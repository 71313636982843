import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Avatar, ClickAwayListener, Collapse, Dialog, DialogTitle, Slide, Divider, Grow, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, Grid, Typography, Badge } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import { tabsClasses } from "@mui/material/Tabs";
import { apiURL, Post } from '../../utils/apiCalls';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 2000,
    '&[x-placement*="bottom"] .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%"
      }
    },
    '&[x-placement*="top"] .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0"
      }
    },
    '&[x-placement*="right"] .MuiPopper-arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%"
      }
    },
    '&[x-placement*="left"] .MuiPopper-arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0"
      }
    }
  }
}));

let gst: any = {
  central_tax: 'Central Tax', central_tax_rate: 'Central Tax (Rate)', integrated_tax: 'Integrated Tax', integrated_tax_rate: 'Integrated Tax (Rate)',
  union_territory_tax: 'Union Territory Tax', union_territory_tax_rate: 'Union Territory Tax (Rate)', compensation_cess: 'Compensation Cess', compensationcess_rate: 'Compensation Cess (Rate)'
};

let customs: any = { tariff: 'Tariff', non_tariff: 'Non Tariff', anti_dumping_duty: 'Anti Dumping Duty', cvd: 'CVD', safeguards: 'Safeguards' }

const getBase64PDFFromUrl = async (pdfUrl: any) => {
  var res = await fetch(pdfUrl);
  var blob = await res.blob();
  var reader = new FileReader();
  const preview : any = document.getElementById('preview');
  return new Promise((resolve, reject) => {
    reader.addEventListener("progress", async () => {
      preview.data = reader.result as any
      resolve(reader.result);
    }, false);
    reader.addEventListener("loadend", async () => {
      preview.data = reader.result as any
      resolve(reader.result);
    }, false);
    reader.onerror = () => {
      toast.error('Please Try Again')
      return reject();
    };
      reader.readAsDataURL(blob);
  })
}

let anchorEl: HTMLDivElement | null = null
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GovtNotification(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState('0');
  const [note, setNote] = React.useState('');
  const [opentoggle, setOpentoggle] = React.useState(false);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [hscodeObject, setHscodeObject]: any = React.useState({ data: {}, index: 0 });
  const [expandedGst, setExpandedGSt]: any = React.useState(null);
  const [openfile, setOpenfile] = React.useState({ open: false, data: '', fileName: '' })
  const [expandedCustom, setExpandedCustom]: any = React.useState(null);
console.log(openfile)
const navigate = useNavigate();

  const handleToggleClose = () => {
    setOpentoggle(false)
    anchorEl = null;
    props.handleParentClose(false)
  }

  const handleClosefile = () => {
    setOpenfile({ open: false, data: '', fileName: '' });
  };

  React.useMemo(() => {
    setHscodeObject(props.record.hscodeObject)
    setOpentoggle(props.record.open)
    anchorEl = props.record.anchorEl;
    setNote(props.record.hscodeObject['data']['govt_policy'] && props.record.hscodeObject['data']['govt_policy']['govt_policy_notifications'] ? Object.keys(props.record.hscodeObject.data.govt_policy['govt_policy_notifications']).length > 0? Object.keys(props.record.hscodeObject.data.govt_policy['govt_policy_notifications'])[0]: '' : '')
  }, [props.record.open]);

  var reader = new FileReader();
  const preview : any = document.getElementById('preview');
  reader.addEventListener("progress", async () => {
    // setOpenfile(openfile => ({...openfile, data: reader.result as any}))
    // preview.data = reader.result as any
  }, false);
  reader.addEventListener("loadend", async () => {
    // preview.data = reader.result as any
    // setOpenfile(openfile => ({...openfile, data: reader.result as any}))
  }, false);

  const handleOpenFile = async (e: any, data: any, obj: string, objItem: any, index: number) => {
    setOpentoggle(false)
    anchorEl = null
  // var res = await fetch(data.file_path);
  // var blob = await res.blob();
  // reader.readAsDataURL(blob);
  setOpenfile({ open: true, data: '', fileName: data.file_name })
  const response: any = await Post(apiURL + "/hs_codes/get_file", {file_path: data.file_path}, navigate);
    if (response.data.status) {
      var res = 'data:application/pdf;base64,' + response.data.data
      setOpenfile({ open: true, data: res, fileName: data.file_name })
      props.parentCallback(data, obj, objItem, index, note)
      }
  };

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChangeYears = (event: React.SyntheticEvent, newNote: string) => {
    setNote(newNote);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Popper open={opentoggle} anchorEl={anchorEl} transition sx={{ overflow: 'inherit', minWidth: { xs: '20%', sm: '30%' }, maxWidth: { xs: '40%', sm: '50%' }, height: { xs: '20%', sm: '30%' } }} className={classes.popper} placement="bottom" disablePortal={true} 
      modifiers={[{
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },]}>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' }} >
            <Paper elevation={12} sx={{ width: '100%' }}>
              <ClickAwayListener onClickAway={handleToggleClose} >
                <Paper elevation={10}>
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', bgcolor: "background.paper" }} >
                    <Tabs value={note} onChange={handleChangeYears} variant="scrollable" scrollButtons sx={{ [`& .${tabsClasses.scrollButtons}`]: { "&.Mui-disabled": { opacity: 0.3 } } }} >
                    {hscodeObject && hscodeObject.data && hscodeObject.data.govt_policy['govt_policy_notifications'] ?
                    Object.keys(hscodeObject.data.govt_policy['govt_policy_notifications']).map((data:any, index:number)=> (
                    <Tab key={index} icon={<Badge badgeContent={hscodeObject.data.govt_policy['govt_policy_notifications'][data]['count']} color="error" sx={{ left: "20px" }}> </Badge>} label={data} value={data} />
                    ))  :null}
                    </Tabs>
                  </Box>

                  <Divider />

                  <TabContext value={value}>
                    <Box sx={{ borderColor: 'divider', p: '0 10px' }}>
                      <TabList onChange={handleChangeTabs} >
                        <Tab label="GST" value='0' sx={{ width: '50%', minWidth: '50%', maxWidth: '50%' }} />
                        <Tab label="Custom" value='1' sx={{ width: '50%', minWidth: '50%', maxWidth: '50%' }} />
                      </TabList>

                    </Box>
                    <TabPanel value="0">
                      <List>

                        {hscodeObject.data && hscodeObject.data.govt_policy['govt_policy_notifications'] && hscodeObject.data.govt_policy['govt_policy_notifications'][note] && hscodeObject.data.govt_policy['govt_policy_notifications'][note]['gst'] ? Object.keys(hscodeObject.data.govt_policy['govt_policy_notifications'][note]['gst']).map((data: any, i: number) => (
                          hscodeObject.data.govt_policy['govt_policy_notifications'][note]['gst'][data].length > 0 ?
                            <>
                              <ListItemButton onClick={event => setExpandedGSt(expandedGst === i ? null : i)}>
                                <ListItemText primary={gst[data]} />
                                {hscodeObject.data.govt_policy['govt_policy_notifications'][note]['gst'][data].reduce(((total: any, { view }: any) => (!view ? total + 1 : total)), 0) ? <Avatar sx={{ backgroundColor: '#FF4842', width: 20, height: 20, fontWeight: 600, fontSize: '0.75rem' }}>
                                  {hscodeObject.data.govt_policy['govt_policy_notifications'][note]['gst'][data].reduce(((total: any, { view }: any) => (!view ? total + 1 : total)), 0)}
                                </Avatar> : null}
                                {expandedGst === i ? <ExpandLess /> : <ExpandMore />}
                              </ListItemButton>
                              <Divider />
                              <Collapse in={expandedGst === i} timeout="auto" unmountOnExit sx={{maxHeight:'200px', overflow:'auto'}}>
                                {hscodeObject.data.govt_policy['govt_policy_notifications'][note]['gst'][data].map((file: any, index: number) => (
                                  <>
                                    <List key={index} sx={{ backgroundColor: !file.view ? 'rgba(0, 0, 0, 0.05)' : 'white' }}>
                                      <ListItem disablePadding>
                                        <ListItemButton onClick={(e: any) => handleOpenFile(e, file, 'gst', data, index)} sx={{ pl: 4 }}>
                                          <ListItemIcon><img src={'/static/icons/pdf-icon.svg'} width='25px' /></ListItemIcon>
                                          <ListItemText primary={file.file_name} />
                                        </ListItemButton>
                                      </ListItem>
                                    </List>  <Divider variant='middle' /></>))}
                              </Collapse>
                            </> : null
                        )) : <Typography align='center'>No GST PDF Found</Typography>}
                      </List>
                    </TabPanel>

                    <TabPanel value="1">
                      <List>
                        {hscodeObject.data && hscodeObject.data.govt_policy['govt_policy_notifications'] && hscodeObject.data.govt_policy['govt_policy_notifications'][note] && hscodeObject.data.govt_policy['govt_policy_notifications'][note]['customs'] ? Object.keys(hscodeObject.data.govt_policy['govt_policy_notifications'][note]['customs']).map((data: any, i: number) => (
                          hscodeObject.data.govt_policy['govt_policy_notifications'][note]['customs'][data].length > 0 ?
                            <>
                              <ListItemButton onClick={event => setExpandedCustom(expandedCustom === i ? null : i)}>
                                <ListItemText primary={customs[data]} />
                                {hscodeObject.data.govt_policy['govt_policy_notifications'][note]['customs'][data].reduce(((total: any, { view }: any) => (!view ? total + 1 : total)), 0) ? <Avatar sx={{ backgroundColor: '#FF4842', width: 20, height: 20, fontWeight: 600, fontSize: '0.75rem' }}>
                                  {hscodeObject.data.govt_policy['govt_policy_notifications'][note]['customs'][data].reduce(((total: any, { view }: any) => (!view ? total + 1 : total)), 0)}
                                </Avatar> : null}
                                {expandedCustom === i ? <ExpandLess /> : <ExpandMore />}
                              </ListItemButton>
                              <Divider />
                              <Collapse in={expandedCustom === i} timeout="auto" unmountOnExit sx={{maxHeight:'200px', overflow:'auto'}}>
                                {hscodeObject.data.govt_policy['govt_policy_notifications'][note]['customs'][data].map((file: any, index: number) => (
                                  <>
                                    <List key={index} sx={{ backgroundColor: !file.view ? 'rgba(0, 0, 0, 0.05)' : 'white' }}>
                                      <ListItem disablePadding>
                                        <ListItemButton onClick={(e: any) => handleOpenFile(e, file, 'customs', data, index)} sx={{ pl: 4 }}>
                                          <ListItemIcon><img src={'/static/icons/pdf-icon.svg'} width='25px' /></ListItemIcon>
                                          <ListItemText primary={file.file_name} />
                                        </ListItemButton>
                                      </ListItem>

                                    </List>  <Divider variant='middle' /> </>))}
                              </Collapse>
                            </> : null
                        )) : <Typography align='center'>No Custom's PDF Found</Typography>}
                      </List>
                    </TabPanel>
                  </TabContext>
                </Paper>
              </ClickAwayListener>
            </Paper>

          </Grow>
        )}
      </Popper>

      <Dialog disableEscapeKeyDown fullScreen className="ModelView" open={openfile.open} onClose={(event, reason) => { if (reason !== 'backdropClick') { handleClosefile } }} TransitionComponent={Transition} >
        <DialogTitle sx={{ position: 'relative' }}> {openfile.fileName}
          <IconButton size="medium" className="closeButton" color="inherit" onClick={handleClosefile} aria-label="close" sx={{ top: '4px' }}><CloseIcon /> </IconButton>
        </DialogTitle>
        <Paper sx={{width: '100%', height: '100%', alignItems: 'center',alignContent: 'center', display: 'flex'}}>
        {openfile.data ? <object width="100%" height="100%" data={openfile.data} aria-label="file" /> :
        <div style={{width:'100%', display:'flex', justifyContent:'center'}}><div style={{color: '#2065D1'}}>Loading</div><div className='AlignLoad' style={{margin:'0px 0px 0px 2%', padding: '0px', overflow:'inherit'}}><div className="dotelastic"></div></div></div>
        }
         {/* <embed src={openfile.data} type="application/pdf" /> */}
        {/* <iframe width="100%" height="100%" src={openfile.data}/>  */}
        </Paper>
      </Dialog>
    </Box>

  );
}