import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, List, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Iconify from '../../../components/Iconify';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCommentIcon from '@mui/icons-material/AddComment';

const PageTableExtract = (props: any) => {
    const [tablepdf, setTablepdf]: any = useState([]);
    const [view, setView] = React.useState({ view: false, id: 0, tableIndex: 0 });
    const [showActionId, setShowActionId] = useState(-1);
    const [anchorElNav, setAnchorElNav] = useState({anchor : null, id:0, i: 0});

    useEffect(() => {
        setTablepdf(props.record.textExtract)
    }, [props.record])

    const handleHeadersTable = (e: any, keys: any, data: any) => {
        const list: any = [...tablepdf]
        list[props.record.index]['tables'][keys]['headers'][data]['value'] = e.target.innerText
        setTablepdf(list)
        props.parentpdfTable(list)
    };

    const handleDataTable = (e: any, keys: any, data: any, index: number) => {
        const list: any = [...tablepdf]
        list[props.record.index]['tables'][keys]['values'][index][data] = e.target.textContent
        setTablepdf(list)
        props.parentpdfTable(list)
    };

    const handleAddRowTable = (key: string, indexNumber: number) => {
        const list: any = [...tablepdf]
        var obj: any = Object.keys(tablepdf[props.record.index]['tables'][key]['headers']).reduce((ac: any, a: any) => ({ ...ac, [a]: '' }), {});
        // list[props.record.index]['tables'][key]['values'].push({ ...obj })
        list[props.record.index]['tables'][key]['values'].splice(indexNumber+1, 0, { ...obj })
        setTablepdf(list)
        props.parentpdfTable(list)
    };

    const handleDeleteRow = (key: any, index: number) => {
        const list: any = [...tablepdf]
        list[props.record.index]['tables'][key]['values'].splice(index, 1)
        setTablepdf(list)
        props.parentpdfTable(list)
    }

    const handleColumnDelete = (key: any, data: any) => {
        const list: any = [...tablepdf]
        delete list[props.record.index]['tables'][key]['headers'][data]
        list[props.record.index]['tables'][key]['values'].map((val: any, index: number) => delete val[data])
        setTablepdf(list)
        props.parentpdfTable(list)
    }

    const extractAlphabets = (uuid) => {
        const alphabets = uuid.replace(/[^a-zA-Z]/g, '');
        return alphabets.substr(0, 4);
    };

    const handleColumnAdd = (key: any) => {
        const list: any = [...tablepdf]
        const uuid = uuidv4();
        const customUUID = extractAlphabets(uuid);
        console.log(customUUID)
        const newKey = `key${Object.keys(tablepdf[props.record.index]['tables'][key]['headers']).length}${customUUID}`
        list[props.record.index]['tables'][key]['headers'][newKey] = { color: "", value: "", mapped_with: "" }
        const res = list[props.record.index]['tables'][key]['values'].map((data: any, index: number) => { return { ...data, [newKey]: '' } })
        list[props.record.index]['tables'][key]['values'] = res

        setTablepdf(list)
        props.parentpdfTable(list)
    };

    const handleOpenNavMenu = (event: any, index: number, i:number) => {
        setAnchorElNav({anchor:event.currentTarget, id: index, i: i});
      };

    const handleCloseNavMenu = () => {
        setAnchorElNav({anchor:null, id: 0, i: 0});
        setShowActionId(-1)
      };

const handleTableDivide = (tableKey, tableI, valueIndex) => {
    console.log(tableKey)
    const list: any = [...tablepdf]
    const tableobjName = `table${Object.keys(list[props.record.index]['tables']).length}`
    const tabledata = list[props.record.index]['tables'][tableKey]['values'].splice(valueIndex)
    const tableheader =  Object.keys(tabledata[0]).reduce(
        (obj, data, index) => Object.assign(obj, { [data]: {value: '', mapped_with: '', color: ''} }), {});
    // const tablevalues = tabledata.splice(1)

    list[props.record.index]['tables'] = {...list[props.record.index]['tables'], [tableobjName]: {headers: {...tableheader}, values: [...tabledata]}}
    setTablepdf(list)
    props.parentpdfTable(list)
    handleCloseNavMenu()
}

function pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? darkColor : lightColor;
  }

    return (
        <Stack spacing={2}>
            {tablepdf.length > 0 ? Object.keys(tablepdf[props.record.index]['tables']).map((keys, i) => {
                const headers = tablepdf[props.record.index]['tables'][keys]['headers']
                const values = tablepdf[props.record.index]['tables'][keys]['values']
                return (
                    <>
                        <Typography padding={i === 0 ? '0px 0px 16px 0px' : "16px 0px"} className="TextExtract-Sticky" sx={{ top: '37px', textTransform: 'uppercase' }}>Table {i + 1}</Typography>
                        <List key={i} sx={{ pt: 0, overflow: 'auto', m: 1 }}>
                            <Table sx={{ minWidth: 650, width: '100%', '& .MuiTableCell-head': { border: '1px solid black' }, '& .MuiTableCell-root': { border: '1px solid black' } }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                    <TableCell align="right" sx={{border:'none !important', width: "60px", minWidth:'60px'}}></TableCell>
                                        {Object.keys(headers).map((data: any, index: number) => (
                                            <Tooltip arrow title={headers[data]['mapped_with']}>
                                                <TableCell key={index} onMouseEnter={() => setView({ view: true, id: index, tableIndex: i })} onMouseLeave={() => setView({ view: false, id: 0, tableIndex: 0 })}
                                                    sx={{ backgroundColor: headers[data]['color'] ? `${headers[data]['color']} !important` : 'inherit', color: headers[data]['color'] ? pickTextColorBasedOnBgColorAdvanced(headers[data]['color'], '#FFFFFF', '#000000'): 'black', minWidth: 'max-content', padding: 0 }}>
                                                    <Stack spacing={0} direction={'row'} justifyContent={'space-between'}>
                                                        <Box component={'span'} contentEditable suppressContentEditableWarning onBlur={(e: any) => handleHeadersTable(e, keys, data)} sx={{ minWidth: '5em', mx: 2, border: headers[data]['value'] ? 'none' : '1px solid' }}>{headers[data]['value']}</Box>
                                                        {view.view && view.id === index && view.tableIndex === i ?
                                                            <IconButton contentEditable={false} aria-label="delete" onClick={() => handleColumnDelete(keys, data)} sx={{ color: headers[data]['color'] ? pickTextColorBasedOnBgColorAdvanced(headers[data]['color'], '#FFFFFF', '#d32f2f') : '#d32f2f' }}><DeleteIcon fontSize="small" /> </IconButton>
                                                            : null}
                                                    </Stack>
                                                </TableCell>
                                            </Tooltip>
                                        ))}

                                        <TableCell sx={{ minWidth: '10%', border: 'none !important', backgroundColor: 'white !important' }}>
                                            <IconButton edge="end" color='primary' sx={{ m: 0 }} onClick={() => handleColumnAdd(keys)}><Iconify icon="carbon:add-alt" sx={undefined} /> </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {values.map((value: any, indexValue: number) => (
                                        <>                                       
                                        <TableRow key={indexValue} hover
                                        onMouseEnter={() => {
                                            setShowActionId(indexValue);
                                          }}
                                          onMouseLeave={() => Boolean(anchorElNav.anchor) && anchorElNav.id === indexValue && anchorElNav.i === i ? setShowActionId(indexValue) : setShowActionId(-1)}>
                                             <TableCell align="right" sx={{border:'none !important', width: "60px", minWidth:'60px'}}>
                                         {indexValue === showActionId ?
                                         <IconButton sx={{ padding: 0 }} onClick={(e)=>handleOpenNavMenu(e, indexValue, i)}><MoreVertIcon /></IconButton>
                                      : null}   
                                       </TableCell>
                                            {Object.keys(headers).map((data: any, index: number) => (
                                                <TableCell key={index} contentEditable suppressContentEditableWarning onBlur={(e: any) => handleDataTable(e, keys, data, indexValue)} >
                                                    {value[data]}
                                                </TableCell>
                                            ))}
                                            <TableCell sx={{ minWidth: '10%' }}>
                                                <IconButton edge="end" sx={{ m: 0 }} onClick={() => handleDeleteRow(keys, indexValue)}><Iconify icon="el:remove-circle" color="#FF4842" sx={undefined} /> </IconButton>
                                            </TableCell>
                                        </TableRow>

                                        <Menu id="menu-appbar" anchorEl={anchorElNav.anchor} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(anchorElNav.anchor) && anchorElNav.id === indexValue && anchorElNav.i === i} onClose={handleCloseNavMenu}
              sx={{ }} >
                <MenuItem onClick={()=>handleTableDivide(keys, i, indexValue)}> 
                <ListItemIcon>
                <AddCommentIcon />
          </ListItemIcon>
          <ListItemText>New Table</ListItemText>
                 </MenuItem>
                 <MenuItem onClick={() => handleAddRowTable(keys, indexValue)}> 
                <ListItemIcon>
                <Iconify icon="carbon:add-alt" sx={{width:'24px', height:'24px'}} />
          </ListItemIcon>
          <ListItemText>Add Table Lines</ListItemText>
                 </MenuItem>
            </Menu>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </List>
                        {/* <Button variant="contained" startIcon={} sx={{ float: 'left', backgroundColor: '#3f51b5', width: "max-content" }}></Button> */}
                      
                    </>
                )
            }) : null}
        </Stack>
    )
};

export default memo(PageTableExtract);