import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, IconButton, Typography, DialogContent, Paper, Grid, TextField, DialogActions } from '@mui/material';
import { FormikProvider, useFormik, Form } from 'formik';
import React, { memo, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Post, apiURL } from '../../../utils/apiCalls';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const CompanySchema = Yup.object().shape({
    company_name: Yup.string().required('Company is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country is required'),
  });

const AddCompany = (props:any) => {
const navigate = useNavigate();
const [openCompany, setOpenCompany] = useState(false);

      const formik = useFormik({
        initialValues: {
           company_name: '',
           address: '',
           city: '',
           country: ''
        },
        validationSchema: CompanySchema,
        onSubmit: async (values, { setSubmitting }) => {
           console.log(values)
         const response = await Post(apiURL + '/msil_company', {...values}, navigate)
         if(response && response.data && response.status) {
           toast.success('Company Details Saved Sucessfully')
           setSubmitting(false)
           handleCloseCompany();
         }
         else {
           toast.error('Please Try Again')
           setSubmitting(false)
         }
      }
      });
      
      const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

      useMemo(()=> {
        setOpenCompany(props.open)
        if(props.company) {
            setFieldValue('company_name', props.company)
        }
    }, [props])

      const handleCloseCompany = () => {
        setOpenCompany(false)
        resetForm();
        props.parentCallback()
       };

    return (
        <Dialog open={openCompany} onClose={handleCloseCompany} maxWidth="lg" fullWidth>
        <DialogTitle className="appBarSend"> <IconButton size="medium" className="closeButtonSend" color="inherit" onClick={handleCloseCompany} aria-label="close"><CloseIcon />
        </IconButton>  <Typography variant="h6" className="sendTypo"><b> Company Details</b></Typography>
        </DialogTitle>
        <FormikProvider value={formik}>
  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
  <DialogContent >
  <Paper>
    <Grid container spacing={2} sx={{ mt: 0 }}>
     <Grid item xs={6}>
      <TextField fullWidth autoComplete="company" label="Company Name" {...getFieldProps('company_name')} error={Boolean(touched.company_name && errors.company_name)} helperText={touched.company_name && errors.company_name} />
      </Grid>
      <Grid item xs={6}>
      <TextField fullWidth label="Company Address" {...getFieldProps('address')} error={Boolean(touched.address && errors.address)} helperText={touched.address && errors.address} />
   </Grid></Grid>

   <Grid container spacing={2} sx={{ mt: 0 }}>
     <Grid item xs={6}>
      <TextField fullWidth label="City" {...getFieldProps('city')} error={Boolean(touched.city && errors.city)} helperText={touched.city && errors.city} />
      </Grid>
      <Grid item xs={6}>
      <TextField fullWidth label="Country" {...getFieldProps('country')} error={Boolean(touched.country && errors.country)} helperText={touched.country && errors.country} />
   </Grid></Grid>
   </Paper>
   </DialogContent>

   <DialogActions>
        <LoadingButton  size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ backgroundColor: '#3f51b5' }}>Save </LoadingButton>
        </DialogActions>
  </Form>
</FormikProvider>
     </Dialog>
    )
}

export default memo(AddCompany);