import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UploadDocuments from '../upload_doccuments/UploadDocumentsCreate';
import HSClassifierCreate from '../hs_classsifer/HSClassifierCreate';
import CheckListCreate from '../CheckListCreate';
// import CustomQueries from '../CustomQueries';
// import PaymentCreate from '../payments/PaymentCreate';
// import PastIssues from '../PastIssues';
import { useParams } from 'react-router-dom';

interface TabpanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabpanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}

      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// const id = useParams();
// console.log(id)

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export default function InboundShipmentEdit() {
    const { id } = useParams();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCallback = () =>{
    setValue(value+1)
}

console.log(id);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Upload Documents" {...a11yProps(0)} />
          <Tab label="HS Code Classification" {...a11yProps(1)} />
          {/* <Tab label="Anomaly Check" {...a11yProps(2)} /> */}
          {/* <Tab label="Custom's Queries" {...a11yProps(3)} />
          <Tab label="Payment Records" {...a11yProps(4)} />
          <Tab label="Past Issues" {...a11yProps(5)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
       <UploadDocuments parentCallback = {handleCallback} id = {id} params = {'edit'} />
      </TabPanel>
      <TabPanel value={value} index={1}>
       <HSClassifierCreate parentCallback = {handleCallback} id= {id} params = {'edit'} />
      </TabPanel>
      
      {/* <TabPanel value={value} index={2}>
       <CheckListCreate parentCallback = {handleCallback} id= {id} params = {'edit'} />
      </TabPanel> */}
      {/* <TabPanel value={value} index={3}>
      <CustomQueries parentCallback = {handleCallback} id= {id} params = {'edit'}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <PaymentCreate parentCallback = {handleCallback} id= {id} params = {'edit'} />
      </TabPanel>
      <TabPanel value={value} index={5}>
       <PastIssues parentCallback = {handleCallback} id= {id} params = {'edit'}/>
      </TabPanel> */}
    </Box>
  );
};