import InfoIcon from '@mui/icons-material/Info';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Badge, Button, Chip, CircularProgress, Divider, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import * as React from 'react';
import HSNav from './HSNav';
// import MuiDialogTitle from '@mui/material/DialogTitle';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiURL, Post } from '../../utils/apiCalls';
import GovtNotification from './GovtNotification';
import HSClassificationSkelton from './HSClassificationSkelton';
import Label from '../../components/Label';
import LoopIcon from '@mui/icons-material/Loop';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import { useMemo, useState } from 'react';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: theme.typography.pxToRem(14),
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: '#fff',
    color: '#f5f5f9',
  },
}));

let anchorEl: HTMLDivElement | null = null

export default function HSClassifierCreate(props: any, uuid: string) {
  const [loading, setLoading] = React.useState(false);
  const [hscodeObject, setHscodeObject]: any = React.useState({ data: {}, index: 0 });
  const [hsclassifyData, setHsclassifyData]: any = React.useState({});
  const [opentoggle, setOpentoggle] = React.useState(false);
  const [value, setValue] = React.useState(false);
  const [hscodeData, setHscodeData]: any = React.useState({ prod_desc: "", hs_code: "", hs_question: {}, hs_dropdown: {}, hs_answer: [], previous_hs_answer: [], hs_position: {}, final_description: "", section_no: "", section_url: "", chapter_no: "", chapter_url: "", end_use_match:0, end_use_description:'' });
const [assignLoading, setAssignLoading] = React.useState(false);
console.log(hsclassifyData);
console.log(hscodeObject);

const [account, setAccount]:any = useState({});

useMemo(()=> {
  setAccount(JSON.parse(sessionStorage.getItem('user') as any))
}, [sessionStorage.getItem('user')])

const navigate = useNavigate();

  async function GetCall() {
    setLoading(true)
    const response: any = await Post(apiURL + "/msil_file_upload/getall_hs_data", { uuid: props.id }, navigate);
    if (response && response.data.status) {
      setLoading(false)
      setHsclassifyData(response.data.data)
      // setHsclassifyData([...response.data.data.table_mapped_data])
    }
    else {
      setLoading(false)
      setHsclassifyData({})
    }
  };

  React.useMemo(() => {
    GetCall();
    sessionStorage.setItem('anamoly_check', '')
  }, [props.id]);

  const handleSaveHsCode = async (data:any) => {
    console.log(data)
    const url = account['user_role_type'] === 1 || data.table_data['data']['hs_code'] === data.search_hscode['hs_code'] ? "/msil_file_upload/update_hs_codes" : "/msil_file_upload/update_new_hs_codes"
    const response: any = await Post(apiURL + url, {...data, ...hsclassifyData}, navigate);
    if (response.data.status) {
      if(account['user_role_type'] === 1 || data.table_data['data']['hs_code'] === data.search_hscode['hs_code']) {
        toast.success('HS Code Updated Sucessfully');
      }
      else {
        toast.success('Request sent to Admin for HS Code Updation');
      }
      const responseReload: any = await Post(apiURL + "/msil_file_upload/getall_hs_data", { uuid: props.id }, navigate);
      if (responseReload && responseReload.data.status) {
        setHsclassifyData(responseReload.data.data)
    }
    else {
      setHsclassifyData({})
    }
    }
    else {
      toast.error(response.data.message)
    }
  };

  const handleSaveReadPDF = async () => {
    const url = "/msil_file_upload/update_hs_codes"
    const response: any = await Post(apiURL + url, hsclassifyData, navigate);
    if (response.data.status) {
      // toast.success('HS Code Classified Sucessfully');
      console.log('Saved HS Code Sucessfully')
    }
    else {
      toast.error(response.data.message)
    }
  };

  const handleHsCode = (data: any) => {
    const list = { ...hsclassifyData };
    setHscodeData(data.hscode_data)
    setValue(data.open)
    list['table_mapped_data'][hscodeObject.index]['hs_code'] = (data.hscode_data.hs_code).replaceAll(/\s/g,'')
    setHsclassifyData(list)
  };

  const handleNotify = (data: any, obj:string, objItem: any, index:number, year: any) => {
    console.log(data.view)
    setOpentoggle(false)
    anchorEl = null
    const list = { ...hsclassifyData };
    if(list['table_mapped_data'][hscodeObject.index]['govt_policy']['govt_policy_count'] > 0) {
      if(!data.view) {
    list['table_mapped_data'][hscodeObject.index]['govt_policy']['govt_policy_count'] = list['table_mapped_data'][hscodeObject.index]['govt_policy']['govt_policy_count'] - 1
      }
    if(list['table_mapped_data'][hscodeObject.index]['govt_policy']['govt_policy_notifications'][year]['count'] > 0) {
      if(!data.view) {
      list['table_mapped_data'][hscodeObject.index]['govt_policy']['govt_policy_notifications'][year]['count'] = list['table_mapped_data'][hscodeObject.index]['govt_policy']['govt_policy_notifications'][year]['count'] - 1
      list['table_mapped_data'][hscodeObject.index]['govt_policy']['govt_policy_notifications'][year][obj][objItem][index]['view'] = 1
    }
  }
    else {
      list['table_mapped_data'][hscodeObject.index]['govt_policy']['govt_policy_notifications'][year]['count'] = 0
    }
    }
    else {
      list['table_mapped_data'][hscodeObject.index]['govt_policy']['govt_policy_count'] = 0
    }
    setHsclassifyData(list)
    handleSaveReadPDF()
  }

  const handleChangeDesc = (e: any, i: number) => {
    const list = { ...hsclassifyData };
    list['table_mapped_data'][i]['part_name'] = e.target.value
    setHsclassifyData(list)
  };

  const handleToggle = (e: any, data: any, i: number) => {
    setHscodeObject({ data: data, index: i })
    setOpentoggle((prevOpen) => !prevOpen)
    anchorEl = e.currentTarget;
  }

  const handleToggleClose = () => {
    setOpentoggle(false)
    anchorEl = null;
  }

  const handleChangenav = async (e: any, data: any, i: number) => {
    const list = { ...hsclassifyData };
    console.log(data)
    setHscodeObject({ data: data, index: i })
    setAssignLoading(true)
    const response = await Post(apiURL + "/hs_codes/search_hs_chapter", { prod_desc: data.part_name, hs_code: '', hs_question: {}, hs_dropdown: {}, hs_answer: [], previous_hs_answer: [], hs_position: {}, final_description: "", section_no: "", section_url: "", chapter_no: "", chapter_url: "", end_use_match:0, end_use_description:'' }, navigate)
    if(response){
    if (response.data.status) {
      // list['table_mapped_data'][i]['hs_code'] = response.data.data.hs_code
      // setHsclassifyData(list)
      setHscodeData(response.data.data)
      setAssignLoading(false)
      setValue(true)
    }
  }
  else {
    setAssignLoading(false)
    toast.error('Please Try Again')
  }
  };

  const handleGovtNotification = (data:any) => {
    const list = { ...hsclassifyData };
    list['table_mapped_data'][hscodeObject.index]['govt_policy'] = data
    setHsclassifyData(list)
    handleSaveReadPDF()
  }

  const handleSubmitHsCode = () => {
    handleSaveHsCode(hsclassifyData)
    navigate('/dashboard/hsclassifier')
  }

  const { onClose, ...other } = props;

  return (
    <>
      {!loading ? (hsclassifyData.table_mapped_data && hsclassifyData.table_mapped_data.length > 0) ? hsclassifyData.table_mapped_data.map((data: any, index: number) => {
        return (
          <Paper key={index} sx={{ maxWidth: '100%', padding: '20px', width: '100%', mb: '2%' }} elevation={3}>
            <Grid container spacing={2} sx={{ mt: '0px' }}>
            <Grid xs={1} sx={{ justifyContent: 'center', display: 'flex' }}>
            <Typography variant="subtitle2">{index+1}</Typography>
              </Grid>
              <Grid xs={4} sx={{ justifyContent: 'center', display: 'flex' }}>
                <Typography variant="h6">Product Name </Typography>
              </Grid>

              <Grid xs={5} sx={{ justifyContent: 'center', display: 'flex' }}>
                <Typography variant="h6">HS Code</Typography>
              </Grid></Grid>

            <Grid container spacing={2} sx={{ mt: '8px' }}>
              <Grid xs={5} sx={{ p: '10px', display: 'flex', alignItems: 'center' }}>
                <TextField id="prod_name" multiline name='prod_name' label="" value={data.part_name} variant="filled" fullWidth onChange={(e: any) => handleChangeDesc(e, index)} />             
              </Grid>

              <Grid xs={5} sx={{ p: '10px', display: 'flex', alignItems: 'center' }}>
                {/* <MuiOtpInput length={8} value={(data.hs_code).replaceAll(/\s/g,'')} TextFieldsProps={{ disabled: true, placeholder: '-', sx: { '& .Mui-disabled': { color: 'black', '-webkitTextFillColor': 'black !important', padding: '0px', fontWeight: 600, fontSize: '3rem' } } }} /> */}
                <OtpInput numInputs={8} value={(data.hs_code).replaceAll(/\s/g,'')} isDisabled placeholder= "--------" containerStyle={{gap:'20px', display: 'flex', alignTtems: 'center', '-moz-box-align': 'center'}} 
                // focusStyle = {{display: 'inline-flex', flexDirection: 'column', position: 'relative',minWidth: '0px',padding: '0px',margin: '0px',border: '0px none'}}
                disabledStyle={{color: 'black', '-webkitTextFillColor': 'black !important', padding: '0px', fontWeight: 600, fontSize: '2.5rem', borderRadius: '8px', boxSizing: 'border-box', border: '1px solid rgb(145, 158, 171)'}} 
                inputStyle={{ color: 'black', '-webkitTextFillColor': 'black !important', padding: '0px', fontWeight: 600, fontSize: '2.5rem', borderRadius: '8px', boxSizing: 'content-box', height:'1.4375em', animationName: 'mui-auto-fill-cancel',
                animationDuration: '10ms', display: 'block',  width:'100%', minWidth:'0px', border: '1px solid rgb(145, 158, 171)' }} 
                />
              </Grid>

              <Grid xs={2} sx={{ p: '10px', display: 'flex', alignItems: 'center' }}>
                <div>
                  <LightTooltip title={data.rules.map((rules: any, r: number) => (
                    <div key={r}>{rules}<Divider /></div>))} arrow placement='bottom' >
                    <IconButton> <InfoIcon /></IconButton></LightTooltip>
                  <Tooltip title={"Government Notifications"}>
                    <IconButton onClick={(e: any) => handleToggle(e, data, index)}>
                    <Badge badgeContent={data.govt_policy['govt_policy_count']} color="error"> <NotificationsNoneIcon />  </Badge>
                    </IconButton>
                  </Tooltip>
                </ div>

                <Button variant="contained" color='primary' onClick={(e: any) => handleChangenav(e, data, index)} sx={{ float: 'right' }} startIcon={data.importer_count > 0 ? <LoopIcon />  :<PanToolAltIcon /> } disabled={hscodeObject.index === index ? assignLoading : false}>
                  {hscodeObject.index === index && assignLoading ? <CircularProgress  size={50}/> : data.importer_count > 0 ? 'Change HS Code' : 'Assign Code' }</Button>
              </Grid> 
              </Grid>
              
              {data.part_no?<Typography><span style={{fontWeight:'bold'}}>Part Number:</span> <Label variant="ghost" color={'info'}>{data.part_no}</Label></Typography>:null}
                <Typography>{data.importer_count} times imported</Typography>
                {data.status?<Typography><span style={{fontWeight:'bold'}}>Status:</span> <Label variant="ghost" color={data.status=== 'rejected'? 'error' : data.status=== 'pending' ? 'warning': 'success'} sx={{textTransform: 'capitalize'}}>{data.status} by Admin</Label></Typography>:null}
                {data.comments?<Typography><span style={{fontWeight:'bold'}}>Comment:</span> <Label variant="ghost" color={'info'}>{data.comments}</Label></Typography>:null}

          </Paper>
        )
      }) : <Paper sx={{ maxWidth: '100%', padding: '20px', width: '100%', mb: '2%' }} elevation={3}>
        <Typography variant="h4" gutterBottom align='center'> No Products Found  </Typography>
      </Paper>
        : <HSClassificationSkelton />}

{account['user_role_type'] === 1 ? <Button variant="contained" onClick={handleSubmitHsCode} sx={{ float: 'right' }}> Submit </Button>: null}

      <HSNav record={{ hs_data: hscodeData, open: value, hscodeObject: hscodeObject }} parentCallback={handleHsCode} govNotificationCallBack={handleGovtNotification} hscodeSubmit={handleSaveHsCode} />

      <GovtNotification record={{open: opentoggle, anchorEl: anchorEl, hscodeObject: hscodeObject }} parentCallback={handleNotify} handleParentClose={handleToggleClose} />
    
    </>
  );
}