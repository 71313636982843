// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name: string) => <Iconify icon={name} width={22} height={22} />;

export const navConfigNonAdmin =
 [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
//   {
//     title: 'Inbound Shipments',
//     path: '/dashboard/inbound_shipment',
//     icon: getIcon('emojione-monotone:ship'),
//   },
  {
    title: 'HS code Clasify',
    path: '/dashboard/hsclassifier',
    icon: getIcon('et:search'),
  },
//   {
//     title: 'checklist',
//     path: '/dashboard/checklist',
//     icon: getIcon('tabler:checklist'),
//   },
//   {
//     title: 'custom queries',
//     path: '/dashboard/custom_queries',
//     icon: getIcon('emojione-monotone:customs'),
//   },
//   {
//     title: 'payments',
//     path: '/dashboard/payment',
//     icon: getIcon('fluent:payment-20-regular'),
//   },
//   {
//     title: 'past issues',
//     path: '/dashboard/past_issues',
//     icon: getIcon('ic:sharp-content-paste-search'),
//   },
  {
    title: 'Miscellaneous HS Code',
    icon: getIcon('carbon:show-data-cards'),
    path: '/dashboard/miscellaneous'
    // children: [{
    //   title: 'Miscellaneous HS Code',
    //   path: '/dashboard/usedhscodes',
    // },
    // {
    //   title: 'Classify HS Code',
    //   path: '/dashboard/miscellaneous',
    // }]
  },
];

export const navConfigAdmin =
 [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'HS code Clasify',
    path: '/dashboard/hsclassifier',
    icon: getIcon('et:search'),
  },
  {
    title: 'Miscellaneous HS Code',
    icon: getIcon('carbon:show-data-cards'),
    path: '/dashboard/miscellaneous'
  },
  {
    title: 'Change Request',
    icon: getIcon('carbon:request-quote'),
    path: '/dashboard/change_request'
  },
];