import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination, CircularProgress, IconButton, FormControl, Select, MenuItem, SelectChangeEvent, Grid, Skeleton
} from '@mui/material';
// components

import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
import { GetAll, apiURL } from '../../utils/apiCalls';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { array } from 'prop-types';
import { queries } from '@testing-library/react';
import MiscSkeleton from '../../sections/@dashboard/user/MiscSkeleton';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'hs_code', label: 'HS Code', align: 'center' },
    { id: 'part_name', label: 'PART NAME', align: 'left' },
    { id: 'part_no', label: 'PART NO', align: 'center' },
    { id: 'unit_price', label: 'UNIT PRICE', align: 'center' },
    { id: '', label: '', align: 'center' },

  ];

  // const USERLIST = [{id:1, hs_code: 27101978, description:'A/T OIL AW-1(>70% MINERAL OIL BASED LUBRICANT) (CONSUMABLE AS CAPTIVE CONSUMPTION) (200LTR/15DRUM)'},
  // {id:2,  hs_code: 34021900, description:'WINDSHIELD WASHER JCW80 (200LTR/20 DRUMS) ( CONSUMABLE AS CAPTIVE CONSUMPTION) MFR-SUZUKI MOTOR'},
  // {id:3,  hs_code: 39269099, description:'BEARING, FR STRUT (MADE OF PLASTIC)  PART NO.45812-79000 (COMPONENTS AS CAPTIVE CONSUMTION FOR AUTOM'},]
  // ----------------------------------------------------------------------

function descendingComparator(a:any, b:any, orderBy:any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order:any, orderBy:any) {
  return order === 'desc'
    ? (a:any, b:any) => descendingComparator(a, b, orderBy)
    : (a:any, b:any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array:any, comparator:any, query:any) {
  const stabilizedThis = array.map((el:any, index:number) => [el, index]);
  stabilizedThis.sort((a:any, b:any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    // hs_code, part_name, part_no, unit_price
    return filter(array, (_user) => (_user.hs_code).toLowerCase().indexOf(query.toLowerCase().trim()) !== -1 || (_user.part_name).toLowerCase().indexOf(query.toLowerCase().trim()) !== -1 ||
    (_user.part_no).toLowerCase().indexOf(query.toLowerCase().trim()) !== -1 || (_user.unit_price).toLowerCase().indexOf(query.toLowerCase().trim()) !== -1);
  }
  return stabilizedThis.map((el:any) => el[0]);
}

export default function Miscellaneous() {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [isActive, setIsActive] = useState([]);

  const [orderBy, setOrderBy] = useState();

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [list, setList]:any = useState([]);
  const [loading, setLoading] = useState(false);

  async function GetList () {
    setLoading(true);
    const response: any = await GetAll(apiURL + "/msilMapping/getmisc", navigate);
         if(response) {
        if (response.data.status) {       
        setLoading(false);
        setList(response.data.data)
        setIsActive(response.data.data)
        }
        else {
        setLoading(false);
        toast.error(response.data.message)
          setList([])
        }
      }
      else {
        setLoading(false);
        toast.error(`HS Code ${response.statusText}`)
      }
};

  useEffect(()=> {
    GetList();    
  },[])

  const handleRequestSort = (event:any, property:any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event:any) => {
    if (event.target.checked) {
      const newSelecteds:any = list.map((n:any) => n.hs_codes);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event:any, name:any) => {
    const selectedIndex = selected.indexOf(name as never);
    let newSelected:any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event:any, newPage:any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event:any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event:any) => {
    setFilterName(event.target.value);
  };
 
  const handleClickEdit = (e: any, id: any) => {
    e.preventDefault();
    navigate(`/dashboard/miscellaneous/${id}`);
}; 

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredUsers = applySortFilter(isActive, getComparator(order, orderBy), filterName);

  const handleChange = (event: SelectChangeEvent) => {
    console.log(event.target.value)
    setIsActive(list.filter((data:any) => data.is_active === event.target.value))
  };

console.log(filteredUsers)
  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Container sx={{m:0, width:'100%', maxWidth:'100%'}} maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom> HS CODES </Typography>
        </Stack>

        <Card>
          <UserListToolbar placeholder= "Search..." numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar sx={{}}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order as "asc" | "desc"}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  isCheckbox={false}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => {
                    const { id, hs_code, part_name, part_no, unit_price } = row;
                    const isItemSelected = selected.indexOf(hs_code as never) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                        </TableCell> */}
                        <TableCell component="th" scope="row" padding="none" align='center' > {hs_code} </TableCell>
                        <TableCell align="left" sx={{minWidth:'20%'}}>{part_name}</TableCell>
                        <TableCell align="center">{part_no}</TableCell>
                        <TableCell align="center">{unit_price}</TableCell>

                        <TableCell align="center">
                       <IconButton color="primary" onClick={(e) => handleClickEdit(e, id)}  >
                 <EditIcon  />
               </IconButton>
                     </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                   {loading ? <MiscSkeleton record={{number: 4}}/>  : 
                   <TableRow>
                      <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                       {list.length <=0 ? <Typography gutterBottom align="center" variant="subtitle1"> Not found</Typography> :<SearchNotFound searchQuery={filterName} /> } 
                      </TableCell>
                    </TableRow> }
                  </TableBody>
                )}

              </Table>
              
              {/* <Table > 
                <TableBody>     
                     <TableCell><Skeleton variant="rounded"  height={30}/></TableCell>
                </TableBody>       

                <TableBody>       
                     <TableCell><Skeleton variant="rounded"  height={30}/></TableCell>
                </TableBody>         
                <TableBody>
                  <TableCell><Skeleton variant="rounded"  height={30}/></TableCell>
               </TableBody>

                <TableBody>             
                <TableCell><Skeleton variant="rounded"  height={30}/></TableCell>
                </TableBody>          
                
                <TableBody>                        
                <TableCell><Skeleton variant="rounded"  height={30}/></TableCell>
                </TableBody>

                  </Table> */}

            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
  );
}

