import * as React from 'react';
import { Get, Post, Put, apiURL } from '../utils/apiCalls';
import { fDate, fDateTime, TimeChangeISO } from '../utils/formatTime';

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useMemo } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Card, Table, Stack, Button, CircularProgress, Paper, Checkbox, Tooltip, Grid, TableRow, TableBody, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TableCell, Container, Typography, TableContainer, TablePagination, Chip, FormGroup, FormControlLabel } from '@mui/material';
// components
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import { toast } from 'react-toastify';
import MiscSkeleton from '../sections/@dashboard/user/MiscSkeleton';
import { LoadingButton } from '@mui/lab';

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

const TABLE_HEADAdmin = [
  { id: 'part_name', label: 'Part Name', align: 'center' },
  { id: 'past_unit_price', label: 'Past Unit Price', align: 'center' },
  { id: 'invoice_No', label: 'Past Invoice Number', align: 'center' },
  { id: 'updated_date', label: 'Past Unit Price Date', align: 'center' },
  { id: 'unit_price', label: 'Current Unit Price', align: 'center' },
  { id: 'check_box', label: 'Update current Unit Price', align: 'center' },
];

const TABLE_HEADNonAdmin = [
  { id: 'part_name', label: 'Part Name.', align: 'center' },
  { id: 'past_unit_price', label: 'Past Unit Price', align: 'center' },
  { id: 'invoice_No', label: 'Past Invoice Number', align: 'center' },
  { id: 'updated_date', label: 'Past Unit Price Date', align: 'center' },
  { id: 'unit_price', label: 'Current Unit Price', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'comments', label: 'Comment', align: 'center' },
]

export function dataURItoBlob(dataURI: string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;

  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export default function CheckListCreate(props: any) {
  const navigate = useNavigate();

  const [anomalydata, setanomalyData]: any = React.useState({ anamoly_list: [], anamoly_status: '' });
  const [filterPartNo, setFilterPartNo] = useState('');
  const [filterHsCode, setFilterHsCode] = useState('');
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [loading, setLoading] = useState(false);
  const [anomalyStatus, setAnomalyStatus] = useState({proceed_unitprice: false, change_unitprice: false});
const [loadingUpdate, setLoadingUpdate] = React.useState({ update: false });
const [account, setAccount]:any = useState({});

useMemo(()=> {
  setAccount(JSON.parse(sessionStorage.getItem('user') as any))
}, [sessionStorage.getItem('user')])

  React.useEffect(() => {
    setanomalyData(props.record.anomalydata)
  }, [props.record])

  const handleFilterPartNo = (e: any) => {
    setFilterPartNo(e.target.value);
  };

  const handleFilterHsCode = (e: any) => {
    setFilterHsCode(e.target.value)
  }

  function applySortFilter(array: any, comparator: any, query: any, q2: any) {
    const stabilizedThis = array.map((el: any, index: number) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query && q2) {
      return filter(array, (_user) => JSON.stringify(_user['part_no']).toLowerCase().indexOf(q2.toLowerCase()) !== -1 || JSON.stringify(_user['hs_code']).toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    else if (query) {
      return filter(array, (_user) => JSON.stringify(_user['part_no']).toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    else if (q2) {
      return filter(array, (_user) => JSON.stringify(_user['hs_code']).toLowerCase().indexOf(q2.toLowerCase()) !== -1);
    }

    return stabilizedThis.map((el: any) => el[0]);
  };

  const filteredList = applySortFilter(anomalydata['anamoly_list']?anomalydata['anamoly_list']: [], getComparator(order, orderBy), filterPartNo, filterHsCode);
  console.log(filteredList)

  const handleSubmitAdmin = async () => {
    setLoadingUpdate({update:true})
    const response: any = await Put(apiURL + "/anomalies/updatestatus", "", { uuid: props.record.anomalydata['uuid'], urid: props.record.anomalydata['urid'], file_id: props.record.anomalydata['file_id'], ...anomalyStatus, anamoly_status: anomalyStatus.proceed_unitprice ? 'pending' : 'completed', anamoly_list: anomalydata['anamoly_list'] }, navigate);
    if (response.data.status) { 
       props.parentCallBack(false)
      if(anomalydata['anamoly_list'].length > 0) {
        const lengthUpdated = anomalydata['anamoly_list'].filter((data:any) => data.update_hsCode === true).length
        if(lengthUpdated > 0){
          toast.success(`Unit Price updated`)
        }
        const lengthProceed = anomalydata['anamoly_list'].filter((data:any) => data.update_hsCode === false).length
        if(lengthProceed > 0)
          toast.error(`You are proceeding with ${lengthProceed} anomaly`)
      }
      setLoadingUpdate({update:false})
    }
    else {
      setLoadingUpdate({update:false})
      toast.error('Cannot Update Unit Prices');
    }
  };

  const handleSubmitNonAdmin = async() => {
    setLoadingUpdate({update:true})
    const response: any = await Put(apiURL + "/anomalies/updatestatus_admin", "", { uuid: props.record.anomalydata['uuid'], file_id: props.record.anomalydata['file_id'], ...anomalyStatus, anamoly_status: anomalyStatus.proceed_unitprice ? 'pending' : 'completed', anamoly_list: anomalydata['anamoly_list'] }, navigate);
    if (response.data.status) { 
      props.parentCallBack(false)
      toast.success(`Request had been sent to Admin for Unit Prices Successfully`)
      setLoadingUpdate({update:false})
    }
      else {
        setLoadingUpdate({update:false})
        toast.error('Please Try Again');
      }
  }

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = anomalydata['anamoly_list'] ? anomalydata['anamoly_list'].map((n: any) => n.job_no): [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows = anomalydata['anamoly_list'] ? anomalydata['anamoly_list'].length === 0 ? -1 : 0 : -1;

  const isUserNotFound = filteredList.length === 0;

  const handleViewPdf = async (id: any) => {
    const response: any = await Post(apiURL + "/msil_file_upload/pdf", { file_id: id }, navigate);
    if(response.data.status) {
      var blob = dataURItoBlob(response.data.data[0].file_data);
      var objectURL = URL.createObjectURL(blob);
      window.open(objectURL, '_blank')
    }
    else {
      toast.error('Cannot Open PDF');
    }
  };

 const handleChangeCheckBox = (e:any, i:number) => {
  const list = {...anomalydata}
  console.log(e.target)
  
    list.anamoly_list[i]["update_hsCode"] = e.target.checked
    setanomalyData(list)
 };

  return (
    <>
      <Container sx={{margin: 0, maxWidth:'100% !important'}}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" >
            <Typography variant="h5" component="div" gutterBottom sx={{ m: "15px 24px" }}><Iconify icon="ic:outline-warning" sx={undefined} />  Anomaly Check for  Unit Price Change </Typography>
            {/* {anomalydata['anamoly_list'] ? anomalydata['anamoly_list'].length > 0 ? anomalydata['anamoly_status'] === 'pending' ? <Label variant="ghost" color={'warning'} sx={{ m: "15px 24px" }}>Please Check for Anomaly Alerts</Label> :
              <Label variant="ghost" color='success' sx={{ m: "15px 24px" }}>Anomaly Alerts are verified</Label> :null :null} */}
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <UserListToolbar placeholder="Search Part No..." numSelected={0} filterName={filterPartNo} onFilterName={handleFilterPartNo} />
            </Grid> </Grid>

          <Scrollbar sx={{}}>
            <TableContainer>
              <Table>
                <UserListHead
                  order={order as "asc" | "desc"}
                  orderBy={orderBy}
                  headLabel={account['user_role_type'] === 1 ? TABLE_HEADAdmin : TABLE_HEADNonAdmin}
                  rowCount={anomalydata['anamoly_list']? anomalydata['anamoly_list'].length: 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  isCheckbox={false}
                />
                <TableBody>
                  {filteredList.map((row: any, index: number) => {
                    const { part_no, part_name, past_unit_price, hs_code, past_unit_price_date, current_unit_price, invoice_no, file_id, update_hsCode, status, comments } = row;

                    return (
                      <TableRow hover key={index} tabIndex={-1}>
                        <TableCell component="th" scope="row" align="left">{<Label variant="ghost" color={'warning'} tooltip={part_no}>{part_name}</Label>} </TableCell>
                        <TableCell align="center">{<Label variant="ghost" color={'success'}>{past_unit_price}</Label>}</TableCell>
                        <TableCell align="center"><Tooltip title={"Open PDF"} arrow><Button onClick={()=>handleViewPdf(file_id)}>{invoice_no}</Button></Tooltip></TableCell>
                        <TableCell align="center">{fDateTime(TimeChangeISO(past_unit_price_date))}</TableCell>
                        <TableCell align="center">{<Label variant="ghost" color={'error'}>{current_unit_price}</Label>}</TableCell>
                        {account['user_role_type'] === 1 ?<TableCell align="center"> <Checkbox checked={update_hsCode} onChange={(e:any)=>handleChangeCheckBox(e, index)} /></TableCell>:null}
                        {account['user_role_type'] !== 1 && status ?<TableCell align="center"><Label variant="ghost" color={status=== 'rejected'? 'error' :'warning'} sx={{textTransform: 'capitalize'}}>{status}</Label> </TableCell>:null}
                        {account['user_role_type'] !== 1 ?<TableCell align="center"> {comments}</TableCell>:null}

                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody> {loading ? <MiscSkeleton record={{number: 6}}/> :
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                       {anomalydata['anamoly_list'] ? anomalydata['anamoly_list'].length <= 0 ? <Typography gutterBottom align="center" variant="subtitle1"> Not Anomaly Data found</Typography> : <SearchNotFound searchQuery={filterPartNo} /> : <Typography gutterBottom align="center" variant="subtitle1"> Not Anomaly Data found</Typography>}
                      </TableCell>
                    </TableRow> }
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
      {account['user_role_type'] === 1 ? <LoadingButton variant="contained" onClick={handleSubmitAdmin} loading={loadingUpdate.update} sx={{ float: 'right', margin: '10px 0px' }}> Submit </LoadingButton> :
      <LoadingButton variant="contained" onClick={handleSubmitNonAdmin} loading={loadingUpdate.update} sx={{ float: 'right', margin: '10px 0px' }}> Request to Admin </LoadingButton>}
       </Container>
    </>
  );
};

