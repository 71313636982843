import * as React from 'react';
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Grid, Box, ListItemText, TextField, Paper, Button, List, ListItem, Dialog, DialogActions, CircularProgress, DialogContent, Tooltip, DialogTitle, ListItemButton, Typography, IconButton, Checkbox, Autocomplete, Divider, Chip, ListItemAvatar, Avatar, Table, TableHead, TableRow, TableCell, TableBody, Popper, Grow, Stack, Menu, MenuItem, ListItemIcon, Fade } from '@mui/material';
import Iconify from '../../components/Iconify';
import { Sign, Post, Get, Put, Delete, GetAll } from '../../utils/apiCalls';
import Label from '../../components/Label';
import { sentenceCase } from 'change-case';
// import "../index.css";
import { apiURL } from '../../utils/apiCalls';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import MenuPopperMapping from './MenuPopperMapping';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCommentIcon from '@mui/icons-material/AddComment';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { createTheme } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TabViewpdfExtract from './tabview_pdfExtract/TabViewpdfExtract';
import { toast } from 'react-toastify';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import  UploadDocumentSkelton from './UploadDocumentSkelton';
import CheckListCreate from '../CheckListCreate';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PagePDFExtract from '../upload_doccuments/tabview_pdfExtract/PagePDFExtract';

const theme = createTheme();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const fileTypes = ["PDF", "JPG", "PNG"];
// const uuid = uuidv4();

const useStyles = makeStyles({
    popper: {
        zIndex: 2000,
        '&[x-placement*="bottom"] $arrow': {
          top: 0,
          left: 0,
          marginTop: "-0.71em",
          marginLeft: 4,
          marginRight: 4,
          "&::before": {
            transformOrigin: "0 100%"
          }
        },
        '&[x-placement*="top"] $arrow': {
          bottom: 0,
          left: 0,
          marginBottom: "-0.71em",
          marginLeft: 4,
          marginRight: 4,
          "&::before": {
            transformOrigin: "100% 0"
          }
        },
        '&[x-placement*="right"] $arrow': {
          left: 0,
          marginLeft: "-0.71em",
          height: "1em",
          width: "0.71em",
          marginTop: 4,
          marginBottom: 4,
          "&::before": {
            transformOrigin: "100% 100%"
          }
        },
        '&[x-placement*="left"] $arrow': {
          right: 0,
          marginRight: "-0.71em",
          height: "1em",
          width: "0.71em",
          marginTop: 4,
          marginBottom: 4,
          "&::before": {
            transformOrigin: "0 0"
          }
        }
      },
    
      arrow: {
        overflow: "hidden",
        position: "absolute",
        width: "1em",
        height: "0.71em", 
        boxSizing: "border-box",
        color: '#FFFF',
        "&::before": {
          content: '""',
          margin: "auto",
          display: "block",
          width: "100%",
          height: "100%",
          boxShadow: theme.shadows[1],
          backgroundColor: "currentColor",
          transform: "rotate(45deg)"
        }
      },
      MapField: {
        "& .addDelete": {
          display: "none",
        },
        "&:hover .addDelete": {
          display: 'flex !important',
        },
      },

  });

export function getBase64(file: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

let uploadList:any = [];
let count = 0;
let indexProducts = -1;
let anchorEl: HTMLDivElement | null = null
type openAnomaly = {
    open: boolean
    width : "lg" | "xl" | "xs" | "sm" | "md"
}

export default function UploadDocumentsCreate(props: any) {
   const classes=useStyles();
   const navigate = useNavigate();
    const [file, setFile]: any = useState({ 'bl': [], 'coo': [], 'packing': [], 'invoice': [] });
    const [signfile, setSignfile]: any = useState({ 'bl': [], 'coo': [], 'packing': [], 'invoice': [] });

    const [openPreview, setOpenPreview] = React.useState(false);
    const [openMap, setOpenMap] = React.useState(false);
    const [mappedData, setMappedData]: any = React.useState({});
    const [loadView, setLoadView] = React.useState(true);

    const [Previewfile, setPreviewfile]: any = React.useState({});
    const [textExtract, settextExtract]: any = React.useState([]);
    const [extractDescriptionIds, setExtractDescriptionIds]: any = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingOpen, setLoadingOpen] = React.useState({ file: 0 , load: false });
    const [loadingfile, setLoadingfile] = React.useState({ file: "", load: false,  });
    const [docsetFiles, setDocsetFiles]: any = React.useState([])
    const [checktextExtractFiles, setChecktextExtractFiles]: any = React.useState({})
    const [products, setProducts]: any = React.useState([]);

const [openComment, setOpenComment] = React.useState({open:false, id:null, load: false});
const [loadingMap, setLoadingMap] = React.useState({ map: false });
const [loadingUpdate, setLoadingUpdate] = React.useState({ update: false, anomalyCheck: true, file: '' });
const [fileMessage, setFileMessage]: any = React.useState({id:"", type:'', message:''});

const [search, setSearch] = React.useState('');
const [openCheckAnomaly, setOpenCheckAnomaly]  = React.useState<openAnomaly>({open: false, width: 'lg'});
const [anomalydata, setanomalyData]: any = React.useState({ anamoly_list: [], anamoly_status: '', file_id: null, uuid: '' });
const [CheckAnomalyConcern, setCheckAnomalyConcern] = React.useState(false);
 const ref:any = React.useRef(null);
 const [anchorElNav, setAnchorElNav] = React.useState<{anchorEl: null | HTMLElement, uploadId: number, fileId: number}>({anchorEl: null, uploadId: 0, fileId: 0});
console.log(anchorElNav)
const [numeric_format, setNumeric_format] = useState(0);
const [billEntry, setBillEntry] = useState({});

//     React.useEffect(() => {
//         if(props.id) {
//         if(props.record) {
//             setLoading(props.record.loading)
//             uploadList = [...props.record.uploadList]
//             setFile(props.record.file)
//             setDocsetFiles(props.record.docsetFiles)
//             setChecktextExtractFiles(props.record.checktextExtractFiles)
//         }
//         }
//     }, [props])

//     React.useEffect(() => {
//          props.fileChange(file)
//  }, [file])

async function GetCall() {
    if (props.id) {
        setLoadView(true)
        const response: any = await Get(apiURL + "/msil_file_upload/", props.id, navigate);
        if (response) {
            if (response.data.status) {
                setLoading(true)
                if (Object.keys(response.data.data.payload).length > 0) {
                    uploadList = response.data.data.uploadList
                    setFile(response.data.data.payload);
                    const responseDocumentsList: any = await Get(apiURL + "/msilDocumentTypes", "", navigate)
                    if (responseDocumentsList) {
                            setDocsetFiles([...responseDocumentsList.data]);
                            setLoading(false)
                            const responseTextextractCheck: any = await Post(apiURL + "/msil_file_upload/checkextractdata", {file_id: props.id}, navigate)
        if (responseTextextractCheck && responseTextextractCheck.status === 200) {
            setLoadView(false)
            setChecktextExtractFiles(responseTextextractCheck.data.data);
            }
            else {
                setLoadView(false)
                setChecktextExtractFiles({})
            }
                        }
                        else {
                            setDocsetFiles([])
                            setLoading(false)
                        }
                }
                else {
                    setFile({ 'bl': [], 'coo': [], 'packing': [], 'invoice': [] });
                }
            }
        }
        else {
            setFile({ 'bl': [], 'coo': [], 'packing': [], 'invoice': [] })
        }
    }
    else {
        navigate('/dashboard/hsclassifier', { replace: true })
    }
};

React.useEffect(() => {
    GetCall()
}, [])

    const loop = async (filelist: any, invoice_name: any) => {
        setLoadingfile({ file: invoice_name, load: true });
        const list = { ...file }
        const listBase64: any = [];
        for (let i = 0; i < filelist.length; i++) {
            if (filelist[i].name)
                await getBase64(filelist[i]).then(
                    data => listBase64.push({ filename: filelist[i].name, data: data, isVerified: false, isSigned: false, docType: invoice_name, id: 0 })
                );
            // else if (filelist[i].filename && !list[invoice_name].some((data: any) => data.filename === filelist[i].filename))
            //     listBase64.push({ filename: filelist[i].filename, data: filelist[i].file_details ? filelist[i].file_details : filelist[i].data, isVerified: false, isSigned: false, docType: invoice_name, id: 0 })
        };
        console.log(listBase64)
        if (listBase64.length > 0) {
            const response: any = await Post(apiURL + "/msil_file_upload", { uploadList: uploadList, payload: listBase64, file_id: props.id }, navigate)
            if (response) {
                if (response.data.status) {
                    list[invoice_name].push(...response.data.data.payload);     
                    const responseTextextractCheck: any = await Post(apiURL + "/msil_file_upload/checkextractdata", {file_id: props.id}, navigate)
                    if (responseTextextractCheck && responseTextextractCheck.status === 200) {
                        setLoadView(false)
                        setLoadingfile({ file: invoice_name, load: false });             
                        setChecktextExtractFiles(responseTextextractCheck.data.data);
                        setFile(list);
                        setOpenComment({open:true, id: response.data.data.payload[0].id, load: false})
                        }
                        else {
                            setLoadView(false)
                            setChecktextExtractFiles({})
                        }
                        setFileMessage({id: invoice_name, type: 'success', message:'Uploaded Sucessfully! Upload Another'})
                        toast.success(response.data.message);
                }
                else {
                    setLoadingfile({ file: invoice_name, load: false });
                    toast.error(response.data.message);
                }
            }
            else {
                setLoadingfile({ file: invoice_name, load: false });
                toast.error("Reason: CORS request did not succeed");
            }
        }
    };

    const handleUploadFile = async (filelist: any, invoice_name: any, selection: boolean) => {
        if (filelist.length > 0) {
                // if (filelist[0].filename) {
                //     var result = list.findIndex(function (value: any) {
                //         if (value.data) { return value.data.split(";base64,")[1] === filelist[0].data.split(";base64,")[1] } else { return value.filename.split(";base64,")[1] === filelist[0].filename.split(";base64,")[1] }
                //     })
                //     list.splice(result, 1);
                // }
                await loop([...filelist], invoice_name);
        }
    };

    const handleDocName = async (invoice_name: any, index:number) => {
        uploadList[index]['doc_name'] =  invoice_name
    };

    const handleFileOpen = async (event: any, file: any, invoice_name: string, index: number, concern: boolean) => {
        // const responseCheck: any = await Post(apiURL + "/file_upload/chechextractdata", { file_id: file.id });
        setCheckAnomalyConcern(concern)
        setLoading(true);
        setLoadingOpen({file: file.id , load: true})
        let updatedList: any = [];
        let finalResult:any = {}
        setPreviewfile({ fileBase64: file.data, docType: invoice_name, file_id: file.id, filename: file.filename })
        // if (responseCheck.data.status) {
            // if (responseCheck.data.data[0].extractData !== null) {
            //     setLoading(false);
            //     setLoadingOpen({file: file.id , load: false})
            //     for (let i = 0; i < Object.keys(responseCheck.data.data[0].extractData).length; i++) {
            //         if (Object.keys(responseCheck.data.data[0].extractData)[i] !== "" && Object.keys(responseCheck.data.data[0].extractData)[i] && !Object.keys(responseCheck.data.data[0].extractData)[i].includes("Unnamed") && Object.values(responseCheck.data.data[0].extractData)[i] !== "") {
            //             let key = Object.keys(responseCheck.data.data[0].extractData)[i].trim();
            //             let value: any = Object.values(responseCheck.data.data[0].extractData)[i]
            //             updatedList.push({ [key]: value })
            //         }
            //     }
            //     settextExtract(updatedList)
            //     const responseMappedData: any = await Get(apiURL + "/logisys/getmapping", "");
            //     if(responseMappedData.data.status)
            //     setMappedData(responseMappedData.data.data)
            // }
            // else {
            //     const responseTextExtractImage: any = await Post(apiURL + "/textract/pdfimage", { id: props.id, payload: [{ filename: file.filename, data: file.data, file_path: file.file_path, docType: invoice_name, id: file.id }] }, navigate);
            //      if(responseTextExtractImage && responseTextExtractImage.status === 200)
            //      {
            //         const response: any = await Post(apiURL + "/textract/newTextract", { data: responseTextExtractImage.data.data }, navigate);
                
            //     if (response && response.status === 200) {
            //         setLoading(false);
            //         setLoadingOpen({file: file.id , load: false})
            //         for (let i = 0; i < Object.keys(response.data['forms']).length; i++) {
            //             if (Object.keys(response.data['forms'][i])[0] !== "" && Object.keys(response.data['forms'][i])[0] && !Object.keys(response.data['forms'][i])[0].includes("Unnamed") && Object.values(response.data['forms'][i])[0] !== "") {
            //                 let key = Object.keys(response.data['forms'][i])[0].trim();
            //                 let value: any = Object.values(response.data['forms'][i])[0]
            //                 let keyMapped = Object.keys(response.data['forms'][i])[1];
            //                 let valueMapped: any = Object.values(response.data['forms'][i])[1]
            //                 updatedList.push({ [key]: value, [keyMapped] : valueMapped})
            //                finalResult = {forms: updatedList, tables: response.data['tables'], count: response.data['count']}
            //             }
            //         }
            //         settextExtract(finalResult)
            //         setOpenPreview(true);
            //         const responseMappedData: any = await Get(apiURL + "/msilMapping/getmapping", "", navigate);
            //     if(responseMappedData.data.status)
            //     setMappedData(responseMappedData.data.data)
            //     }
            //     else {
            //         setLoading(false);
            //         setLoadingOpen({file: file.id , load: false})
            //         settextExtract([])
            //         setOpenPreview(true);
            //     };
            // }
            // else {
            //    setLoading(false);
            //    setLoadingOpen({file: file.id , load: false})
            //    settextExtract([])
            //    setOpenPreview(true);
            // }
        //     }
        // }
        // else setLoadingOpen({file: file.id , load: false})
      
        const response: any = await Post(apiURL + "/textract/pdfimage", { payload: [{ filename: file.filename, file_path: file.file_path }] }, navigate);
        if (response && response.status === 200 && response.data.status) {
           try {
           const MapObj = await Promise.all(response.data.data.map(async (val:any, i:number)=> {
             var obj =  await Post(apiURL + "/textract/newTextract", { data: [val] }, navigate)
             if(obj && obj.data) {
              return {...obj.data}
             }
           }));
           
        //    Sanko shoji co.,LTD



           settextExtract(MapObj)
           setOpenPreview(true);
           setLoading(false);
           setLoadingOpen({ file: file.id, load: false })
              const responseMappedData: any = await GetAll(apiURL + "/msilMapping/getmapping", navigate);
           if (responseMappedData && responseMappedData.data && responseMappedData.data.status)
              setMappedData(responseMappedData.data.data)

              const responeBillEntry = await Get(apiURL + '/msil_file_upload/dataskeleton/', file.id, navigate)
              if(responeBillEntry && responeBillEntry.data && responeBillEntry.data.status)
              setBillEntry(responeBillEntry?.data.data)
           } catch (e) {
                setLoading(false);
           setLoadingOpen({ file: file.id, load: false })
       }
        }
        else {
            setLoading(false);
        setLoadingOpen({file: file.id , load: false}) 
        toast.error('Please Try Again')
        }
    };

    const handleFileView = async (event: any, file: any, invoice_name: string, index: number, concern: boolean) => {
        setCheckAnomalyConcern(concern)
        setLoading(true);
        setLoadingOpen({file: file.id , load: true})
        let updatedList: any = []
        setPreviewfile({ fileBase64: file.data, docType: invoice_name, file_id: file.id, filename: file.filename })
      
                const responseCheck: any = await Post(apiURL + "/msil_file_upload/newfileview", { file_id: file.id }, navigate);
                if (responseCheck && responseCheck.status === 200 && responseCheck.data.status) {
                    if (responseCheck.data.data[0].extractData !== null) {
                       setLoading(false);
                       setOpenPreview(true);
                       setLoadingOpen({ file: file.id, load: false })

                       const finalData = responseCheck.data.data[0].extractData

                        //   Sanko shoji co.,LTD
                    //    if(finalData[0]['tables']['table1'] && finalData[0]['tables']['table1']['values']) {
                    //    const val = finalData[0]['tables']['table1']['values'][0]['key1']
                    //    if(val && val.split(':')[1]) {
                        
                    //     finalData[0]['tables']['table1']['values'][0]['key0'] = finalData[0]['tables']['table1']['values'][0]['key0'] + val.split(':')[0]
                    //     finalData[0]['tables']['table1']['values'][0]['key1'] = val.split(':')[1]
                    //    }
                    // }
                    /////////////////////////////////////////////

                    //    Carrier Asian Limited

                    // if(finalData[1] && finalData[1]['tables']['table0']) {
                    //     const val = finalData[1]['tables']['table0']['values']
                    //     val.map((data, i)=> {
                    //         if(i < 2) {
                    //             Object.keys(data).map((va, ind)=> {
                    //                 finalData[1]['tables']['table0']['headers'][va]['value'] = finalData[1]['tables']['table0']['headers'][va]['value'] + Object.values(data)[ind]
                    //             })                       
                    //         }
                    //     })
                    //     finalData[1]['tables']['table0']['values'] = val.slice(2)
                    // }
                    ////////////////////////////////////////////////

                    //    Asian Maruichi (Thailand) CO. LTD.

                //     if(finalData[0] && finalData[0]['tables']['table0']) {
                //         const val:any = finalData[0]['tables']['table0']['headers']
                //         const valueArr = finalData[0]['tables']['table0']['values']

                //         var obj = {}
                //         const res = valueArr.reduce((init, d, ind)=> {
                //             var objVal = {}
                //             Object.keys(d).map((v, m)=> {
                //                 if(m !==0 && m < 5) {
                //                     if(m === 1) {
                //                         objVal[v] = Object.values(d)[m]
                //                     }
                //                     else {
                //                         objVal['key1'] = objVal['key1'] + Object.values(d)[m]
                //                     }
                //                 }
                //                 else {
                //                     if(m===0) {
                //                    objVal[v] = Object.values(d)[m]
                //                     }
                //                 else {
                //                    var lastkeyIndex = parseInt(Object.keys(objVal)[Object.keys(objVal).length -1].split('key')[1])
                //                    objVal[`key${lastkeyIndex+1}`] = Object.values(d)[m]
                //                 }
                //                 }
                //             })
                //             init.push(objVal)
                //             return init
                //         }, [])


                //         finalData[0]['tables']['table0']['values'] = res 
                         
                //         Object.keys(val).map((data, i)=> {
                //             var objValue: any = Object.values(val)[i]
                //             if(i !==0 && i < 5) {
                //                 if(i === 1) {
                //                 obj[data] = objValue
                //                 }
                //                 else {
                //                     obj['key1']['value'] = obj['key1']['value'] + objValue['value']
                //                 }
                //             }
                //             else {
                //                if(i===0) {
                //                 obj[data] = Object.values(val)[i]
                //                  }
                //              else {
                //                 var lastkeyIndex = parseInt(Object.keys(obj)[Object.keys(obj).length - 1].split('key')[1])
                //                 obj[`key${lastkeyIndex+1}`] =Object.values(val)[i]
                //              }
                //             }
                //         })

                //         finalData[0]['tables']['table0']['headers'] = obj 
                //     }


                // console.log(finalData)

                   //////////////////////////////////////////////////////////////////

                    //   Daido Metal

                    // if(finalData[1] && finalData[0]['tables']['table1']) {
                    //     const val = finalData[0]['tables']['table1']['values']
                    //    const result =  val.reduce((res, data, i)=> {
                    //         if(i !==0 && i < 3) {
                    //            Object.keys(data).map((d, ind)=> {
                    //             res[0][d] = res[0][d] + Object.values(data)[ind]
                    //            })              
                    //         }
                    //         else {
                    //             res.push(data)
                    //         }
                    //         return res
                    //     }, [])
                    //     finalData[0]['tables']['table1']['values'] = result
                    // }

                    ////////////////////////////////////////////////


                       settextExtract(finalData)
                       setNumeric_format(responseCheck.data.data[0].numeric_format)
                       const responseMappedData: any = await GetAll(apiURL + "/msilMapping/getmapping", navigate);
                       if (responseMappedData.data.status)
                          setMappedData(responseMappedData.data.data)

                          const responeBillEntry = await Get(apiURL + '/msil_file_upload/dataskeleton/', file.id, navigate)
                          if(responeBillEntry && responeBillEntry.data && responeBillEntry.data.status)
                          setBillEntry(responeBillEntry?.data.data)
                    }
                    else {
                       setLoading(false);
                       setLoadingOpen({ file: file.id, load: false })
                       settextExtract([])
                       setOpenPreview(true);
                    }
                 }
                 else {
                    setLoadingOpen({ file: file.id, load: false })
                    toast.error("Unable to Open");
                 }
    };

    const handleClosePreview = () => {
        setOpenPreview(false);
        setLoading(false);
        settextExtract([])
        setProducts([])
        indexProducts = -1;
    };

    const handleTabNext = () => {
        props.parentCallback(1)
    };

    const handleSign = async (filename: any, invoice_name: string, index: number) => {
        // const list = { ...signfile }
        const list = {...file}
        const response = await Sign(filename.data);
        if (response !== "Please Connect PKI" && response.includes('data:application/pdf;base64,')) {
            // setPreviewfile(response)
            // list[invoice_name].push({ signed: 1, i: index })
            // setSignfile(list)
            list[invoice_name][index]['data'] = response
            list[invoice_name][index]['isSigned'] = true
            const responseFileEdit = await Put(apiURL + "/file_upload/", filename.id, {file_data:response, isSigned: true, uuid: props.id, reference_no: props.record.reference_no }, navigate);
            if(responseFileEdit && responseFileEdit.data.status) {
            setFile(list)
            toast.success("Docuemnt Signed Sucessfully")
            }
        }
        else {
            toast.error(response)
        }
    };

    const handleAddDocs = () => {
        count = count + 1
        const listSign = { ...signfile }
        const list = { ...file }
        uploadList.push({ id: 'document' + count, title: 'Upload Documents', doc_name:"" })
        list['document' + count] = [];
        listSign['document' + count] = [];

        setSignfile(listSign)
        setFile(list)
    };

    const handleRemoveDocCard = (i: number) => {
        uploadList.splice(i, 1);

    };

    const handleFileError = (file: any, invoice_name:any) => {
        setFileMessage({id: invoice_name, type: 'error', message:'File Size Error'})
        toast.error(file)
    };

    const handleTypeError = (err: any, invoice_name:any) => {
        setFileMessage({id: invoice_name, type: 'error', message:'Select/Drop Correct File Type'})
        toast.error(err)
    };

    // const handleTitle = (event: any, id: string) => {
    //     const listIndex = uploadList.findIndex((obj => obj.id == id))
    //     uploadList[listIndex].title = event.currentTarget.innerText
    // };

    const handleDeleteFile = async (fileData: any, invoice_name: string) => {
        console.log(fileData)
        const list = { ...file };
        const response: any = await Delete(apiURL + "/msil_file_upload/", fileData.id, navigate);
        if (response.data.status === true) {
            var result = list[invoice_name].findIndex(function (value: any) {
                if (value.id) { return value.id === fileData.id } 
            })
            list[invoice_name].splice(result, 1);
            setAnchorElNav({anchorEl: null, uploadId: 0, fileId: 0})
            setFile(list);
            setFileMessage({id: invoice_name, type: 'success', message:'File Deleted Succesfully'})
            toast.success(response.data.message);
            const responseTextextractCheck: any = await Post(apiURL + "/msil_file_upload/checkextractdata", {file_id: props.id}, navigate)
                if (responseTextextractCheck && responseTextextractCheck.status === 200) {
                    setLoadView(false)
                    setChecktextExtractFiles(responseTextextractCheck.data.data);
                    handleCloseNavMenu()
                    }
                    else {
                        setLoadView(false)
                        setChecktextExtractFiles({})
                    }
        }
        else {
            toast.error(response.data.message);
        };
    }

    // const handleCheckList = (event: any, i: number) => {
    //     const list = [...extractDescriptionIds]

    //     if (event.target.checked) {
    //         list.push(i)
    //     }
    //     else {
    //         const index = list.indexOf(i);
    //         if (index > -1) {
    //             list.splice(index, 1);
    //         }
    //     };

    //     setExtractDescriptionIds(list);
    // }

    // const handleInputDescription = (event: any, newInputValue: any, index: number, data: any) => {
    //     const list = [...textExtract]
    //     const listProducts = [...products]

    //     let id = Object.keys(data)[0];
    //     let actualValue = Object.values(data)[0]
    //     if (event) {
    //         event.preventDefault();
    //         list[index][newInputValue] = actualValue

    //         if (newInputValue !== 'Assign Description of Goods' && data.ind) {
    //             for (var i = Object.keys(data.ind).length - 1; i >= 0; i--) {
    //                 listProducts.splice(Object.values(data.ind)[i] as number, 1)
    //             }
    //             setProducts(listProducts)
    //         }
    //         if (id !== "") {
    //             delete list[index][id]
    //         }
    //         else {
    //             delete list[index][""]
    //         }

    //         if (list[index].ind && newInputValue !== 'Assign Description of Goods') {
    //             delete list[index].ind
    //         }
    //         settextExtract(list);
    //     }
    // };

    // const isLiteralObject = function (a: { constructor: ObjectConstructor }) {
    //     return (!!a) && (a.constructor === Object);
    // };

    // const handleProducts = (e: any, data: any, index: number, newValue: any) => {
    //     const list = [...products]
    //     const listExtract = [...textExtract]
    //     // indexProducts = index;

    //     let actualValue: any = Object.values(data)[0]
    //     if (isLiteralObject(actualValue) && newValue === 'Assign Description of Goods') {
    //         listExtract[index]['ind'] = {}
    //         for (var i = 0; i < Object.values(actualValue).length; i++) {
    //             list.push({ description: Object.values(actualValue)[i], unit_price: '' })
    //             listExtract[index].ind[Object.keys(actualValue)[i]] = list.length - 1
    //         }

    //         setProducts(list)
    //         settextExtract(listExtract)
    //     }
    //     else {
    //         if (newValue === 'Assign Description of Goods') {
    //             listExtract[index]['ind'] = list.length
    //             setProducts([...products, { description: actualValue, unit_price: '' }])
    //         }
    //     }
    // };

    // const handleProductsValues = (e: any, val: any, prodIndex: any) => {
    //     const list = [...products];

    //     if (prodIndex !== -1) {
    //         list[prodIndex].unit_price = e.target.value
    //     }
    //     setProducts(list)
    // };

    const handleTextExtractData = (data:any) => {
        submitTextextractValues(data)
    };

   const SendDataAnomalyCheck = async (event:any, id:any, concern: boolean) => {
    setCheckAnomalyConcern(concern)
    setLoadingUpdate({update: false, anomalyCheck: true, file: id })
        const response: any = await Post(apiURL + "/msilMapping/check_hs_anomalies", { uuid: props.id, file_id: id }, navigate);
       if(response) {
        if (response.data.status) {
          setanomalyData({ anamoly_list: response.data.data['anamoly_list'], anamoly_status: response.data.data['anamoly_status'], file_id: id, uuid: props.id })
          setLoadingUpdate({update: false, anomalyCheck: false, file: id })
          toast.error(response.data.message)
          setOpenCheckAnomaly({open: true, width: 'lg'})
        }
        else {
        const responseCheckAnomaly: any = await Post(apiURL + "/msilMapping/get_anomalies_status", { uuid: props.id }, navigate);
        if(responseCheckAnomaly.data.status && responseCheckAnomaly.data.message === 'Anomalies Found') {            
        sessionStorage.setItem('anamoly_check', 'pending')
        if(props.params === 'create')
        navigate(`/dashboard/hsclassifier/create?pending`)
        else 
        navigate(`/dashboard/hsclassifier/${props.id}?pending`)
        }
        else {
        sessionStorage.setItem('anamoly_check', 'completed')
        if(props.params === 'create')
        navigate(`/dashboard/hsclassifier/create?completed`)
        else 
        navigate(`/dashboard/hsclassifier/${props.id}?completed`)
        }

        const responseTextextractCheck: any = await Post(apiURL + "/msil_file_upload/checkextractdata", {file_id: props.id}, navigate)
        if (responseTextextractCheck && responseTextextractCheck.status === 200) {
            setChecktextExtractFiles(responseTextextractCheck.data.data);               
            }
            else {
                setChecktextExtractFiles({})
           setLoadingUpdate({update: false, anomalyCheck: false, file: id })
            }
            
          setanomalyData({ anamoly_list: [], anamoly_status: '', file_id: null, uuid: '' })
          toast.success('No Anomaly Found');
          setOpenCheckAnomaly({open: false, width: 'lg'})
          setLoadingUpdate({update: false, anomalyCheck: false, file: id })
          handleClosePreview();
        }
    }
    else {
        setLoadingUpdate({update: false, anomalyCheck: false, file: id })
    }
      };

    const submitTextextractValues = async (fileList: any) => {
        const list = { ...file }
            const listIndex = list[fileList.docType].findIndex(((obj: any) => obj.id == fileList.file_id))
            list[fileList.docType][listIndex].isVerified = true;
            setFile(list);
            SendDataAnomalyCheck('',fileList.file_id, true)
    };

    const handleDeleteCard = (file_name: string, index: number) => {
         const listFile = {...file}
         delete listFile[file_name]
         uploadList.splice(index, 1)
         setFile(listFile);    
    };

    const handleCloseDialog = () => {
        setOpenMap(false)
    };

    // const handleListItemClick = (value: string) => {
    //     handleCloseDialog()
    //   };

    //   const handleCloseComment =()=> {
    //     if (anchorEl)
    //     setOpenComment(false);
    //   anchorEl = null;
    //   };

      const handleSubmitComment = async (data:any) => {
        setOpenComment({open:true, id: data.id, load: true})
        const responseFileEdit = await Put(apiURL + "/file_upload/filecomment", '', {comment: data.comment, file_uploadId: data.id}, navigate);
            if(responseFileEdit && responseFileEdit.data.status) {
                setOpenComment({open:false, id: data.id, load: false})
                toast.success("Comment Updated Sucessfully")
            }
            else if(responseFileEdit) {
                toast.error(responseFileEdit.data.message);
            }
        };

      const handleCommentPDF = (e:any, data:any, doc_name: string, index:number) => {
        const list = { ...file }
        const { id, value } = e.target
        list[doc_name][index][id] = value

        setFile(list);
      };

      const handleAddMap = () => {
        const list = {...mappedData};
        var obj = {}
        var key =''
        for(var i=0; i < Object.keys(list.headers_data).length; i++){
            key = Object.keys(list.headers_data)[i]
            obj = Object.assign(obj, {[key] : ''})
        }
        list.values_data.push(obj)
         setMappedData(list);
         ref.current.scrollIntoView({ behavior: 'smooth',block: 'end',inline: 'nearest' });
      };


      const deleteMap = (e: any, data: any, i: any, dI: any ) => {
        e.preventDefault();
        const list = {...mappedData};
        if (list.values_data[i]) {
            list.values_data[i][data] = ''
        }
    
        return setMappedData(list);
      };

      const handleEditMap = (e: any, data: any, i: any, dI: any ) => {
        const list = {...mappedData};
        if (list.values_data[i]) {
            list.values_data[i][data] = e.target.value
        };
         setMappedData(list);
      };


      const submitMappedData = async () => {
        setLoadingMap({map: true})
        const response: any = await Put(apiURL + "/msilMapping/updatemapping", "", { ...mappedData }, navigate);
        if (response.data.status === true) {    
            setLoadingMap({map: false})
            handleCloseDialog();
        }
        else {
            setLoadingMap({map: false})
            toast.error(response.data.message)
        }
    };

    const handleSearch = (e:any) => {
        setSearch(e.target.value)
    };

    const handleCloseAnomaly = async () => {
        setOpenCheckAnomaly({open: false, width: 'lg'})
        handleClosePreview();
        const responseTextextractCheck: any = await Post(apiURL + "/msil_file_upload/checkextractdata", {file_id: props.id}, navigate)
        if (responseTextextractCheck && responseTextextractCheck.status === 200) {
            setLoadView(false)
            setChecktextExtractFiles(responseTextextractCheck.data.data);               
            }
            else {
                setLoadView(false)
                setChecktextExtractFiles({})
            }
    };

    const handleCallbackAnomaly = (data:any) => {
        handleCloseAnomaly()       
    };

    const handleWidthDialog = () => {
        if(openCheckAnomaly.width === 'lg')
         setOpenCheckAnomaly({open: true, width: 'xl'})
         else 
         setOpenCheckAnomaly({open: true, width: 'lg'})
    }

   const exportAdminIssuedCo = async (file_id:number) => {
    const response: any = await Post(apiURL + "/msil_file_upload/exportcsv", {file_id: file_id, uuid: props.id }, navigate)
    if(response.data.status){
            // let exportIssuedCos = response.data['data'];
            let exportCSV:any = [...response.data.data['data_list']];
             
            let csvName = response.data.data['invoice_no']+ '.csv'
           exportToCsv(csvName, exportCSV);
    }
      }
      
     const exportToCsv = (filename: string, rows: any) => {
        if (!rows || !rows.length) {
          return;
        }
        const separator = ',';
        const keys = Object.keys(rows[0]);
        
        const csvContent =
          keys.join(separator) +
          '\n' +
          rows.map((row:any, i:number) => {
            return keys.map(k => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              cell = cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""');
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            }).join(separator);
          }).join('\n');
      
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        // if (navigator['msSaveBlob']) { // IE 10+
        // //   navigator.msSaveBlob(blob, filename);
        // toast.error('Please Try in Chrome Brower')
        // } else {
          const link = document.createElement('a');
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            handleCloseNavMenu()
          }
        // }
      };

      const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>, i:number, index: number) => {
        setAnchorElNav({anchorEl: event.currentTarget, uploadId: i, fileId: index});
      };

      const handleCloseNavMenu = () => {
        setAnchorElNav({anchorEl: null, uploadId: 0, fileId: 0});
      };

    return (
        <>
        { uploadList.length > 0 ? <Stack spacing={1} >
            <Grid container spacing={2}>
                {uploadList.map((list: any, i: number) => (
                    <Grid item xs={6} key={i} >
                        <Paper elevation={3} sx={{padding:'20px'}}>
                        <Grid container spacing={2}>
                          <Grid item xs={11} >
                            <Autocomplete freeSolo id="tab" disableClearable fullWidth value={list.doc_name}
                                onChange={(event: any, newValue: string | null) => handleDocName(newValue, i)}
                                 options={docsetFiles.map((option:any) => option.document_name)} loading={loading}
                                // isOptionEqualToValue={(option, value) => option.document_name === value.document_name}
                                // getOptionLabel={(option) => option.document_name}
                                renderInput={(params: any) => (<TextField {...params} variant="filled" label="DOCUMENT TYPE" fullWidth helperText= " " />)}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} key={option.id}
                                    // onClick={(e:any) => handleUploadFile(option, list.id, selected)} 
                                    >
                                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                        {option}
                                    </li>
                                )}
                            />
                            </Grid>
                            <Grid item xs={1} >
                           {file[list.id] ?  file[list.id].length <= 0 ? <IconButton edge="end" onClick={() => handleDeleteCard(list.id, i)}><Iconify icon="eva:trash-2-outline" width={24} height={24} color="#FF4842" /></IconButton> :null :null}
                           </Grid></Grid>
                            {/* {i >= 4 ?<IconButton edge="end" onClick={()=>handleRemoveDocCard(i)} sx={{float:'right'}}><Iconify icon="el:remove-circle" color="#FF4842" sx={undefined} /> </IconButton> :null} */}
                            {/* <p style={{ display: 'flex', margin: 'auto' }} contentEditable={i >= 4 ? "true" : "false"} suppressContentEditableWarning={true} onInput={(e: any) => handleTitle(e, list.id)}>
                                <Typography sx={{ overflowWrap: 'anywhere', fontWeight: 'bolder' }} variant="h4"><Iconify icon="bx:upload" sx={undefined} /> {list.title}</Typography>
                                {i >= 4 ? <Iconify icon="clarity:edit-line" color="#2065d1" sx={{ m: '2%', width: '1.5em', height: '1.5em' }} /> : null} </p> */}
                          <Grid container spacing={2}>
                               <Grid item xs={11} > 
                           <FileUploader multiple={true} handleChange={(e: any) => handleUploadFile(e, list.id, false)} name="file" label="Upload or drop a file Accepts 25MB" types={fileTypes} maxSize={25} onSizeError={(file: any)=>handleFileError(file, list.id)} onTypeError={(err:any)=>handleTypeError(err, list.id)} hoverTitle=" "
                           children={    
                           <div style={{width:'100%', height:'80px', cursor: 'pointer', alignItems:'center', display:'flex', padding:'8px 16px 8px 8px', border:'2px dashed rgb(6, 88, 194)', borderRadius: '5px', color: (fileMessage.id === list.id && fileMessage.type === 'error') ? 'red' : 'inherit'}}>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="#0658C2"></path></svg>                 
                               <div style={{display: 'flex', justifyContent: 'space-between', flexGrow: 1, alignItems: 'center'}}> 
                                {fileMessage.id === list.id ? <span>{fileMessage.message}</span> : <span>Upload or drop a file Accepts 25MB </span>}
                                <span>PDF, JPG, PNG</span>
                            </div>
                            </div>
                        } />
                              </Grid></Grid>
                            {file[list.id] ? file[list.id].length > 0 ? file[list.id].map((data: any, index: number) => (
                                <List key={index}>
                                    <ListItem 
                                    // secondaryAction={
                                    // <>
                                    //     <LoadingButton variant="contained" loading={loadingOpen.file === data.id && loadingOpen.load ? true : false} onClick={(event) => handleFileOpen(event, data, list.id, index, true)} sx={{ backgroundColor: '#3f51b5', m: '8px' }} > Text Extract </LoadingButton>
                                    //    {checktextExtractFiles && checktextExtractFiles[list.id] && checktextExtractFiles[list.id][index] ?
                                    //    checktextExtractFiles[list.id][index]['view'] ? <><Tooltip title="View" followCursor><IconButton onClick={(event) => handleFileView(event, data, list.id, index, true)} > <VisibilityIcon /> </IconButton></Tooltip>
                                    //   {checktextExtractFiles[list.id][index]['anomaliy_check'] ? <Tooltip title="Anomalies found" followCursor><IconButton onClick={(e) =>SendDataAnomalyCheck(e, data.id, false)}>{(loadingUpdate.anomalyCheck && loadingUpdate.file === data.id) ?  <CircularProgress size={24}/> :<Iconify icon="ic:outline-warning" sx={{color: '#b78103'}} />} </IconButton></Tooltip> :
                                    //   <Tooltip title="No Anomaly found" followCursor><IconButton onClick={()=>toast.success('No Anomaly found')}><Iconify icon="fluent-mdl2:completed-solid" sx={{color: '#229a16'}} /></IconButton></Tooltip> }
                                    //   </>
                                    //    :null :null}

                                    // {/* {data.isSigned ? <Label variant="ghost" color='success' endIcon={<Iconify icon="icon-park:correct" />} sx={{ boxShadow: '0 8px 16px 0 rgba(32, 101, 209, 0.24)', minHeight: '40px' }}>
                                    //     {sentenceCase("Signed")} </Label> :
                                    //     // <Button variant="contained" onClick={() => handleSign(data, list.id, index)} sx={{ backgroundColor: '#3f51b5', m: '8px' }}> Sign </Button>}   */}
                                        // {/* <Tooltip title="Write Comment" followCursor><IconButton onClick={(e:any)=> (setOpenComment({open:true, id: data.id, load: false}))}> <AddCommentIcon /> </IconButton></Tooltip> */}
                                    // <Tooltip title="Compress File" followCursor><IconButton target="_blank" href="https://www.ilovepdf.com/compress_pdf" ><FolderZipIcon /></IconButton></Tooltip>
                                    // <Tooltip title="Delete" followCursor><IconButton edge="end" onClick={() => handleDeleteFile(data, list.id)}><Iconify icon="eva:trash-2-outline" width={24} height={24} color="#FF4842" /></IconButton></Tooltip> 
                                    // </>}
                                     >
                                        {/* {loadingOpen.file === data.id && loadingOpen.load ? <div style={{justifyContent: 'center', display:'flex', width:'100%'}}><CircularProgress size={24} /></div> :
                                        <ListItemButton onClick={(event) => handleFileOpen(event, data, list.id, index)} sx={{ maxWidth: '95%', paddingRight: 0 }}>
                                            <ListItemText>{data.filename} </ListItemText>
                                        </ListItemButton>} */}
                                         <ListItemText primary={data.filename} sx={{wordBreak: 'break-all'}} />
                                         {/* <> */}
                                        <LoadingButton variant="contained" loading={loadingOpen.file === data.id && loadingOpen.load ? true : false} onClick={(event) => handleFileOpen(event, data, list.id, index, true)} sx={{ backgroundColor: '#3f51b5', minWidth:'30%' }} > Text Extract </LoadingButton>
                                        {/* {loadView?<Tooltip title="View" followCursor><IconButton onClick={(event) => handleFileView(event, data, list.id, index, true)} > <VisibilityIcon /> </IconButton></Tooltip>:null} */}
                                       {checktextExtractFiles && checktextExtractFiles[list.id] && checktextExtractFiles[list.id][index] ?
                                       checktextExtractFiles[list.id][index]['view'] ? <><Tooltip title="View" followCursor><IconButton onClick={(event) => handleFileView(event, data, list.id, index, true)} > <VisibilityIcon /> </IconButton></Tooltip>
                                      {checktextExtractFiles[list.id][index]['anomaliy_check'] ? <Tooltip title="Anomalies found" followCursor><IconButton onClick={(e) =>SendDataAnomalyCheck(e, data.id, false)}>{(loadingUpdate.anomalyCheck && loadingUpdate.file === data.id) ?  <CircularProgress size={24}/> :<Iconify icon="ic:outline-warning" sx={{color: '#b78103'}} />} </IconButton></Tooltip> :
                                      <Tooltip title="No Anomaly found" followCursor><IconButton onClick={()=>toast.success('No Anomaly found')}><Iconify icon="fluent-mdl2:completed-solid" sx={{color: '#229a16'}} /></IconButton></Tooltip> }
                                      </>
                                       :null :null}

<IconButton size="large" aria-label="File Upload Options" aria-controls="menu-appbar" aria-haspopup="true" onClick={(e)=>handleOpenNavMenu(e, i, index)} color="inherit" >
              <MoreVertIcon />
            </IconButton>
            {/* <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left',  }} keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu}
              sx={{ }} > */}
              <Menu
        anchorEl={anchorElNav.anchorEl} keepMounted
        id="account-menu"
        open={Boolean(anchorElNav.anchorEl && anchorElNav.uploadId === i &&  anchorElNav.fileId === index)}
        onClose={handleCloseNavMenu} TransitionComponent={Fade}
        PaperProps={{
          elevation: 12,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 15,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
                 <MenuItem onClick={() => exportAdminIssuedCo(data.id)}> 
                <ListItemIcon>
                <FileDownloadIcon />
          </ListItemIcon>
          <ListItemText>Download CSV</ListItemText>
                {/* <Tooltip title="Delete" followCursor><IconButton edge="end" onClick={() => handleDeleteFile(data, list.id)}><Iconify icon="eva:trash-2-outline" width={24} height={24} color="#FF4842" /></IconButton></Tooltip> */}
                 </MenuItem>
                <MenuItem component='a' target="_blank" href="https://www.ilovepdf.com/compress_pdf">
                <ListItemIcon>
                <FolderZipIcon />
          </ListItemIcon>
          <ListItemText>Compress File</ListItemText>
                {/* <Tooltip title="Compress File" followCursor><IconButton target="_blank" href="https://www.ilovepdf.com/compress_pdf" ><FolderZipIcon /></IconButton></Tooltip> */}
                </MenuItem>
                <MenuItem onClick={() => handleDeleteFile(data, list.id)}> 
                <ListItemIcon>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} color="#FF4842" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
                {/* <Tooltip title="Delete" followCursor><IconButton edge="end" onClick={() => handleDeleteFile(data, list.id)}><Iconify icon="eva:trash-2-outline" width={24} height={24} color="#FF4842" /></IconButton></Tooltip> */}
                 </MenuItem>
            </Menu>

                                       {/* 
                                      */}
                                    {/* </> */}
                                    </ListItem>
                                    {/* <div>{commentValue}</div> */}
                                    {/* <ListItem> */}
                                      {/* {(openComment.open && openComment.id === data.id) ?  <>  <TextField id='comment' variant="filled" multiline label=""  value={data.comment} onChange={(e: any) => handleCommentPDF(e, data, list.id, index)} fullWidth/>
                                    <LoadingButton variant="contained" onClick={() => handleSubmitComment(data)} loading={(openComment.id === data.id && openComment.load)? true: false} sx={{ backgroundColor: '#3f51b5', ml:'3px' }}> Save </LoadingButton>
                                    </> : <div style={{display:'flex', alignItems:'center'}}><Typography sx={{mr:'10px'}}>Comment:</Typography> <span style={{color:'#e65100'}}>{data.comment}</span></div>} */}
                                    {/* </ListItem> */}
                                    <Divider variant="middle" sx={{display: file[list.id].length - 1 === index ?  'none': 'inherit'}}/>
                                </List>
                            )) : null :null}

                            {(loadingfile.file === list.id && loadingfile.load) ? <div className='AlignLoad'><div className="dotelastic"></div></div> : null}

                        </Paper>
                    </Grid>
                ))}
            </Grid>
            </Stack> :  <UploadDocumentSkelton /> }
            
            <Grid container spacing={2}>
                <Grid xs={12} sx={{ padding: '30px', justifyContent: 'center', display: 'flex' }} justifyContent="center" >
                    <Button variant="contained" onClick={handleAddDocs} startIcon={<Iconify icon="carbon:add-alt" sx={undefined} />} > Add Additional Documents </Button>
                </Grid></Grid>

            <Button variant="contained" onClick={handleTabNext} sx={{ float: 'right' }}> Next </Button>

            <Dialog open={openPreview} onClose={handleClosePreview} fullWidth maxWidth="xl">
                {/* <DialogTitle>
                <Grid container spacing={2} >

<Grid item xs={6} sx={{alignItems:"center", display:'flex'}}> <Typography variant='h6'> Preview PDF</Typography>
</Grid>
<Grid item xs={6} alignItems="end"> <TextField id="search_table" variant='outlined' label="Search" onChange={handleSearch} fullWidth sx={{mt:'12px'}} />
</Grid> 
</Grid>                   
                    
                    </DialogTitle> */}
                {/* <DialogContent>
                    <Grid container spacing={2} sx={{ margin: 0 }} >
                        <Grid xs={5} sx={{ padding: '10px', height: 500 }}>
                            <object data={Previewfile.fileBase64} aria-label="Preview" width="100%" height="100%" />
                        </Grid>
                        {!loading ? <Grid xs={7} sx={{ padding: '10px', overflow: 'auto', height: 500 }}>
                            {(textExtract).length !== 0 ? <><Typography component={'div'} align="center" variant="h6">Extrated Data</Typography> 
                           <Typography component={'div'} align="center">To Know Mapped Fields.<Button variant='text' onClick={()=>setOpenMap(true)}>Click here</Button></Typography> </> :null}                           */}
                           <PagePDFExtract record={{bill_entry: billEntry, numeric_format: numeric_format, textExtract: textExtract, id: props.id, Previewfile: Previewfile, mappedData: mappedData}} parentCallback={handleTextExtractData} />
                        {/* </Grid> :
                            <Grid xs={7} sx={{ padding: '10px', display: 'grid', alignItems: 'center', justifyContent: 'center' }}>
                                <CircularProgress />
                            </Grid>} </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton variant="contained" onClick={() => submitTextextractValues(Previewfile)} loading={loadingUpdate.update} sx={{ backgroundColor: '#3f51b5' }}> Update </LoadingButton>
                </DialogActions> */}
            </Dialog>

            {/* <Dialog open={openMap} onClose={handleCloseDialog} maxWidth="lg" >
            <DialogTitle className="appBarSend"> <IconButton size="medium" className="closeButtonSend" onClick={handleCloseDialog} aria-label="close"><CloseIcon />
              </IconButton>  <Typography variant="h6" className="sendTypo"><b> Mapping Data to Invoice Number</b></Typography>
        </DialogTitle>
        <List sx={{ pt: 0 }} ref={ref}>
                <Table stickyHeader aria-label="simple table" >
                <TableHead>
                  <TableRow>
                  {mappedData['headers_data'] ? Object.values(mappedData['headers_data']).map((data:any, index:number) => (
                  <TableCell className={classes.MapField} sx={{width: `${100/Object.values(mappedData['headers_data']).length}%`}}> <div> {data} 
                  <IconButton color="primary" onClick={()=>handleAddMap()} style={{ padding: '0px', float: 'right' }} className="addDelete"><AddIcon />  </IconButton> </div>
                  </TableCell>
                  )): null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mappedData['values_data'] ? mappedData['values_data'].map((data:any, i:number) => (
                    <TableRow key={i} >
                        {data ? Object.keys(mappedData['headers_data']).map((val:any, index:number) => (
                            <>
                      <TableCell scope="column" key={index} className={classes.MapField} sx={{width: `${100/Object.values(mappedData['headers_data']).length}%`}}> 
                    
                     <Grid container key={i} sx={{m:'-8px'}}>
                     <Grid item xs={10}>
                     <TextField key={index} id='map' variant="filled" multiline label=""  value={data[val]} onChange={(e: any) => handleEditMap(e, val, i, index)} fullWidth />
                     </Grid> 
                     <Grid item xs={2} sx={{alignItems:'center', display:'grid'}}>
                     {data[val] ? <IconButton onClick={(e) => deleteMap(e, val, i, index )} style={{ float: 'right', color: '#d32f2f', width: 'max-content' }} className="addDelete"><DeleteIcon />  </IconButton> : null}
                     </Grid></Grid>
                     </TableCell>
                     </>
                      )):null}
                      
                    </TableRow>
                  )):null}

               
                </TableBody>
              </Table>
                
        </List>
        <DialogActions>
                    <LoadingButton variant="contained" startIcon={<SaveIcon />} onClick={() => submitMappedData()} sx={{ backgroundColor: '#3f51b5' }} loading={loadingMap.map}> Save </LoadingButton>
                </DialogActions>
      </Dialog> */}

      <Dialog open={openCheckAnomaly.open} onClose={handleCloseAnomaly} maxWidth={openCheckAnomaly.width} fullWidth>
            <DialogTitle className="appBarSend">          
                 <IconButton size="medium" className="closeButtonSend" onClick={handleCloseAnomaly} aria-label="close"><CloseIcon />
              </IconButton> 
              <IconButton size="medium" className="closeButtonSend" onClick={handleWidthDialog} aria-label="close">{openCheckAnomaly.width === 'lg' ? <FullscreenIcon /> :<FullscreenExitIcon />}
              </IconButton> 
               {/* <Typography variant="h6" className="sendTypo"><b>Check Anomalies</b></Typography> */}
        </DialogTitle>
                <CheckListCreate params={props.params} record={{anomalydata: anomalydata, CheckAnomalyConcern: CheckAnomalyConcern}} parentCallBack = {handleCallbackAnomaly} />
      </Dialog>
        </>
    );
};

