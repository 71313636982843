import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import { Card, CardContent, Divider, SelectChangeEvent, Typography, Button, Grid } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Scrollbar from '../../components/Scrollbar';
import { fDateTime, TimeChangeISO } from '../../utils/formatTime';
import { Description } from '@mui/icons-material';

const UnitPriceHistory = (props:any) => {

  return (
      <Box sx={{ maxWidth: 400, maxHeight: 400 }}>
    {/* <Paper> */}
       {Object.keys(props.record).length > 0 ?
       <Card style={{padding:'15px 20px 15px 20px'}}>
      {/* <CardContent> */}
        <Typography variant="h5" gutterBottom >
          HISTORY
        </Typography>
        <Grid container spacing={2}>
            <Grid item xs={7}  alignItems='center' display={'flex'} >
        <Typography style={{alignItems:'center'}}> Present Unit Price </Typography> </Grid>
        <Grid item xs={1}  alignItems='center' display={'flex'} >
          <Typography style={{alignItems:'center'}}> - </Typography>
        </Grid>
        <Grid item xs={4}  alignItems='center' display={'flex'} sx={{wordBreak:'break-all'}}>
        <span style={{fontWeight:'bold', alignItems:'center'}}>{props.record.unit_price}</span> </Grid>
        </Grid>
               {/* {parseISO('2014-02-11T11:30:30')} */}
{/* {props.record.updated_date} */}
{/* {fDateTime(result)} */}

        <Typography align="left">{fDateTime(TimeChangeISO(props.record.updated_date))}</Typography>
        
        <br/>
        {props.record.old_unit_price ? <Divider/> :null}
        {props.record.old_unit_price ? <br/> :null}


        {props.record.old_unit_price ? <Grid container spacing={2}>
            <Grid item xs={7} alignItems='center' display={'flex'} >
        <Typography style={{alignItems:'center'}}> Previous Unit Price </Typography> 
        </Grid>
        <Grid item xs={1} alignItems='center' display={'flex'} >
          <Typography style={{alignItems:'center'}}> - </Typography>
        </Grid>
        <Grid item xs={4} alignItems='center' display={'flex'} sx={{wordBreak:'break-all'}}>
        <span style={{fontWeight:'bold', alignItems:'center'}}>{props.record.old_unit_price}</span> 
        </Grid></Grid> :null}

        {/* {props.record.old_unit_price ?<Typography>First Unit Price - 
        <span style={{fontWeight:'bold'}}>{props.record.old_unit_price}</span></Typography> :null} */}
        {props.record.old_unit_price ?<Typography align="left">{fDateTime(TimeChangeISO(props.record.previous_unit_price_date))}</Typography> :null}

      {/* </CardContent> */}

    </Card>  :null}
    
    <br />
   <div style={{height:'250px', overflowY: 'auto'}}>
   {props ? <Stepper orientation="vertical">
        {props.record && props.record.unit_price_history ?  props.record.unit_price_history.map((data: any, i:number) => (
          <Step key={i} expanded active>
            <StepLabel>
             {/* <Typography sx={{fontSize:'large'}}>{data.updated_date}</Typography> */}
             <Typography>{data.changed_field} : <span style={{fontWeight:'bold', alignItems:'center'}}>{data.changed_value}</span></Typography>
            </StepLabel>

            <StepContent>
            <Typography align="left">{fDateTime(data.updated_date)}</Typography>
            {data.requested_by ?<Typography align="left">Requested By: <span style={{fontWeight:'bold', alignItems:'center'}}>{data.requested_by}</span></Typography>:null}
            {data.accepted_by ? <Typography align="left">{data.status === 'Accepted'? "Accepted By:" : "Rejected By:"} <span style={{fontWeight:'bold', alignItems:'center'}}>{data.accepted_by}</span></Typography>: null}
            {data.status ? <Typography align="left">Status: <span style={{fontWeight:'bold', alignItems:'center'}}>{data.status}</span></Typography> :null}
            {data.comment ? <Typography align="left">Comment: {data.comment}</Typography> :null}

            </StepContent>
          
          </Step>
          
        )) :null}
     </Stepper> :null}
     </div>
      {/* </Paper>  */}
     
    </Box> 

  )
};

export default UnitPriceHistory;


