import * as React from 'react';
import Box from '@mui/material/Box';
import { Skeleton, Paper, Stack, Grid } from '@mui/material';

export default function HSClassificationSkelton() {
  return (
    <Box>
       {Array.from(new Array(2)).map((item, index) => ( 
       <Grid container spacing={2}>
         <Grid item xs={6}> 
       <Paper key={index} elevation={3} sx={{ maxWidth: '100%', padding: '20px', width: '100%', mb:'2%' }}>

<Stack spacing={1} >

    <Grid container spacing={2}>
        <Grid item xs={12}> 
        <Skeleton variant="rounded"  height={30}/>
        </Grid>
        <Grid item xs={12}> 
        <Skeleton variant="rounded"  height={60}/>
        </Grid></Grid>
        {/* <Grid container spacing={2}>
        <Grid item xs={12}> 
        <Skeleton variant="rounded"  height={60}/>
        </Grid></Grid> */}
      </Stack>
      </Paper>
      </Grid>

      <Grid item xs={6}> 
       <Paper key={index} elevation={3} sx={{ maxWidth: '100%', padding: '20px', width: '100%', mb:'2%' }}>

<Stack spacing={1} >

<Grid container spacing={2}>
        <Grid item xs={12}> 
        <Skeleton variant="rounded"  height={30}/>
        </Grid>
        <Grid item xs={12}> 
        <Skeleton variant="rounded"  height={60}/>
        </Grid></Grid>
      </Stack>
      </Paper>
      </Grid> </Grid>
      ))}
    </Box>
  );
}
