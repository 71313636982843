import { useRef, useState } from 'react';
import { Link as RouterLink, Navigate, useLocation } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Tooltip } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import { dotCase, sentenceCase, capitalCase } from 'change-case';
import React from 'react';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '#',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '#',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const location = useLocation();
  const [open, setOpen] = useState(null);
  const [account, setAccount]:any = useState({first_name: '', last_name: '', email:'', user_role_name: ''});
  console.log(account)

React.useMemo(()=> {
  setAccount(JSON.parse(sessionStorage.getItem('user') as any))
}, [sessionStorage.getItem('user')])

  const handleOpen = (event:any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    // <Navigate to="/login" state={{ location }} replace />
    setOpen(null);
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('anamoly_check')
  }

  return (
    <>
    <Tooltip title="Profile">
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          bgcolor : 'white',
          ...(open as unknown as object && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
         boxShadow: 'rgb(163, 163, 163) 6px 11px 9px 1px'
        }}
      >
        <Avatar >{account.first_name?capitalCase(account.first_name[0]):null}</Avatar>
      </IconButton>
      </Tooltip>
      
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
          {account.first_name?capitalCase(account.first_name):null}&nbsp;{account.last_name?capitalCase(account.last_name):null}
          ({account.user_role_name})
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
        </Box>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem component={RouterLink} to='/login' onClick={handleLogout} sx={{ m: 1 }}>
        <Typography variant="subtitle2" noWrap> Logout  </Typography>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
