import React, { useMemo, useState } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// Pages
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import InboundShipmentCreate from './pages/inbound_shipments/InboundShipmentCreate';
// import InboundShipmentList from './pages/inbound_shipments/InboundShipmentList';
import InboundShipmentEdit from './pages/inbound_shipments/InboundShipmentEdit';
import HSClassifierList from './pages/hs_classsifer/HSClassifierList';
import Miscellaneous  from './pages/miscellaneous/Miscellaneous';
import UsedHscodes  from './pages/UsedHscodes';
import HSClassifierCreate  from './pages/hs_classsifer/HSClassifierCreate';
import Login from './pages/Login';
import UploadDocumentsCreate  from './pages/upload_doccuments/UploadDocumentsCreate';
import PaymentList  from './pages/payments/PaymentList';
import PaymentCreate  from './pages/payments/PaymentCreate';
import PaymentEdit  from './pages/payments/PaymentEdit';
import { toast } from 'react-toastify';
import MiscellaneousEdit from './pages/miscellaneous/MiscellaneousEdit';
import ChangeRequest from './pages/change_request_hscode/ChangeRequest';

// ----------------------------------------------------------------------

export default function Router() {
  const [account, setAccount]:any = useState({});
  useMemo(()=> {
    setAccount(JSON.parse(sessionStorage.getItem('user') as any))
  }, [sessionStorage.getItem('user')])

  const Admin = [
    { path: 'app', element: <DashboardApp /> },
    { path: 'hsclassifier/create', element: <InboundShipmentCreate /> },
    { path: 'hsclassifier/:id', element: <InboundShipmentEdit /> },
    { path: 'hsclassifier', element: <HSClassifierList /> },
    { path: 'miscellaneous', element: <Miscellaneous /> },
    { path: 'miscellaneous/:id', element: <MiscellaneousEdit />},
    { path: 'change_request', element: <ChangeRequest /> },
  ]

  const NonAdmin = [
    { path: 'app', element: <DashboardApp /> },
    { path: 'hsclassifier/create', element: <InboundShipmentCreate /> },
    { path: 'hsclassifier/:id', element: <InboundShipmentEdit /> },
    { path: 'hsclassifier', element: <HSClassifierList /> },
    { path: 'miscellaneous', element: <Miscellaneous /> },
    { path: 'miscellaneous/:id', element: <MiscellaneousEdit />},
  ]
  
  return useRoutes([
    {
      path: '/dashboard',
      element:  <RequireAuth>
      <DashboardLayout />
    </RequireAuth>,
      children: account && account['user_role_type'] === 1 ? Admin : NonAdmin,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth) {
    toast.error('Please Login to Continue');
    return ( 
    <Navigate to="/login" state={{ location }} replace />
    );
  }

  return children;
}

// let AuthContext = React.createContext(null);

function useAuth() {
  return sessionStorage.getItem('token');
}