import axios, { AxiosError } from 'axios';
import * as converter from 'xml-js';
import { toast } from 'react-toastify';

export var apiURL = "https://fdpapi.fdpconnect.com"

export async function Post(url: string, data:object, navigate: any) {
      try {
        const response =  await axios({ method: 'POST', url: url, data: data, headers: {'Authorization': 'Barer ' + sessionStorage.getItem('token')} })
        return response  
    }
    catch (error:any) {
      console.log(error.response)
      console.log(error.request)
      console.log(error.message)
      const err = error as AxiosError
      if (err.response) {
         console.log(err.response.status)
         if (err.response.status < 200 || err.response.status >= 300) {  
          if(err.response.status === 403) {
          navigate('/login')
          toast.error("Please Login Again");
          sessionStorage.removeItem('user')
          sessionStorage.removeItem('token')
          }
          else {
            toast.error("Please Try Again");
          }
         }
      }
      else {
        console.log(err.response)
        console.log(err.message)
        toast.error("Please Check Your Network")
      }
   return null
    }
  };

  export async function Get(url: string, id: string, navigate: any) {
       try {
        const response =  await axios({ method: 'GET', url: url+id, headers: {'Authorization': 'Barer ' + sessionStorage.getItem('token')} })
        return response  
    }
    catch (error:any) {
      console.log(error.response)
      console.log(error.request)
      console.log(error.message)
      const err = error as AxiosError
      console.log(err)
      if (err.response) {
         console.log(err.response.status)
         if (err.response.status < 200 || err.response.status >= 300) {  
          console.log(err.response.status)
          if(err.response.status === 403) {
            console.log(err.response.status)
          navigate('/login')
          toast.error("Please Login Again");
          sessionStorage.removeItem('user')
          sessionStorage.removeItem('token')
          }
          else {
            console.log(err.message)
            toast.error("Please Try Again");
          }
         }
      }
      else {
        toast.error("Please Check Your Network")
      }
 return null
    }
   };

   export async function GetAll(url: string, navigate: any) {
  
    try {
     const response =  await axios({ method: 'GET', url: url, headers: {'Authorization': 'Barer ' + sessionStorage.getItem('token')} })
     console.log(response)
     return response  
 }
 catch (error:any) {
  console.log(error.response)
      console.log(error.request)
      console.log(error.message)
  const err = error as AxiosError
  if (err.response) {
     console.log(err.response.status)
     if (err.response.status < 200 || err.response.status >= 300) {  
      if(err.response.status === 403) {
      navigate('/login')
      toast.error("Please Login Again");
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('token')
      }
      else {
        toast.error("Please Try Again");
      }
     }
  }
  else {
    console.log(err.message)
    toast.error("Please Check Your Network")
  }
   return null
 }
};

export async function Put(url: string, id: string, data: object, navigate: any) {
    try {
     const response =  await axios({ method: 'put', url: url+id, data: data, headers: {'Authorization': 'Barer ' + sessionStorage.getItem('token')} })
     return response  
 }
 catch (error:any) {
  console.log(error.response)
      console.log(error.request)
      console.log(error.message)
  const err = error as AxiosError
  if (err.response) {
     console.log(err.response.status)
     if (err.response.status < 200 || err.response.status >= 300) {  
      if(err.response.status === 403) {
      navigate('/login')
      toast.error("Please Login Again");
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('token')
      }
      else {
        toast.error("Please Try Again");
      }
     }
  }
  else {
    console.log(err.message)
    toast.error("Please Check Your Network")
  }
   return null
 }
};

export async function Delete(url: string, id: string, navigate: any) {
  
  try {
   const response =  await axios({ method: 'delete', url: url+id, headers: {'Authorization': 'Barer ' + sessionStorage.getItem('token')} })
   return response  
}
catch (error:any) {
  console.log(error)
  const err = error as AxiosError
  if (err.response) {
     console.log(err.response.status)
     if (err.response.status < 200 || err.response.status >= 300) {  
      if(err.response.status === 403) {
      navigate('/login')
      toast.error("Please Login Again");
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('token')
      }
      else {
        toast.error("Please Try Again");
      }
     }
  }
  else {
    toast.error("Please Check Your Network")
  }
 return null
}
};

  export async function Sign(data:string) {
    let datastr = data.split("data:application/pdf;base64,")
    let doc = datastr[1];
    let today = new Date();
    let uniqueId = today.toISOString();
    uniqueId = uniqueId + '|';
    let body = '<request>' +
      '<command>pkiNetworkSign</command>' + '<ts>' + today.toISOString() + '</ts>' + '<txn>' + uniqueId + '</txn>' +
      "<certificate>" +
      " <attribute name='CN'></attribute>" +
      " <attribute name='O'></attribute>" +
      "<attribute name='OU'></attribute>" +
      "<attribute name='T'></attribute>" +
      "<attribute name='E'></attribute>" +
      "<attribute name='SN'>‎‎</attribute>" +
      "<attribute name='CA'></attribute>" +
      "<attribute name='TC'>SG</attribute>" +
      "<attribute name='AP'>1</attribute>" +
      "</certificate>" +
      "<file>" +
      "<attribute name='type'>pdf</attribute>" +
      "</file>" +
      "<pdf>" +
      "<page>1</page>" +
      "<cood>1,1</cood>" +
      "<size>120,120</size>" +
      "</pdf>" +
      "<data>" + doc + "</data>" +
      '</request>';
    body.replace(/"/g, '+');

    try {
        const response =  await axios({
        method: 'POST',
        url: encodeURI('http://127.0.0.1:1620'),
        headers: {'Content-Type': 'text/xml', 'Accept': 'text/xml'},
        data: encodeURIComponent(body),
      })
      const data = await response.data;
      let r1 = converter.xml2json(data , { compact: true, spaces: 2 });
          const JSONData = JSON.parse(r1);
          if (JSONData.response.data) {
            var pdfbasedata = "data:application/pdf;base64," + JSONData.response.data._cdata;

              return pdfbasedata
          }
          else {
            var err = JSONData.response.error._cdata
            return err
          }
        }
          catch (error) {
            console.error(error);
            return "Please Connect PKI"
          }
  };  