import { Box, Drawer, IconButton } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
// hooks
import useResponsive from '../../hooks/useResponsive';
import Button from '../../theme/overrides/Button';
//
import {navConfigAdmin, navConfigNonAdmin} from './NavConfig';
import NavSectionDesktop from '../../components/NavSectionDesktop';
import FDPLogo from '../../components/FDPLogo';

const DRAWER_WIDTHOpen = '18%';
const DRAWER_WIDTHClose = '6%';

// ----------------------------------------------------------------------

// DashboardSidebar.propTypes = {
//   isOpenSidebar: PropTypes.bool,
//   onCloseSidebar: PropTypes.func,
// };

interface Props {
  isOpenSidebar: boolean
  onCloseSidebar: () => void
  isOpenSidebarDesktop: boolean 
  onCloseSidebarDesktop?: () => void
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, isOpenSidebarDesktop, onCloseSidebarDesktop }: Props) {
  const { pathname } = useLocation();
  const [account, setAccount]:any = useState({});
  const isDesktop = useResponsive('up', 'lg');

  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: isOpenSidebarDesktop ? DRAWER_WIDTHClose : DRAWER_WIDTHOpen,
    },
  }));

  useMemo(()=> {
    setAccount(JSON.parse(sessionStorage.getItem('user') as any))
  }, [sessionStorage.getItem('user')])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ py: 3, display: 'inline-flex', backgroundColor:'white' }}>
        <Logo />
      </Box>
   
      {isOpenSidebarDesktop ? <NavSectionDesktop navConfig={account.user_role_type === 1 ? navConfigAdmin : navConfigNonAdmin} /> :
      <NavSection navConfig={account.user_role_type === 1 ? navConfigAdmin : navConfigNonAdmin} /> }
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: 'inline-flex', backgroundColor:'white' }}>
        <FDPLogo />
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTHOpen,
              bgcolor: '#495057'},
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
       open
          variant="persistent"
          PaperProps={{
            sx: {
              width: isOpenSidebarDesktop ? DRAWER_WIDTHClose : DRAWER_WIDTHOpen,
              bgcolor: '#495057;',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
