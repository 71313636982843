// ----------------------------------------------------------------------

import { Theme } from "@mui/material";

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2)
        },
        gutterBottom: {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1)
        },
      }
    }
  };
}
