import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, Box, ListItemText, TextField, Paper, Button, List, ListItem, Drawer, FormControl, Radio,TableContainer, RadioGroup, FormControlLabel, FormGroup, Dialog, DialogActions, CircularProgress, DialogContent, Tooltip, DialogTitle, ListItemButton, Typography, IconButton, Checkbox, Autocomplete, Divider, Chip, ListItemAvatar, Avatar, Table, TableHead, TableRow, TableCell, TableBody, Popper, Grow, ButtonGroup, Collapse, Alert } from '@mui/material';
import React, { useMemo, useState } from "react";
import { Post,apiURL } from '../../utils/apiCalls';
import { toast } from 'react-toastify';
import FlipNumbers from 'react-flip-numbers';
import { useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import OtpInput from 'react-otp-input';
import CloseIcon from '@mui/icons-material/Close';

// VII - 39, 40
// XIII - 70
// XV - 73, 76, 83
// XVI - 84, 85
// XVII - 87
// XVIII - 90, 91
// XX - 94
var section_no:any = {'39':7, '40': 7, '70': 13, '73': 15,'76':15,'83':15, '84':16,'85':16, '87':17, '90':18,'91':18, '94':20}
export default function HSNav(props: any) {
    console.log(props)
const navigate = useNavigate()
    const [value, setValue] = React.useState(false);
    // console.log(value)

    // const [hsclassify, setHsclassify]:any = React.useState({q1:"1) What type of Bearing ?", q2: "", q3:'', q4: ''});
    // const [hsValue, setHsValue]:any = React.useState({q1:"", q2: "", q3:'', q4: ''});
    // console.log(hsclassify);
    const [hscode, setHscode] = React.useState('');
    console.log(hscode);
    const [hscodeData, setHscodeData]: any = React.useState({prod_desc: "", hs_code: "", hs_question: {}, hs_dropdown: {}, hs_answer: [], previous_hs_answer: [], hs_position: {}, final_description: "", section_no: "", section_url: "", chapter_no: "", chapter_url: "", end_use_match:0, end_use_description:''});
    // console.log(hscodeData)
    const [hscodeObject, setHscodeObject]: any = React.useState({ data: {}, index: 0 });

    const [loading, setLoading] = React.useState(false);
    const useRef: any = React.useRef([]);
    // console.log(useRef)
    const [referenceNode, setReferenceNode]:any = React.useState();
    const [enableCheck, setEnableCheck] = React.useState(true);
    const [openUnitprice, setOpenUnitprice] = React.useState(false);
    const [error, setError]: any = React.useState({})
    const [expanded, setExpanded]:any = React.useState(null);
    const [addManual, setAddManual] = React.useState(false);
    const [changeHscode, setChangeHscode] = React.useState({old_hscode:'', new_hscode: '', check: false});
    const [openChangedHscode, setOpenChangedHscode] = React.useState(false)
console.log(changeHscode);
console.log(hscodeObject)

const [account, setAccount]:any = useState({});

useMemo(()=> {
  setAccount(JSON.parse(sessionStorage.getItem('user') as any))
}, [sessionStorage.getItem('user')])

useMemo(() => {
        setHscodeData(props.record.hs_data)
        setValue(props.record.open)
        setHscode((props.record.hs_data.hs_code).replaceAll(/\s/g,''))
        setHscodeObject(props.record.hscodeObject)
    }, [props.record.open])

    // const handleChangenav = async () => {

    //     const response = await Post(apiURL + "/hs_codes/search_hs_chapter", hscodeData)
    //     if (response.data.status) {
    //         setHscodeData(response.data.data)
    //         setHscode(response.data.data.hs_code)
    //         setValue(true)
    //         props.parentCallback({ hs_code: response.data.data.hs_code, index: props.record.index })
    //     }
    // };

    React.useEffect(() => {
        if(referenceNode)
        return () => referenceNode.removeEventListener('scroll', handleScroll);
    }, []);

    const handleChangenavBack = () => {
        // const list = { prod_desc: "", hs_code: hscode, hs_question: {}, hs_dropdown: {}, hs_answer: [], previous_hs_answer: [], hs_position: {}, final_description: ""}
        // setValue(false);
        // props.parentCallback({ hscode_data: list, open: false, hscodeObject: hscodeObject })
    };

    const handleSubmit = async (data: any) => {
        // if(hscodeData.final_description === "" && hscodeData.hs_code.length < 8) {
        setLoading(true)
        const response = await Post(apiURL + "/hs_codes/search_hs_chapter", data, navigate)
        console.log(response)
        if(response) {
        if(response.status < 200 || response.status >= 300) {
            setLoading(false)
            toast.error(response.statusText);
        }
        else {
        if (response.data.status) {
            setLoading(false)
            setHscodeData(response.data.data)
            setHscode((response.data.data.hs_code).replaceAll(/\s/g,''))
            props.parentCallback({ hscode_data: account['user_role_type'] === 1 ? response.data.data : hscodeObject.data.hs_code, open: true, hscodeObject: hscodeObject })
        }
        else {
            setLoading(false)
            toast.error(response.data.message);
        }
    }
        }
        else {
            setLoading(false)
            toast.error('Please Retry Again');
        }
    };

    const toggleDrawer = (open: any) => {
        const list = { prod_desc: "", hs_code: '', hs_question: {}, hs_dropdown: {}, hs_answer: [], previous_hs_answer: [], hs_position: {}, final_description: "", section_no: "", section_url: "", chapter_no: "", chapter_url: "", end_use_match:0, end_use_description:''}
        // if (
        //     event.type === 'keydown' &&
        //     ((event as React.KeyboardEvent).key === 'Tab' ||
        //       (event as React.KeyboardEvent).key === 'Shift')
        //   ) {
        //     return;
        //   }
        useRef['current'] = []
        setValue(false)
        setHscodeData(list)
        setLoading(false)
        setEnableCheck(true)
        setAddManual(false)
        setHscode('')
        setChangeHscode({old_hscode:'', new_hscode: '', check: false})
        props.parentCallback({ hscode_data: list, open: false, hscodeObject: hscodeObject })
        // props.govNotificationCallBack({})
        // props.hscodeSubmit(true)
    };

    const handleChangeRadio = (event: any, index: number) => {
        const list = { ...hscodeData };

        const { name, value } = event.target
        list.hs_answer[index] = parseInt(value)
        handleSubmit(list)
        setAddManual(false)
        setExpanded(null)
        setHscodeData(list);
    };

    const handleChangeCheck = (e:any, i:number) => {
        const list = { ...hscodeData };

        const { name, checked } = e.target
        if(checked && !enableCheck) {
           list.hs_answer[i] = 1
           handleSubmit(list)
           setEnableCheck(true)
        }
        else {
            list.hs_answer[i] = 0
            handleSubmit(list)
        }
        setHscodeData(list);
    };

    function handleScroll(event:any) {
        var node = event.target;
        const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
        // console.log(node.scrollHeight, 'scrollHeight')
        // console.log(node.scrollTop, 'scrollTop')
        // console.log(node.clientHeight, 'clientHeight')
        // console.log(node.scrollHeight - node.scrollTop, 'scrollHeight - scrollTop')
        // console.log(node.clientHeight - 1, 'clientHeight')
        // console.log(bottom)
        const bottomDynamic = (node.scrollHeight - node.scrollTop) -1 === node.clientHeight;
        const bottomDynamicClient = node.scrollHeight - node.scrollTop === node.clientHeight -1;
        // console.log(bottomDynamic)
        if (bottom || bottomDynamic || bottomDynamicClient) {
            setEnableCheck(false)
        }
        else {
            setEnableCheck(true)
        }
    }

    const paneDidMount = (node:any) => {
        if (node) {
            node.addEventListener('scroll', handleScroll);
            setReferenceNode(node);
        }
    };

    const handleUnitPriceOpen = async () => {
      const list = { prod_desc: "", hs_code: account['user_role_type'] === 1 ? hscode : hscodeObject.data.hs_code, 
      hs_question: {}, hs_dropdown: {}, hs_answer: [], previous_hs_answer: [], hs_position: {}, final_description: "", section_no: "", section_url: "", chapter_no: "", chapter_url: "", end_use_match:0, end_use_description:''}
      // if(hscodeObject.data.importer_count > 0) {
        setLoading(true)
        const response:any = await Post(apiURL + "/msil_file_upload/check_hs_code", {part_no: hscodeObject.data.part_no, hs_code: hscode,importer_count: hscodeObject.data.importer_count }, navigate)
        if (response && response.data.status) {
          setLoading(false)      
          toast.error(response.data.message)
          setOpenChangedHscode(true)
          setChangeHscode({old_hscode: response.data.data['misc_data'][0].hs_code, new_hscode: response.data.data['hs_code_array'][0], check: false })
        }
        else {
          setOpenChangedHscode(false)
          if(account['user_role_type'] === 1) {
          toast.success(response.data.message)
          }
          useRef['current'] = []
          setValue(false)
          setHscodeData(list)
          setLoading(false)
          setEnableCheck(true)
          setAddManual(false)
          props.parentCallback({ hscode_data: list, open: false, hscodeObject: hscodeObject })
          props.hscodeSubmit({table_data: {...hscodeObject}, search_hscode: {...hscodeData, hs_code: hscode} })
          setOpenUnitprice(false)
          setChangeHscode({old_hscode:'', new_hscode: '', check: false})
          // const responseCheckNotifi = await Post(apiURL + "/hs_codes/check_notification", {prod_desc: hscodeData['prod_desc'] , hs_code: hscode, section_no: section_no[(hscode).slice(0, 2)]?section_no[(hscode).slice(0, 2)]: 0 , chapter_no : parseInt((hscode).slice(0, 2)) }, navigate)
          // if (responseCheckNotifi && responseCheckNotifi.data.status) {
          //     props.govNotificationCallBack(responseCheckNotifi.data.data)
          // }
        }
      // }
      // else {
        // setOpenUnitprice(true)
        // handleUnitPriceSubmit()
      // }
    };

    const handleChangedHsCodeClose = () => {
      toggleDrawer(false)
      setOpenChangedHscode(false)
  };

  const handleCheckHsCode = (e:any) => {
      const list = {...changeHscode}
      list.check = e.target.checked
      setChangeHscode(list)
  }

    const handleUnitPriceClose = () => {
        toggleDrawer(false)
        setOpenUnitprice(false)
    };

    const handleChangeUnitPrice = (e:any) => {
        const list = {...hscodeObject};
        const { id, value } = e.target

        list.data[id] = value
        handleValidateUnitPrice()
        setHscodeObject(list)
    };

    const handleUnitPriceSubmit = async () => {
         let updated_hsCode = (openChangedHscode) ? (changeHscode.check ? changeHscode.new_hscode : changeHscode.old_hscode ) : hscode
         //hscode
         console.log(updated_hsCode)
        const list = { prod_desc: "", hs_code: account['user_role_type'] === 1 ? updated_hsCode : hscodeObject.data.hs_code, 
        hs_question: {}, hs_dropdown: {}, hs_answer: [], previous_hs_answer: [], hs_position: {}, final_description: "", section_no: "", section_url: "", chapter_no: "", chapter_url: "", end_use_match:0, end_use_description:''}
        console.log(list)
        
        // if(handleValidateUnitPrice() === 0) {
        useRef['current'] = []
        setValue(false)
        setHscodeData(list)
        setLoading(false)
        setEnableCheck(true)
        setAddManual(false)
        props.parentCallback({ hscode_data: list, open: false, hscodeObject: hscodeObject })
        props.hscodeSubmit({table_data: {...hscodeObject}, search_hscode: {...hscodeData, hs_code: hscode} })
        setOpenUnitprice(false)
        setOpenChangedHscode(false)
        setChangeHscode({old_hscode:'', new_hscode: '', check: false})
        // const response = await Post(apiURL + "/hs_codes/check_notification", {prod_desc: hscodeData['prod_desc'] , hs_code: updated_hsCode, section_no: section_no[(updated_hsCode).slice(0, 2)]?section_no[(updated_hsCode).slice(0, 2)]: 0 , chapter_no : parseInt((updated_hsCode).slice(0, 2)) }, navigate)
        // if (response && response.data.status) {
        //     props.govNotificationCallBack(response.data.data)
        // }
    // }
        // else {
        //     toast.error('Please Enter Unit Price')
        // }
    };

    const isEmptyOrSpaces = (str:any) => {
        return !str || str.toString().trim() === '';
    };

    const handleValidateUnitPrice = () => {
            let e: any = {};
            var list = { ...error }
            if (isEmptyOrSpaces(hscodeObject.data['unit_price'])) { e["unit_price"] = "Required Unit price" }
            // if (isEmptyOrSpaces(hscodeObject.data['valid_from'])) { e["valid_from"] = "Required Valid From" }
            // if (isEmptyOrSpaces(hscodeObject.data['valid_to'])) { e["valid_to"] = "Required Valid To" }
    
            list = e
            setError(list)
            if (Object.entries(e).length === 0)
                return 0
            else
                return -1
        };

        function convertToRoman(num: number) {
            var roman:any = {M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 }
            var result = '';
            for (var key in roman) {
              if (num == roman[key]) {
                return result +=key;
              }
              var check = num > roman[key];
              if(check) {
                result = result + key.repeat(parseInt((num/roman[key]) as any));
                num = num%roman[key];
              }
            }
           return result;
          };

          const handleClickKey = (event: any) => {
            console.log(event.target.textContent)
            console.log(useRef.current)
        
            if (event.target.textContent && useRef.current) {
              for (let i = 0; i < useRef.current.length; i++) {
                if (useRef.current) {
                  if (useRef.current[i].id.toLowerCase() === (event.target.textContent === "S/Q" ? 'section_question' : event.target.textContent === "C/Q" ? 'chapter_question' : event.target.textContent.toLowerCase()) ) {
                    if (useRef.current[i]) {
                      useRef.current[i].scrollIntoView({ block: 'center', behavior: 'smooth' })
                    }
                  }
                }
              }
            }
          };
          
          const Aside = () => (
            <div style={{ width: 'auto', marginTop: '2em', position: 'fixed', right: '5px', zIndex:'2000', background:'white' }}>
              <ButtonGroup orientation="vertical" size="small" aria-label="small button group" color="primary">
                {Object.keys(hscodeData.hs_question).map((data: any, i: number) => (hscodeData.hs_question[data] ?<Button key={data} style={{ textTransform: 'uppercase' }} onClick={handleClickKey}>{data === 'chapter_question' ? "C/Q" : data === 'section_question' ? 'S/Q' : data}</Button>:null))}
              </ButtonGroup>
            </div>
          );

          const handleSeeMore = () => {
            const list = { ...hscodeData };

            list['hs2_more_or_less_option'] = 1
            // const index = list['hs2_all_dropdown'].findIndex((d:any, i:number)=> d === list['hs_dropdown']['h2'][list['hs_answer'][0]]) 
            // console.log(index)
            setHscodeData(list)
          };

          const handleSeeLess = () => {
            const list = { ...hscodeData };
            list['hs2_more_or_less_option'] = 0
            setHscodeData(list)
          }

          const handleChangeEnduse = (e:any) => {
            const list = { ...hscodeData };
            list['end_use_description'] = e.target.value
            setHscodeData(list)
          }

          const handleSubmitEndUse = (e:any) => {
            const list = { ...hscodeData };
            list['end_use_change'] = 1
            setHscodeData(list)
            handleSubmit(list)
          }

          const handleAddMannualHscode = (e:any) => {
            setHscode('')
           setAddManual(true)
          }

    return (
            <Drawer anchor={'right'} open={value} sx={{ '& .MuiDrawer-paper': { minWidth: '40%', maxWidth: '40%' } }}>
                <div> <IconButton onClick={()=>toggleDrawer(false)}> <CancelIcon /> </IconButton>
                    <Divider />
                </div>

                <div style={{ padding: '0px 10px 0px 20px' }}>
                {/* <Aside /> */}
                    <Paper sx={{ position: 'sticky', top: '0', zIndex: 1800, alignItems:'center', p:'10px'}} >
                        <div style={{display: 'flex', width:'100%', justifyContent:'space-between'}}>
                         <div className="FilpCounter">
                         <Typography sx={{marginRight:'10px', display:'flex', whiteSpace:'pre'}}>HS Code:</Typography>
                            
                            
                           {addManual ? <OtpInput numInputs={8} value={hscode} placeholder= "--------" 
                            containerStyle={{gap:'10px', display: 'flex', alignTtems: 'center', '-moz-box-align': 'center'}} 
                            onChange={(e:any)=>setHscode(e)} isInputNum
                // focusStyle = {{display: 'inline-flex', flexDirection: 'column', position: 'relative',minWidth: '0px',padding: '0px',margin: '0px',border: '0px none'}}
                // disabledStyle={{color: 'black', '-webkitTextFillColor': 'black !important', padding: '0px', fontWeight: 600, fontSize: '3rem', borderRadius: '8px', boxSizing: 'border-box', border: '1px solid rgb(145, 158, 171)'}} 
                inputStyle={{ color: 'black', '-webkitTextFillColor': 'black !important', padding: '0px', fontWeight: 600, boxSizing: 'content-box', animationName: 'mui-auto-fill-cancel',
                // fontSize: '3rem',  height:'1.4375em', width:'100%', borderRadius: '8px', 
                animationDuration: '10ms', display: 'block', width:'30px', height:'30px', borderRadius: '4px', minWidth:'0px', border: '1px solid rgb(145, 158, 171)' }} 
                /> :
                            <div className="FilpCounter-Font">
                            <FlipNumbers height={30} width={30} color="black" background="white" play numbers={hscode} duration={1} />                         
                            </div> }
                
                            </div>
                        {/* <span style={{ fontSize: '20px', fontWeight: 600 }} className="Counter-unit">{hscode}</span>  */}
                        <Button startIcon={<ContentPasteSearchIcon />} onClick={handleAddMannualHscode}>Add Manually?</Button>
                       </div>
                    </Paper>
                      
                      {hscodeData.end_use_match ? <>
                        <Alert severity="error">This Item is no where mentioned in the Custom Tariff Heading</Alert>
                    <Typography margin={1}>Please Enter End Use for Description</Typography>
                    <Grid container spacing={2} sx={{alignItems:'center', display:'flex'}}>
                    <Grid xs={8} item>
                    <TextField id="end_use" label="" multiline variant="filled" value={hscodeData['end_use_description']} fullWidth onChange={handleChangeEnduse}/>
                    </Grid>
                       <Grid xs={4} item>
                       <Button variant="contained" fullWidth onClick={handleSubmitEndUse}>Search</Button>
                       </Grid></Grid>
                      </> :null}

                    {Object.keys(hscodeData.hs_question).map((data: any, i: number) => (
                        <>
                           {hscodeData.hs_question[data] ? <Paper elevation={10} sx={{padding:'0px 16px'}}>
                            {((data === 'section_question' || data === 'chapter_question') && hscodeData.hs_question[data]) ? <Typography id={data} gutterBottom key={i} variant='h6' ref={el => useRef.current[i] =el }> Q. {data === 'section_question' ?  (`${hscodeData.hs_question[data]} ${convertToRoman(hscodeData['section_no'])} Notes`): (`${hscodeData.hs_question[data]} ${hscodeData['chapter_no']} Notes`) } 
                           <IconButton onClick={event => {setExpanded(expanded === i ? null : i ), setEnableCheck(true)}} >{expanded === i ? <ExpandLess /> : <ExpandMore />} </IconButton> </Typography> : <>
                           <Typography id={data} gutterBottom key={i} variant='h6' ref={el => useRef.current[i] =el }>Q. {hscodeData.hs_question[data]} 
                            <IconButton onClick={event => {setExpanded(expanded === i ? null : i ), setEnableCheck(true)}} > {(expanded === i || i === hscodeData.hs_answer.length) ? <ExpandLess /> : <ExpandMore />} </IconButton></Typography> 
                            {hscodeData.hs_dropdown[data][hscodeData.hs_answer[i]] ?<Typography>A. {hscodeData.hs_dropdown[data][hscodeData.hs_answer[i]]} </Typography> :null}
                            </> }                         

                            <Collapse in={expanded === i || i === hscodeData.hs_answer.length} timeout="auto" unmountOnExit sx={{padding:'0px 16px 16px 16px', maxHeight: '300px', overflow: 'auto'}}>
                            {((data === 'section_question' || data === 'chapter_question') && hscodeData.hs_question[data]) ? <img src={data === 'section_question' ? hscodeData.section_url : hscodeData.chapter_url} style={{width: '40%'}} /> :null}
                            {hscodeData.hs_question[data] ? 
                          (data === 'section_question' || data === 'chapter_question') ?
                            hscodeData.hs_dropdown[data=== 'section_question'? 'section_answers': 'chapter_answers'] ? <>  <div ref={paneDidMount} style={{height:'145px', overflow:'auto', border: '3px solid rgba(145, 158, 171, 0.24)', padding:'10px'}}> {hscodeData.hs_dropdown[data=== 'section_question'? 'section_answers': 'chapter_answers'].map((list: any, index: number) => (
                              <><Typography key={index}>{list}</Typography>
                                </>  
                            ))} </div> <FormGroup>
                                  <FormControlLabel disabled={(!enableCheck && !loading) ? false : true} control={<Checkbox name={data} checked={hscodeData.hs_answer[i] === 1?true:false} onChange={(e:any)=>handleChangeCheck(e, i)} />} label="By Checking you are Confirming that you read all the Instructions" />
                                </FormGroup> </>
                               : null
                            : <FormControl sx={{ position: 'inherit', width: '100%' }} key={i} >
                                {data === 'hs2' ?  <>
                               {hscodeData.hs_dropdown[data] && hscodeData.hs2_more_or_less_option === 0 ? <><RadioGroup key={i} id={data} name={data} value={hscodeData.hs_answer[i] >= 0  ? hscodeData.hs_answer[i] :null} onChange={(e: any) => handleChangeRadio(e, i)} >
                                    {hscodeData.hs_dropdown[data] ? hscodeData.hs_dropdown[data].map((list: any, index: number) => (
                                        <FormControlLabel key={index} value={index} control={<Radio />} label={list} disabled={loading} sx={{ alignItems: 'flex-start','& .MuiFormControlLabel-label': {padding:'9px 0px'}}} />
                                    )) : null}
                                </RadioGroup> 
                                {/* <Button onClick={handleSeeMore} sx={{ width: 'max-content', left:'80%' }}>See More</Button> */}
                                </>
                                :
                                hscodeData.hs2_all_dropdown ? <><RadioGroup key={i} id={data} name={data} value={hscodeData.hs_answer[i] >= 0  ? hscodeData.hs_answer[i] :null} onChange={(e: any) => handleChangeRadio(e, i)} >
                                    {hscodeData.hs2_all_dropdown ? hscodeData.hs2_all_dropdown.map((list: any, index: number) => (
                                        <FormControlLabel key={index} value={index} control={<Radio />} label={list} disabled={loading} sx={{ alignItems: 'flex-start','& .MuiFormControlLabel-label': {padding:'9px 0px'}}} />
                                    )) : null}
                                </RadioGroup>
                                {/* <Button onClick={handleSeeLess} sx={{ width: 'max-content', left:'80%' }}>See Less</Button> */}
                                </>
                                :null }
                                </> : <>
                                      {hscodeData.hs_dropdown[data] ? <><RadioGroup key={i} id={data} name={data} value={hscodeData.hs_answer[i] >= 0  ? hscodeData.hs_answer[i] :null} onChange={(e: any) => handleChangeRadio(e, i)} >
                                    {hscodeData.hs_dropdown[data] ? hscodeData.hs_dropdown[data].map((list: any, index: number) => (
                                      <>
                                        <FormControlLabel key={index} value={index} control={<Radio />} label={list} disabled={loading} sx={{ alignItems: 'flex-start','& .MuiFormControlLabel-label': {padding:'9px 0px'}}} />
                                        {data === 'hs4' && !(index === hscodeData.hs_dropdown[data].length -1) ? <Divider /> :null}
                                        </>
                                    )) : null}
                                </RadioGroup> 
                                </> : null}</> }

                            </FormControl> : null}
                            </Collapse>
                            </Paper>  :null}
                        </>
                    ))}

                </div>
                {Object.keys(hscodeData.hs_question).length > 0 ? <div style={{ justifyContent: 'center', display: 'flex', marginTop:'10px' }}>
                    <Button variant="contained" disabled={(hscode.length === 8 && !loading) ? false : true} sx={{ width: '50%' }} onClick={handleUnitPriceOpen}> {loading ? <CircularProgress  size={24}/> : 'Submit' }</Button>
                </div > : null}

                <Dialog open={openUnitprice} onClose={(event, reason) => { if (reason !== 'backdropClick') { handleUnitPriceClose}}} fullWidth maxWidth="sm" disableEscapeKeyDown >
                <DialogTitle className="appBarSend"> <IconButton size="medium" className="closeButtonSend" color="inherit" onClick={()=>setOpenUnitprice(false)} aria-label="close"><CloseIcon />
              </IconButton>  <Typography variant="h6" className="sendTypo"><b>Unit price Update</b></Typography></DialogTitle>
                <Divider />
               {hscodeObject.data ? <DialogContent>
                <Grid container spacing={2} >
         <Grid item xs={6} >
          <TextField id='unit_price' variant='filled' label="UNIT PRICE" fullWidth value={hscodeObject.data.unit_price} onChange={handleChangeUnitPrice} error={error.unit_price ? true : false} helperText={error.unit_price ? error.unit_price : " "}/>
         </Grid> </Grid> 
         
                </DialogContent> :null}
                <DialogActions>
                    <Button variant="contained" onClick={handleUnitPriceSubmit} > Update </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openChangedHscode} onClose={(event, reason) => { if (reason !== 'backdropClick') { handleChangedHsCodeClose}}} fullWidth maxWidth="sm" disableEscapeKeyDown >
                <DialogTitle className="appBarSend"> <IconButton size="medium" className="closeButtonSend" color="inherit" onClick={()=>setOpenChangedHscode(false)} aria-label="close"><CloseIcon />
              </IconButton>  <Typography variant="h6" className="sendTypo"><b>HS Code Check</b></Typography></DialogTitle>
                <Divider />
               {hscodeObject.data ? <DialogContent>
                <TableContainer>
              <Table>
                <TableHead>
              <TableRow>
              <TableCell component="th" scope="row" align="center">{"Old HS Code"} </TableCell>
                        <TableCell align="center">{"FDP's Suggested HS Code"}</TableCell>
                        </TableRow>
                </TableHead>
              <TableBody>
              <TableRow>
              <TableCell component="th" scope="row" align="center">{changeHscode.old_hscode} </TableCell>
                        <TableCell align="center">{account['user_role_type'] === 1 ?<Checkbox checked={changeHscode.check} onChange={(e:any)=>handleCheckHsCode(e)} />:null}{changeHscode.new_hscode}</TableCell>
                        </TableRow>
                        </TableBody>
                        </Table>
              </TableContainer>
                </DialogContent> :null}
                <DialogActions>
                    <Button variant="contained" onClick={handleUnitPriceSubmit} >{account['user_role_type'] === 1 ? changeHscode.check ? "Proceed with New HS Code" : 'Proceed with Misc HS Code': "Request to Admin" }</Button>
                </DialogActions>
            </Dialog>

            </Drawer>
            
    )
}; 