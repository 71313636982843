import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
// material
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
// components
import Iconify from '../../components/Iconify';
import Label from '../../components/Label';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import WarningIcon from '@mui/icons-material/Warning';
import { useParams } from 'react-router-dom';
import React from 'react';

// ----------------------------------------------------------------------

const DRAWER_WIDTHOpen = 20;
const DRAWER_WIDTHClose = 8;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

interface Props {
  onOpenSidebar: () => void
  isOpenSidebarDesktop: boolean
  anomaly_status: string | null
}

export default function DashboardNavbar({ onOpenSidebar , isOpenSidebarDesktop, anomaly_status }: Props) {
  const { id } = useParams();
  const [state, setState] = React.useState(false);
 const URL = window.location.href.split('#/')[1]
console.log(state)

 const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${ isOpenSidebarDesktop ? DRAWER_WIDTHClose : DRAWER_WIDTHOpen}%)`,
  },
}));

  React.useEffect(() => {
    // if(URL === "dashboard/hsclassifier/create") {
    //   sessionStorage.setItem('anamoly_check', '')
    //   setState(false)
    // }
     if(URL === "dashboard/hsclassifier/create?completed" || URL === "dashboard/hsclassifier/create?pending" || URL === ("dashboard/hsclassifier/" + id + '?pending') || URL === ("dashboard/hsclassifier/" + id + '?completed')) {
      setState(true)
     } 
     else {
      setState(false)
     }   
  }, [URL, id])

  return (
    // anomaly_status ?
     <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <div style={{width:'100%', justifyContent:'center', display:'flex'}} >
        {/* {(state && anomaly_status === 'pending') ? <div style={{height:'70px', width:'80%', margin:'2%', boxShadow: '#A3A3A3 6px 11px 9px 1px'}}><Label variant="ghost" color={'warning'} className='loaderPending' sx={{height:'100%', width:'100%', fontSize:'2rem', justifyContent:'start'}} startIcon={<WarningIcon style={{color:'#E4A11B'}} />} iconStyle={{width:'5%', height:'50%'}}>Anomalies Found</Label>
        <div className='loaderPending'></div></div> :null}
        {(state && anomaly_status === 'completed') ? <div style={{height:'70px', width:'80%', margin:'2%', boxShadow: '#A3A3A3 6px 11px 9px 1px'}}><Label variant="ghost" color={'success'} className='loaderCompleted' sx={{height:'100%', width:'100%', fontSize:'2rem', justifyContent:'start'}} startIcon={<Iconify icon="fluent-mdl2:completed-solid" sx={{color: '#229a16'}} />} iconStyle={{width:'5%', height:'50%'}}>Anomalies Not Found</Label>
        <div className='loaderCompleted'></div></div> :null} */}
        </div>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle> 
    // :null
  );
}
