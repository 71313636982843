import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import Iconify from './Iconify';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

FDPLogo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function FDPLogo( disabledLink = false, sx:any ) {
  const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width:'100%', height: 'auto', ...sx }} >
      <img src="/automaxis-logo.jpg" />
        {/* <Iconify sx={{width:'100%', height:'100%'}} icon="bxs:home" color="#1976d2" /> */}
    </Box>
  );

  // if (disabledLink) {
  //   return <>{logo}</>;
  // }

  return  <Link underline="none" href='http://autom-axis.com'  target="_blank">{logo}</Link>;
}
