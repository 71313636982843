import { filter } from 'lodash';
import React, { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination, CircularProgress, IconButton, FormControl, Select, MenuItem, SelectChangeEvent, Grid, Skeleton, Tooltip, Dialog, DialogTitle
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
// components

import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
import { GetAll, Post, Put, apiURL } from '../../utils/apiCalls';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { array } from 'prop-types';
import { queries } from '@testing-library/react';
import MiscSkeleton from '../../sections/@dashboard/user/MiscSkeleton';
import { fDate } from '../../utils/formatTime';
import Iconify from '../../components/Iconify';
import CheckListCreate from '../CheckListCreate';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloseIcon from '@mui/icons-material/Close';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'part_name', label: 'PART NAME', align: 'left' },
    { id: 'invoice_numner', label: 'Invoice Number', align: 'center' },
    { id: 'change_request', label: 'Change Request', align: 'center' },
    { id: 'sender_name', label: 'Sender Name', align: 'center' },
    { id: 'date', label: 'Date', align: 'center' },
    { id: '', label: '', align: 'center' },
  ];

  // const USERLIST = [{id:1, hs_code: 27101978, description:'A/T OIL AW-1(>70% MINERAL OIL BASED LUBRICANT) (CONSUMABLE AS CAPTIVE CONSUMPTION) (200LTR/15DRUM)'},
  // {id:2,  hs_code: 34021900, description:'WINDSHIELD WASHER JCW80 (200LTR/20 DRUMS) ( CONSUMABLE AS CAPTIVE CONSUMPTION) MFR-SUZUKI MOTOR'},
  // {id:3,  hs_code: 39269099, description:'BEARING, FR STRUT (MADE OF PLASTIC)  PART NO.45812-79000 (COMPONENTS AS CAPTIVE CONSUMTION FOR AUTOM'},]
  // ----------------------------------------------------------------------

function descendingComparator(a:any, b:any, orderBy:any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order:any, orderBy:any) {
  return order === 'desc'
    ? (a:any, b:any) => descendingComparator(a, b, orderBy)
    : (a:any, b:any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array:any, comparator:any, query:any) {
  const stabilizedThis = array.map((el:any, index:number) => [el, index]);
  stabilizedThis.sort((a:any, b:any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    // part_name, part_no, invoice_no, request_name, username
    return filter(array, (_user) => (_user.part_name).toLowerCase().indexOf(query.toLowerCase().trim()) !== -1 || (_user.username).toLowerCase().indexOf(query.toLowerCase().trim()) !== -1 ||
    (_user.request_name).toLowerCase().indexOf(query.toLowerCase().trim()) !== -1 || (_user.part_no).toLowerCase().indexOf(query.toLowerCase().trim()) !== -1 || (_user.invoice_no).toLowerCase().indexOf(query.toLowerCase().trim()) !== -1);
  }
  return stabilizedThis.map((el:any) => el[0]);
}

type openAnomaly = {
  open: boolean
  width : "lg" | "xl" | "xs" | "sm" | "md"
}

export default function ChangeRequest() {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [isActive, setIsActive] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [list, setList]:any = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState({id: 0, anomalyCheck: false});
  const [openCheckAnomaly, setOpenCheckAnomaly]  = React.useState<openAnomaly>({open: false, width: 'lg'});
  const [anomalydata, setanomalyData]: any = React.useState({ anamoly_list: [], anamoly_status: '', file_id: null, uuid: '', urid: '' });
  const [CheckAnomalyConcern, setCheckAnomalyConcern] = React.useState(false);
 
  async function GetList () {
    setLoading(true);
    const response: any = await GetAll(apiURL + "/change_request", navigate);
        if(response) {
        if (response.data) {       
        setLoading(false);
        setList(response.data)
        setIsActive(response.data)
        }
        else {
        setLoading(false);
        toast.error(response.data)
          setList([])
        }
      }
      else {
        setLoading(false);
        toast.error(`Change Request ${response.statusText}`)
      }
};

  useEffect(()=> {
    GetList();    
    console.log(JSON.parse(sessionStorage.getItem('user') as any))
  },[])

  const handleRequestSort = (event:any, property:any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event:any) => {
    if (event.target.checked) {
      const newSelecteds:any = list.map((n:any) => n.hs_codes);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event:any, name:any) => {
    const selectedIndex = selected.indexOf(name as never);
    let newSelected:any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event:any, newPage:any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event:any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event:any) => {
    setFilterName(event.target.value);
  };
 
  const handleClickEdit = async (e: any, row: any) => {
    e.preventDefault();
    // if(row.request_name === 'Change in Unit price') {
    //   SendDataAnomalyCheck(e, row)
    // }
    // else {
    navigate(`/dashboard/miscellaneous/${row.miscellaneous_id}`);
    // }
    const response = await Put(apiURL+ '/anomalies/seen/', row.id, {seen: 1}, navigate )
}; 

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredUsers = applySortFilter(isActive, getComparator(order, orderBy), filterName);

  const handleChange = (event: SelectChangeEvent) => {
    console.log(event.target.value)
    setIsActive(list.filter((data:any) => data.is_active === event.target.value))
  };

  const isUserNotFound = filteredUsers.length === 0;

  const handleCloseAnomaly = async () => {
    setOpenCheckAnomaly({open: false, width: 'lg'})
};

const handleWidthDialog = () => {
  if(openCheckAnomaly.width === 'lg')
   setOpenCheckAnomaly({open: true, width: 'xl'})
   else 
   setOpenCheckAnomaly({open: true, width: 'lg'})
}

const SendDataAnomalyCheck = async (event:any, data:any) => {
  setLoadingCheck({ anomalyCheck: true, id: data.id })
        setanomalyData({ anamoly_list: [{part_no: data.part_no, part_name: data.part_name, past_unit_price: data.past_unitprice, hs_code: data.new_hscode, past_unit_price_date: data.past_unit_price_date, current_unit_price: data.new_unitprice, invoice_no: data.past_invoice_no, file_id: data.file_id, update_hsCode: data.update_hsCode}],  uuid: data.shipment_id, file_id: data.file_id, urid: data.urid })
        setLoadingCheck({anomalyCheck: false, id: data.id })
        setOpenCheckAnomaly({open: true, width: 'lg'})
    };

    const handleCallbackAnomaly = () => {
      setanomalyData({ anamoly_list: [], anamoly_status: '', file_id: null, uuid: '' })
      handleCloseAnomaly()
      GetList();  
    }
  return (
    <>
    <Container sx={{m:0, width:'100%', maxWidth:'100%'}} maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom> Change Request </Typography>
        </Stack>

        <Card>
          <UserListToolbar placeholder= "Search..." numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order as "asc" | "desc"}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  isCheckbox={false}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => {
                    const { id, part_name, part_no, invoice_no, request_name, username, created_date, seen } = row;
                    const isItemSelected = selected.indexOf(id as never) !== -1;

                    return (
                      <TableRow
                        sx={{backgroundColor: seen ? "none" : "action.hover"}}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >                   
                        <Tooltip title={part_no}><TableCell component="th" scope="row" sx={{minWidth:'20%'}} align='left'>{part_name} </TableCell></Tooltip>
                        <TableCell align="center">{invoice_no}</TableCell>
                        <TableCell align="center">{request_name}</TableCell>
                        <TableCell align="center">{username}</TableCell>
                        <TableCell align="center">{fDate(created_date)}</TableCell>
                        <TableCell align="center">
                       <IconButton color="primary" onClick={(e) => handleClickEdit(e, row)}>
                       {/* {request_name === 'Change in Unit price' ? <Tooltip title="Anomalies found" followCursor>{(loadingCheck.anomalyCheck && loadingCheck.id === id) ?  <CircularProgress size={24}/> :<Iconify icon="ic:outline-warning" sx={{color: '#b78103'}} />}</Tooltip> : */}
                       <VisibilityIcon />
                      {/* //  } */}
               </IconButton>
                     </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                   {loading ? <MiscSkeleton record={{number: 5}}/>  : 
                   <TableRow>
                      <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                       {list.length <=0 ? <Typography gutterBottom align="center" variant="subtitle1"> Not found</Typography> :<SearchNotFound searchQuery={filterName} /> } 
                      </TableCell>
                    </TableRow> }
                  </TableBody>
                )}

              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Dialog open={openCheckAnomaly.open} onClose={handleCloseAnomaly} maxWidth={openCheckAnomaly.width} fullWidth>
            <DialogTitle className="appBarSend">          
                 <IconButton size="medium" className="closeButtonSend" onClick={handleCloseAnomaly} aria-label="close"><CloseIcon />
              </IconButton> 
              <IconButton size="medium" className="closeButtonSend" onClick={handleWidthDialog} aria-label="close">{openCheckAnomaly.width === 'lg' ? <FullscreenIcon /> :<FullscreenExitIcon />}
              </IconButton> 
        </DialogTitle>
                <CheckListCreate params={''} record={{anomalydata: anomalydata }} parentCallBack = {handleCallbackAnomaly} />
      </Dialog>
      </>
  );
}

