import { Divider, Grid, Paper, Typography, Tooltip } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { makeStyles, styled } from '@mui/styles';

const VerticalSpacer = () => <div style={{ height: '1em', display: 'block' }} />;
const Line = ({obj}) => {
    console.log(obj)
    return (
     <Tooltip arrow title={`${obj.key} : ${obj.value}`}>
    <div style={{ borderRadius:'5px', width:'100%', height:'5px', display: 'block', backgroundColor: obj.color }} />
</Tooltip>
    )
}

const GridCard = styled(Grid)(({ theme }) => ({
    width: '100%',
    marginLeft: 0,
    paddingTop: '5px !important'
}));

const BillEntryPreview = (props) => {

    const [billEntry, setBillEntry] = useState({});
   console.log(props.bill_entry)

    return (
<Grid xs={2} sx={{ padding: '10px', overflow:'auto', height: 500}}>
                  <Paper elevation={12} sx={{pr: '16px', pb: '16px'}}>
                     <Typography sx={{justifyContent:'center', display:'flex'}}>Bill of Entry</Typography>
                     <VerticalSpacer />      
                     <VerticalSpacer />

                     <GridCard container spacing={2} >
                        <GridCard item xs={4}>                           
                        <Line obj = {{...props.bill_entry['importer_details']}} />
                        </GridCard> </GridCard>

                        <VerticalSpacer />
                        <VerticalSpacer />
                     <VerticalSpacer />
                      
                     <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={6}>
                        </GridCard> 
                        <GridCard item xs={6}>
                         <Line obj = {{...props.bill_entry['port_loading']}} />
                        </GridCard> </GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={6}>
                      <Line obj = {{...props.bill_entry['exporter_country']}} />
                        </GridCard> 
                        <GridCard item xs={6}>
                         <Line obj = {{...props.bill_entry['country_to']}} />
                        </GridCard> </GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={6}>
                      <Line obj = {{...props.bill_entry['bl_no']}} />
                        </GridCard> 
                        <GridCard item xs={6}>
                         <Line obj = {{...props.bill_entry['hbl_no']}} />
                        </GridCard> </GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}> 
                     <GridCard item xs={6}>
                      <Line obj = {{...props.bill_entry['bl_date']}} />
                        </GridCard> 
                        <GridCard item xs={6}>
                         <Line obj = {{...props.bill_entry['hbl_date']}} />
                        </GridCard> </GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={6}>
                      <Line obj = {{...props.bill_entry['no_of_packages']}} />
                        </GridCard> 
                        <GridCard item xs={6}>
                         <Line obj = {{...props.bill_entry['total_gw']}} />
                        </GridCard> </GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={6}>
                         <Line obj = {{...props.bill_entry['marks_no']}} />
                        </GridCard> </GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={4}>
                         <Line obj = {{...props.bill_entry['invoice_no']}} />
                        </GridCard> 
                        <GridCard item xs={4}>
                         <Line obj = {{...props.bill_entry['invoice_date']}} />
                        </GridCard>
                        <GridCard item xs={4}>
                         <Line obj = {{...props.bill_entry['exporter_details']}} />
                        </GridCard></GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={4}>
                         <Line obj = {{...props.bill_entry['invoice_value']}} />
                        </GridCard> 
                        <GridCard item xs={2}>
                         <Line obj = {{...props.bill_entry['inco_terms']}} />
                        </GridCard></GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={6}>
                         <Line obj = {{...props.bill_entry['freight']}} />
                        </GridCard> </GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={6}>
                         <Line obj = {{...props.bill_entry['insurance']}} />
                        </GridCard> </GridCard>

                        <VerticalSpacer />
                        <VerticalSpacer />
                     <VerticalSpacer />
                     <VerticalSpacer />
                        <VerticalSpacer />
                      
                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={6}>
                         <Line obj = {{...props.bill_entry['third_party']}} />
                        </GridCard> </GridCard>

                        <VerticalSpacer />

                      <Typography sx={{justifyContent:'center', display:'flex', mb:'10px'}}>Item Details</Typography>
                      <VerticalSpacer />

                      <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={2}>
                        </GridCard> 
                        <GridCard item xs={2}>
                         <Line obj = {{...props.bill_entry['hs_code']}} />
                        </GridCard>
                        <GridCard item xs={2}>
                         <Line obj = {{...props.bill_entry['part_name']}} />
                        </GridCard>
                        <GridCard item xs={2}>
                        </GridCard> 
                        <GridCard item xs={2}>
                        </GridCard>
                        <GridCard item xs={2}>
                        </GridCard></GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={2}>
                      <Line obj = {{...props.bill_entry['quantity']}} />
                        </GridCard> 
                        <GridCard item xs={2}>
                         <Line obj = {{...props.bill_entry['unit_price']}} />
                        </GridCard>
                        <GridCard item xs={2}>
                         <Line obj = {{...props.bill_entry['hs_code']}} />
                        </GridCard>
                        <GridCard item xs={2}>
                        </GridCard> 
                        <GridCard item xs={2}>
                        </GridCard>
                        <GridCard item xs={1}>
                        </GridCard>
                        <GridCard item xs={1}>
                        </GridCard></GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={2}>
                      <Line obj = {{...props.bill_entry['unit']}} />
                        </GridCard> 
                        <GridCard item xs={2}>
                        </GridCard>
                        <GridCard item xs={2}>
                        </GridCard>
                        <GridCard item xs={2}>
                        </GridCard> 
                        <GridCard item xs={2}>
                        </GridCard>
                        <GridCard item xs={1}>
                        </GridCard>
                        <GridCard item xs={1}>
                        </GridCard></GridCard>

                        <Divider sx={{mt: 2}} />

                        <VerticalSpacer />

                        <GridCard container spacing={2} sx={{mb:'10px'}}> 
                     <GridCard item xs={4}>
                         <Line obj = {{...props.bill_entry['importer_details']}} />
                        </GridCard> 
                        <GridCard item xs={4}>
                        </GridCard>
                        <GridCard item xs={4}>
                        </GridCard></GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={4}>
                         <Line obj = {{...props.bill_entry['invoice_no']}} />
                        </GridCard> 
                        <GridCard item xs={4}>
                         <Line obj = {{...props.bill_entry['invoice_date']}} />
                        </GridCard>
                        <GridCard item xs={4}>
                         <Line obj = {{...props.bill_entry['exporter_details']}} />
                        </GridCard></GridCard>

                        <Divider sx={{mt: 2}} />

                        <Typography sx={{justifyContent:'center', display:'flex', mb:'10px'}}>Item Details</Typography>

                      <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={2}>
                        </GridCard> 
                        <GridCard item xs={2}>
                         <Line obj = {{...props.bill_entry['hs_code']}} />
                        </GridCard>
                        <GridCard item xs={2}>
                         <Line obj = {{...props.bill_entry['part_name']}} />
                        </GridCard>
                        <GridCard item xs={2}>
                        </GridCard> 
                        <GridCard item xs={2}>
                        </GridCard>
                        <GridCard item xs={2}>
                        </GridCard></GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={2}>
                      <Line obj = {{...props.bill_entry['quantity']}} />
                        </GridCard> 
                        <GridCard item xs={2}>
                         <Line obj = {{...props.bill_entry['unit_price']}} />
                        </GridCard>
                        <GridCard item xs={2}>
                         <Line obj = {{...props.bill_entry['hs_code']}} />
                        </GridCard>
                        <GridCard item xs={2}>
                        </GridCard> 
                        <GridCard item xs={2}>
                        </GridCard>
                        <GridCard item xs={1}>
                        </GridCard>
                        <GridCard item xs={1}>
                        </GridCard></GridCard>

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={2}>
                      <Line obj = {{...props.bill_entry['unit']}} />
                        </GridCard> 
                        <GridCard item xs={2}>
                        </GridCard>
                        <GridCard item xs={2}>
                        </GridCard>
                        <GridCard item xs={2}>
                        </GridCard> 
                        <GridCard item xs={2}>
                        </GridCard>
                        <GridCard item xs={1}>
                        </GridCard>
                        <GridCard item xs={1}>
                        </GridCard></GridCard>

                        <Divider sx={{mt: 2}} />
                        <VerticalSpacer />

                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={6}>
                        </GridCard> 
                        <GridCard item xs={6}>
                        </GridCard></GridCard>

                        <VerticalSpacer />
                        <VerticalSpacer />
                         <VerticalSpacer />
                         <VerticalSpacer />
                         <VerticalSpacer />
                        <VerticalSpacer />
                         <VerticalSpacer />
                         <VerticalSpacer />
                         <VerticalSpacer />

                        <Divider sx={{mt: 2}} />
                        <VerticalSpacer />
                        <Divider sx={{mt: 2}} />

                        <Typography sx={{justifyContent:'center', display:'flex', mb:'10px'}}>Container Details</Typography>
                        <GridCard container spacing={2} sx={{mb:'10px'}}>
                     <GridCard item xs={12}>                  
                         <Line obj = {{...props.bill_entry['container_details']}} />
                        </GridCard></GridCard>

                        <Typography sx={{justifyContent:'center', display:'flex', mb:'10px'}}>BOND Details</Typography>
                        <VerticalSpacer />
                        <Divider sx={{mt: 2}} />

                        <Typography sx={{justifyContent:'center', display:'flex', mb:'10px'}}>GSTIN Details</Typography>

                        <VerticalSpacer />

                     </Paper>                
                  </Grid>
    )
};

export default BillEntryPreview;