import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ForwardIcon from '@mui/icons-material/Forward';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Button, CardActions, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import { pathToRegexp } from 'path-to-regexp';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Get, Post, Put, apiURL } from '../../utils/apiCalls';
import { dataURItoBlob } from '../CheckListCreate';
import HSNav from '../hs_classsifer/HSNav';
import UnitPriceHistory from './UnitPriceHistory';

export default function MiscellaneousEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [mscl, setMscl]: any = React.useState({});
  const [error, setError]: any = React.useState({})
  const [account, setAccount]: any = useState({});
  const [value, setValue] = React.useState(false);
  const [hscodeData, setHscodeData]: any = React.useState({ prod_desc: "", hs_code: "", hs_question: {}, hs_dropdown: {}, hs_answer: [], previous_hs_answer: [], hs_position: {}, final_description: "", section_no: "", section_url: "", chapter_no: "", chapter_url: "", end_use_match: 0, end_use_description: '' });
  const [loading, setLoading] = React.useState({ assign_hs: false, comment: false, accept: false, key: '' });
  const [openComment, setOpenComment] = React.useState({ open: false, key: '' });
  const [comment, setComment] = React.useState('');
console.log(mscl)
console.log(value)

  useMemo(() => {
    setAccount(JSON.parse(sessionStorage.getItem('user') as any))
  }, [sessionStorage.getItem('user')])

  const GetById = async () => {
    if (id) {
      const response: any = await Get(apiURL + "/msilMapping/", id, navigate);
      if (response.data.status) {
        setMscl(response.data.data)
      }
      else {
        toast.error(response.data.message);
      }
    }
    else {
      navigate('/dashboard/miscellaneous', { replace: true })
    }
  };

  React.useEffect(() => {
    GetById();
  }, [id])

  const handleChangeMscl = (e: any) => {
    const list = { ...mscl };
    const { id, value } = e.target
    if(id === 'new_hscode' || id === 'new_unitprice') 
    list.pending_history[0][id] = value
    else
    list[id] = value
    handleValidation(list)
    setMscl(list)
  };

  const handleSave = async () => {
    if (id) {
      if (handleValidation(mscl) === 0) {
        const response: any = await Put(apiURL + "/msilMapping/", id, mscl, navigate);
        if (response.data.status) {
          toast.success(response.data.message);
          navigate('/dashboard/miscellaneous', { replace: true })
        }
        else {
          toast.error(response.data.message);
        }
      }
      else {
        toast.error("Please resolve the issues and Save form")
      }
    }
    else {
      navigate('/dashboard/miscellaneous')
      toast.error("Please Retry Again")
    }
  };

  const isEmptyOrSpaces = (str: any) => {
    return !str || str.toString().trim() === '';
  }

  const handleValidation = (data: any) => {
    let e: any = {};
    let list = { ...error }
    // if (isEmptyOrSpaces(data.unit_price)) { e["unit_price"] = "Required Unit Price" }
    // if (isEmptyOrSpaces(data.hs_code)) { e["hs_code"] = "Required HS Code" }

    if (!isEmptyOrSpaces(data.unit_price) && !(pathToRegexp(/(^-?\d\d*\.\d\d*$)|(^-?\.\d\d*$)|(^\d+$)/).test(data.unit_price))) { e["unit_price"] = "Invalid Unit Price" }
    if (!isEmptyOrSpaces(data.hs_code) && !(pathToRegexp(/^[0-9]{8}$/).test(data.hs_code))) { e["hs_code"] = "Invalid HS Code" }
    list = e
    setError(list)
    if (Object.entries(e).length === 0)
      return 0
    else
      return -1
  };

  const handleBack = () => {
    navigate('/dashboard/miscellaneous')
  };

  const handleHsCode = (data: any) => {
    const list = { ...mscl };
    setHscodeData(data.hscode_data)
    setValue(data.open)
    console.log(data)
    list.pending_history[0]['new_hscode'] = (data.hscode_data.hs_code).replaceAll(/\s/g, '')
    setMscl(list)
  };

  const handleChangenav = async () => {
    const list = { ...mscl };
    setLoading({ assign_hs: true, comment: false, accept: false, key: '' })
    const response = await Post(apiURL + "/hs_codes/search_hs_chapter", { ...mscl.pending_history[0].hs_details }, navigate)
    if (response) {
      if (response.data.status) {
        list['hs_code'] = response.data.data.hs_code
        setMscl(list)
        setHscodeData(response.data.data)
        setLoading({ assign_hs: false, comment: false, accept: false, key: '' })
        setValue(true)
      }
    }
    else {
      setLoading({ assign_hs: false, comment: false, accept: false, key: '' })
      toast.error('Please Try Again')
    }
  };

  const handleViewPdf = async (id: any) => {
    const response: any = await Post(apiURL + "/msil_file_upload/pdf", { file_id: id }, navigate);
    if (response.data.status) {
      var blob = dataURItoBlob(response.data.data[0].file_data);
      var objectURL = URL.createObjectURL(blob);
      window.open(objectURL, '_blank')
    }
    else {
      toast.error('Cannot Open PDF');
    }
  };

  const handleCloseComment = () => {
    setOpenComment({ open: false, key: '' })
  };

  const handleChangeComment = (e: any) => {
    setComment(e.target.value)
  };

  const submitComment = async () => {
    var data = {}
     if(openComment.key === "HS Code") {
       data = {changed_hs_code: mscl.pending_history[0]['changed_hs_code'], comment: comment, urid: mscl.pending_history[0].request_id}
       setLoading({ assign_hs: false, comment: true, accept: false, key: 'HS Code' })
     }
     else {
       data = {changed_unit_price: mscl.pending_history[0]['changed_unit_price'], comment: comment, urid: mscl.pending_history[0].request_id}
       setLoading({ assign_hs: false, comment: true, accept: false, key: 'Unit Price' })
     }
    const response: any = await Put(apiURL + "/anomalies/reject/", mscl.id, { ...data }, navigate);
    if (response && response.data.status) {
      setLoading({ assign_hs: false, comment: false, accept: false, key: '' })
     setOpenComment({ open: false, key: '' })
     toast.success('Rejected Successfully')
     GetById()
    }
    else {
      setLoading({ assign_hs: false, comment: false, accept: false, key: '' })
      toast.error('Please Try Again')
     }
  };

  const handleApprove = async (key:string) => {
    var data = {}
    if(key === "HS Code") {
      data = {changed_hs_code: mscl.pending_history[0]['changed_hs_code'], new_hs_code: mscl.pending_history[0].new_hscode, urid: mscl.pending_history[0].request_id}
      setLoading({ assign_hs: false, comment: false, accept: true, key: 'HS Code' })
    }
    else {
      data = {changed_unit_price: mscl.pending_history[0]['changed_unit_price'], new_unit_price: mscl.pending_history[0].new_unitprice, urid: mscl.pending_history[0].request_id}
      setLoading({ assign_hs: false, comment: false, accept: true, key: 'Unit Price' })
    }
   const response: any = await Put(apiURL + "/anomalies/accept/", mscl.id, { ...data }, navigate);
   if (response && response.data.status) {
    setLoading({ assign_hs: false, comment: false, accept: false, key: '' })
    toast.success('Approved Successfully')
    GetById()
   }
   else {
    setLoading({ assign_hs: false, comment: false, accept: false, key: '' })
    toast.error(response.data.message)
   }
  }

  const handleSubmit = () => {

  }
  return (
    <div>
      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom> MSCL HS Code </Typography>
        </Stack> */}
      <Grid container spacing={2}>
        <Grid item xs={9}>
          {mscl.part_name ? <Paper elevation={5} sx={{ padding: '20px', '& .MuiFilledInput-input.Mui-disabled': { '-webkit-text-fill-color': 'black' } }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="part_no" variant='filled' label="PART NO" value={mscl.part_no} disabled fullWidth onChange={handleChangeMscl} helperText=" " />
              </Grid>
              <Grid item xs={6}>
                <TextField id="part_name" variant='filled' label="PART NAME" fullWidth value={mscl.part_name} disabled helperText=" " />
              </Grid> </Grid>

            <Grid container spacing={2} >
            {mscl.pending_history && !mscl.pending_history[0]?.changed_hs_code ? <Grid item xs={6} >
                <TextField id='hs_code' variant='filled' label="HS CODE" fullWidth value={mscl.hs_code} onChange={handleChangeMscl} error={error['hs_code'] ? true : false} helperText={error['hs_code']} disabled={account['user_role_type'] === 1 ? false : true} />
              </Grid> :null}
              {mscl.pending_history && !mscl.pending_history[0]?.changed_unit_price ? <Grid item xs={6} >
                <TextField id='unit_price' variant='filled' label="UNIT PRICE" fullWidth value={mscl.unit_price} onChange={handleChangeMscl} error={error['unit_price'] ? true : false} helperText={error['unit_price']} disabled={account['user_role_type'] === 1 ? false : true} />
              </Grid> :null}  </Grid>

            {account['user_role_type'] === 1 && mscl.pending_history.length > 0 ? <>
            {mscl.pending_history[0]['changed_hs_code'] ? <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                <Grid item xs={5}>
                  <TextField sx={{ '& .MuiInputLabel-root.Mui-disabled': { color: 'whitesmoke' }, backgroundColor: '#f08080', '& .MuiFilledInput-input.Mui-disabled': { '-webkit-text-fill-color': 'White !important' } }}
                    id='past_hscode' variant='filled' label="OLD HS CODE" fullWidth disabled value={mscl.pending_history[0].past_hscode} />
                </Grid>
                <Grid item xs={1} style={{ display: 'grid', justifyItems: 'center', paddingTop: '30px' }}>
                  <ForwardIcon />
                </Grid>
                <Grid item xs={5}>
                  <TextField sx={{ '& .MuiInputLabel-root': { color: '#0d5f07' }, backgroundColor: '#d2fbd0', '& .MuiFilledInput-input': { color: '#0d5f07' } }}
                    id='new_hscode' variant='filled' label="NEW HS CODE" fullWidth value={mscl.pending_history[0].new_hscode} onChange={handleChangeMscl}/>
                </Grid>
                <Grid item xs={1} style={{ display: 'grid', justifyItems: 'center', paddingTop: '30px' }}>
                  <IconButton onClick={handleChangenav}>{loading.assign_hs ? <CircularProgress size={24} /> : <EditIcon />} </IconButton>
                </Grid> </Grid> :null}

                {mscl.pending_history[0]['changed_hs_code'] ? <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                <Grid item xs={6}>
                  <Button variant="contained" style={{ background: 'red', justifyContent: 'left', display: 'flex' }} onClick={() => setOpenComment({ open: true, key: 'HS Code' })}>Reject</Button>
                </Grid>
                <Grid item xs={6} style={{ justifyContent: 'start', display: 'flex' }}>
                  <LoadingButton variant="contained" style={{ background: '#008000' }} loading={loading.accept && loading.key === "HS Code"} onClick={()=>handleApprove('HS Code')} > Approve</LoadingButton>
                </Grid></Grid> :null}

                {mscl.pending_history[0]['changed_unit_price'] ? <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                <Grid item xs={4}>
                  <TextField sx={{ '& .MuiInputLabel-root.Mui-disabled': { color: 'whitesmoke' }, backgroundColor: '#f08080', '& .MuiFilledInput-input.Mui-disabled': { '-webkit-text-fill-color': 'White !important' } }}
                    id='past_unitprice' variant='filled' label="OLD UNIT PRICE" fullWidth disabled value={mscl.pending_history[0].past_unitprice}/>
                </Grid>
                <Grid item xs={1} style={{ display: 'grid', justifyItems: 'center', paddingTop: '30px' }}>
                  {mscl.pending_history[0].past_invoice_no ? <Tooltip title={"Open PDF"} arrow><Button onClick={() => handleViewPdf(mscl.pending_history[0].file_id)}>{mscl.pending_history[0].past_invoice_no}</Button></Tooltip> :null}
                </Grid>
                <Grid item xs={1} style={{ display: 'grid', justifyItems: 'center', paddingTop: '30px' }}>
                  <ForwardIcon />
                </Grid>
                <Grid item xs={6}>
                  <TextField sx={{ '& .MuiInputLabel-root': { color: '#0d5f07' }, backgroundColor: '#d2fbd0', '& .MuiFilledInput-input': { color: '#0d5f07' } }}
                    id='new_unitprice' variant='filled' label="NEW UNIT PRICE" fullWidth value={mscl.pending_history[0].new_unitprice}  onChange={handleChangeMscl} />
                </Grid></Grid> :null}

                {mscl.pending_history[0]['changed_unit_price'] ? <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                <Grid item xs={6}>
                  <Button variant="contained" style={{ background: 'red', justifyContent: 'left', display: 'flex' }} onClick={() => setOpenComment({ open: true, key: 'Unit Price' })}>Reject</Button>
                </Grid>
                <Grid item xs={6} style={{ justifyContent: 'start', display: 'flex' }}>
                  <LoadingButton variant="contained" style={{ background: '#008000' }} loading={loading.accept && loading.key === "Unit Price"} onClick={()=>handleApprove('Unit Price')}> Approve</LoadingButton>
                </Grid> </Grid> :null}
            </> : null}

            <Grid container spacing={2} style={{ paddingTop: '20px' }}>
              <Grid item xs={6} >
                <TextField id='sr_no' variant='filled' label="SR NO" fullWidth value={mscl.sr_no} onChange={handleChangeMscl} disabled={account['user_role_type'] === 1 ? false : true} />
              </Grid>
              <Grid item xs={6} >
                <TextField id='basic_notification' variant='filled' label="BASIC NOTIFICATION" fullWidth value={mscl.basic_notification} onChange={handleChangeMscl} disabled={account['user_role_type'] === 1 ? false : true} />
              </Grid> </Grid>

            <Grid container spacing={2} style={{ paddingTop: '20px' }}>
              <Grid item xs={6} >
                <TextField id='igst_serial_no' variant='filled' label="IGST SERIAL NO" fullWidth value={mscl.igst_serial_no} onChange={handleChangeMscl} disabled={account['user_role_type'] === 1 ? false : true} />
              </Grid>
              <Grid item xs={6} >
                <TextField id='igst_notn_no' variant='filled' label="IGST NOTN NO" fullWidth value={mscl.igst_notn_no} onChange={handleChangeMscl} disabled={account['user_role_type'] === 1 ? false : true} />
              </Grid> </Grid>

            <Grid container spacing={2} style={{ paddingTop: '20px' }}>
              <Grid item xs={6} >
                <TextField id='gst_cess_serial_no' variant='filled' label="GST CESS SERIAL NO" fullWidth value={mscl.gst_cess_serial_no} onChange={handleChangeMscl} disabled={account['user_role_type'] === 1 ? false : true} />
              </Grid>
              <Grid item xs={6} >
                <TextField id='gst_cess_notn_no' variant='filled' label="GST CESS NOTN NO" fullWidth value={mscl.gst_cess_notn_no} onChange={handleChangeMscl} disabled={account['user_role_type'] === 1 ? false : true} />
              </Grid> </Grid>

            <Grid container spacing={2} style={{ paddingTop: '20px' }}>
              <Grid item xs={6} >
                <TextField id='aidc_levy_notification_searial_no' variant='filled' label="AIDC LEVY NOTIFICATION SERIAL NO" fullWidth value={mscl.aidc_levy_notification_searial_no} onChange={handleChangeMscl} disabled={account['user_role_type'] === 1 ? false : true} />
              </Grid>
              <Grid item xs={6} >
                <TextField id='aidc_exemption_notification_no' variant='filled' label="AIDC EXEMPTION NOTIFICATION NO" fullWidth value={mscl.aidc_exemption_notification_no} onChange={handleChangeMscl} disabled={account['user_role_type'] === 1 ? false : true} />
              </Grid> </Grid>

            {/* <Grid container spacing={2} >
        <Grid item xs={6} >
        <Typography>VALID FROM</Typography>
          <TextField id='validity_from' type= 'date' variant='filled' label="" fullWidth value={format(new Date(mscl.validity_from), 'yyyy-MM-dd')} onChange={handleChangeMscl} helperText=" "/>
         </Grid>
         <Grid item xs={6} >
         <Typography>VALID TO</Typography>
          <TextField id='validity_to' type= 'date' variant='filled' label="" fullWidth value={format(new Date(mscl.validity_to), 'yyyy-MM-dd')} onChange={handleChangeMscl} helperText=" "/>
         </Grid></Grid>  */}

            <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" onClick={handleBack}>Back</Button>
              {account['user_role_type'] === 1 ? <Button variant="contained" onClick={handleSave}>Save </Button> : null}
            </CardActions>
          </Paper> :
            <Paper elevation={5} sx={{ padding: '20px', '& .MuiGrid-item': { mb: '16px' } }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Skeleton variant="rounded" height={30} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="rounded" height={30} />
                </Grid> </Grid>

              <Grid container spacing={2} >
                <Grid item xs={6} >
                  <Skeleton variant="rounded" height={30} />
                </Grid>
                <Grid item xs={6} >
                  <Skeleton variant="rounded" height={30} />
                </Grid> </Grid>

              <Grid container spacing={2} >
                <Grid item xs={6} >
                  <Skeleton variant="rounded" height={30} />
                </Grid>
                <Grid item xs={6} >
                  <Skeleton variant="rounded" height={30} />
                </Grid></Grid>
            </Paper>}
        </Grid>
        <Grid item xs={3}>
          <UnitPriceHistory record={mscl} />
        </Grid> </Grid>

      <HSNav record={{ hs_data: hscodeData, open: value, hscodeObject: { data: { part_no: mscl.part_no } } }} parentCallback={handleHsCode} hscodeSubmit={handleSubmit} />

      <Dialog open={openComment.open} onClose={handleCloseComment} maxWidth="lg" sx={{ '& .MuiDialog-paper': { minWidth: '30%' } }}>
        <DialogTitle className="appBarSend"> <IconButton size="medium" className="closeButtonSend" onClick={handleCloseComment} aria-label="close"><CloseIcon />
        </IconButton>  <Typography variant="h6" className="sendTypo"><b>{openComment.key} Reject Comment</b></Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} >
            <Grid item xs={12} >
              <TextField multiline id='comment' variant='filled' label="Comment" fullWidth onChange={handleChangeComment} />
            </Grid> </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton variant="contained" startIcon={<SaveIcon />} onClick={() => submitComment()} sx={{ backgroundColor: '#3f51b5' }} loading={loading.comment}> Save </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};


