import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date) {
  if(date)
  return format(new Date(date), 'dd MMMM yyyy');
  else 
  return '';
}

export function fDateTime(date: Date) {
  if(date)
  return format(new Date(date), 'dd MMM yyyy HH:mm');
  else 
  return '';
}

export function fDateTimeSuffix(date: Date) {
  if(date)
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
  else 
  return '';
}

export function fToNow(date: Date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export const TimeChangeISO = (result:any) => {
  var d =  new Date(result);
  d.setHours(d.getHours() - 5);
  d.setMinutes(d.getMinutes() - 30);
   return d
    }
