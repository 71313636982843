import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Button, Card, Checkbox, Chip, CircularProgress, CircularProgressProps, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Grow, IconButton, InputAdornment, List, ListItem, Paper, Popper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { makeStyles, styled } from '@mui/styles';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from "../../../components/Iconify";
import { dataURItoBlob } from "../../../utils/DownloadXlsx";
import isEmptyOrSpaces from "../../../utils/Validations";
import { Post, Put, apiURL } from '../../../utils/apiCalls';
import PageTableExtract from "./pageTableExtract";
import { ChromePicker, SketchPicker, SliderPicker } from 'react-color'
import { FormikProvider, useFormik, Form } from 'formik';
import { values } from 'lodash';
import axios from 'axios';
import * as Yup from 'yup';
import AddCompany from './AddCompany';
import BillEntryPreview from './BillEntryPreview';

const theme = createTheme();
const shapeStylesView = { width: 20, height: 20, position: 'absolute', display: 'flex', ml: '-20px' };

export const Circle = ({ bgcolor, count }) => {
   return (
      bgcolor ? <Avatar sx={{ bgcolor: bgcolor, ...shapeStylesView }}>{count}</Avatar> : null
   );
};

const useStyles = makeStyles({
   popper: {
      zIndex: 2000,
      '&[x-placement*="bottom"] $arrow': {
         top: 0,
         left: 0,
         marginTop: "-0.71em",
         marginLeft: 4,
         marginRight: 4,
         "&::before": {
            transformOrigin: "0 100%"
         }
      },
      '&[x-placement*="top"] $arrow': {
         bottom: 0,
         left: 0,
         marginBottom: "-0.71em",
         marginLeft: 4,
         marginRight: 4,
         "&::before": {
            transformOrigin: "100% 0"
         }
      },
      '&[x-placement*="right"] $arrow': {
         left: 0,
         marginLeft: "-0.71em",
         height: "1em",
         width: "0.71em",
         marginTop: 4,
         marginBottom: 4,
         "&::before": {
            transformOrigin: "100% 100%"
         }
      },
      '&[x-placement*="left"] $arrow': {
         right: 0,
         marginRight: "-0.71em",
         height: "1em",
         width: "0.71em",
         marginTop: 4,
         marginBottom: 4,
         "&::before": {
            transformOrigin: "0 0"
         }
      }
   },

   arrow: {
      overflow: "hidden",
      position: "absolute",
      width: "1em",
      height: "0.71em",
      boxSizing: "border-box",
      color: '#FFFF',
      "&::before": {
         content: '""',
         margin: "auto",
         display: "block",
         width: "100%",
         height: "100%",
         boxShadow: theme.shadows[1],
         backgroundColor: "currentColor",
         transform: "rotate(45deg)"
      }
   },
   MapField: {
      "& .addDelete": {
         display: "none",
      },
      "&:hover .addDelete": {
         display: 'flex !important',
      },
   },

});

const shapeStyles = { cursor: 'pointer', width: 20, height: 20, display: 'block' };
const shapeCircleStyles = { borderRadius: '50%' };
let anchorEl: any = null;

function CircularProgressWithLabel(
   props: CircularProgressProps & { value: number },
 ) {
   return (
     <Box sx={{ position: 'relative', display: 'inline-flex' }}>
       <CircularProgress variant="determinate" {...props} />
       <Box
         sx={{
           top: 0,
           left: 0,
           bottom: 0,
           right: 0,
           position: 'absolute',
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
         }}
       >
         <Typography
           variant="caption"
           component="div"
           color="text.secondary"
         >{`${Math.round(props.value)}%`}</Typography>
       </Box>
     </Box>
   );
 }
 
const PagePDFExtract = memo((props: any) => {
   const classes = useStyles();
   const navigate = useNavigate();
   const [loadingMap, setLoadingMap] = useState({ map: false });
   const [Previewfile, setPreviewfile]: any = useState({ file_id: '', fileBase64: '', blobUrl: '', filename: '' });
   const [loadingUpdate, setLoadingUpdate] = useState({ update: false, anomalyCheck: true, file: '' });
   const [mappedData, setMappedData]: any = useState({});
   const ref: any = React.useRef(null);
   const [openMap, setOpenMap] = useState(false);
   const [textExtract, settextExtract]: any = useState([]);
   const useRefValue: any = useRef([]);
   const [openColor, setOpenColor] = useState({open: false, color: '', key: ''});
const [arrowRef, setArrowRef]:any = useState(null);
const [openCompany, setOpenCompany] = useState({open: false, company: ''});
const [numeric_format, setNumeric_format] = useState(0);
const [openBill, setOpenBill] = useState(false);

   console.log(props.record.bill_entry)
   
   useMemo(() => {
      settextExtract(props.record.textExtract)
      setPreviewfile((prevState) => ({ ...prevState, ...props.record.Previewfile }))
      setMappedData(props.record.mappedData)
      setNumeric_format(props.record.numeric_format)
   }, [props.record])

   useMemo(() => {
      if (Previewfile.fileBase64 && isEmptyOrSpaces(Previewfile.blobUrl)) {
         setPreviewfile((prevState: any) => ({ ...prevState, blobUrl: dataURItoBlob(Previewfile.fileBase64) }))
      }
   }, [Previewfile])

   const handleExtractKeys = useCallback((event: any, index: number, i: number, data: any) => {
      const list = [...textExtract]
      console.log(index)
      const { value } = event.target;
      list[index]['forms'][i]['key'] = value
      settextExtract(list);
   }, [textExtract]);

   const handleExtractValues = useCallback((event: any, index: number, i: number) => {
      const list = [...textExtract]
      const { value } = event.target;
      list[index]['forms'][i]['value'] = value;
      settextExtract(list);
   }, [textExtract]);

   const handleDeleteExtract = (index: number, i: number) => {
      const list = [...textExtract]
      list[index]['forms'].splice(i, 1);
      settextExtract(list);
   };

   const handlePdfTable = (data: any) => {
      settextExtract(data)
   };

   const handleAddObject = (index: number) => {
      const list = [...textExtract];
      list[index]['forms'].push({ key: "", value: "", mapped_with: "", color: "" });
      settextExtract(list);
   };

   const refPosition = () => {
      if (useRefValue.current && useRefValue.current.length > 0) {
         var pdfData = useRefValue.current[0];
         console.log(pdfData);
         var key = 'Company Name';
         [...pdfData.childNodes].map((child, j) => {
            if (pdfData.childNodes[j]['localName'] === 'li') {
               if(pdfData.childNodes[j]['childNodes'][0]['childNodes'][0].textContent.toLowerCase().trim() === key.toLowerCase().trim()) {
                  console.log(pdfData.childNodes[j]['childNodes'][0]['childNodes'][0].textContent)
               pdfData.childNodes[j]['childNodes'][0]['childNodes'][0].scrollIntoView({ behavior: 'smooth' })
               }
            }
         })
      }
   }

   const createCompany = async() => {
      const list = [...textExtract];
      list[0]['forms'].push({ key: "Company Name", value: "", mapped_with: "", color: "" });
      settextExtract(list);
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(1000);
      refPosition()
   };

   const submitTextextractValues = async (fileList: any) => {
      setLoadingUpdate({ update: true, anomalyCheck: false, file: fileList.file_id })
      const response: any = await Post(apiURL + "/msil_file_upload/updateExtractedData", { uuid: props.record.id, extractData: textExtract, file_id: fileList.file_id, filename: fileList.filename, numeric_format: numeric_format }, navigate);
      if (response) {
         if (response.data.status) {
            toast.success(response.data.message)
            if(response.data.note) {
               toast.success(response.data.note)
            }
            setLoadingUpdate({ update: false, anomalyCheck: false, file: fileList.file_id })
            props.parentCallback(Previewfile)
         }
         else {
            setLoadingUpdate({ update: false, anomalyCheck: false, file: fileList.file_id })
            if(response.data.message && response.data.note) {
               toast.success(response.data.message)
               createCompany()
               toast.error(response.data.note)
            }
            else {
            toast.error(response.data.message)
            }
         }
      }
      else {
         toast.error('Data Not Saved Please Try Again')
         setLoadingUpdate({ update: false, anomalyCheck: false, file: fileList.file_id })
      }
   };

   const handleCloseDialog = () => {
      setOpenMap(false)
   };

   const handleAddMap = () => {
      const list = { ...mappedData };
      var obj = {}
      var key = ''
      for (var i = 0; i < Object.keys(list.headers_data).length; i++) {
         key = Object.keys(list.headers_data)[i]
         obj = Object.assign(obj, { [key]: '' })
      }
      list.values_data.push(obj)
      setMappedData(list);
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
   };

   const handleEditMap = (e: any, data: any, i: any, dI: any) => {
      const list = { ...mappedData };
      if (list.values_data[i]) {
         list.values_data[i][data] = e.target.textContent
      };
      setMappedData(list);
   };

   const submitMappedData = async () => {
      setLoadingMap({ map: true })
      const response: any = await Put(apiURL + "/msilMapping/updatemapping", "", { ...mappedData }, navigate);
      if (response.data.status === true) {
         setLoadingMap({ map: false })
         handleCloseDialog();
      }
      else {
         setLoadingMap({ map: false })
         toast.error(response.data.message)
      }
   };

   const handleSearch = (e: any) => {
      // setSearch(e.target.value)
      populateItems(e.target.value);
   };

   const populateItems = (val: any) => {
      console.log(val)
      if (useRefValue.current && useRefValue.current.length > 0) {
         useRefValue.current.map((data, i) => {
            var pdfData = useRefValue.current[i];
            if (pdfData) {
               [...pdfData.childNodes].map((child, j) => {

                  [...pdfData.childNodes[j]['childNodes']].map((table_data, k) => {
                     if (!(pdfData.childNodes[j]['id'] === 'header1' || pdfData.childNodes[j]['id'] === 'header2' || pdfData.childNodes[j]['id'] === 'divider' || pdfData.childNodes[j]['id'] === 'Add')) {

                        if (pdfData.childNodes[j]['localName'] === 'li') {
                           if (pdfData.textContent.toLowerCase().trim().includes((val).toLowerCase().trim()) && val) {

                              if (pdfData.childNodes[j].textContent.toLowerCase().trim().includes((val).toLowerCase().trim()) ) {
                                 if (pdfData.childNodes[j]['childNodes'][0]['childNodes'][0].textContent.toLowerCase().trim().includes((val).toLowerCase().trim())) {
                                    if (pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'].length === 2) {
                                       pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'][1].style.backgroundColor = '#FFFF00'
                                    }
                                    else {
                                       pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'][0].style.backgroundColor = '#FFFF00'
                                    }
                                    pdfData.childNodes[j]['childNodes'][0]['childNodes'][0].scrollIntoView({ behavior: 'smooth' })
                                 }

                                 else {
                                    //Remove Background Color
                                    if (pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'].length === 2) {
                                       pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'][1].style.backgroundColor = ''
                                    }
                                    else {
                                       pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'][0].style.backgroundColor = ''
                                    }
                                 }

                                 if (pdfData.childNodes[j]['childNodes'][0]['childNodes'][1].textContent.toLowerCase().trim().includes((val).toLowerCase().trim())) {
                                    pdfData.childNodes[j]['childNodes'][0]['childNodes'][1]['childNodes'][0]['childNodes'][0].style.backgroundColor = '#FFFF00'
                                    pdfData.childNodes[j]['childNodes'][0]['childNodes'][0].scrollIntoView({ behavior: 'smooth' })
                                 }

                                 else {
                                    //Remove Background Color
                                    pdfData.childNodes[j]['childNodes'][0]['childNodes'][1]['childNodes'][0]['childNodes'][0].style.backgroundColor = ''
                                 }
                              }
                              else {
                                 //Remove Background Color
                                 if (pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'].length === 2) {
                                    pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'][1].style.backgroundColor = ''
                                 }
                                 else {
                                    pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'][0].style.backgroundColor = ''
                                 }

                                 pdfData.childNodes[j]['childNodes'][0]['childNodes'][1]['childNodes'][0]['childNodes'][0].style.backgroundColor = ''
                              }
                           }
                           else {
                              //Remove Background Color
                              if (pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'].length === 2) {
                                 pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'][1].style.backgroundColor = ''
                              }
                              else {
                                 pdfData.childNodes[j]['childNodes'][0]['childNodes'][0]['childNodes'][0].style.backgroundColor = ''
                              }

                              pdfData.childNodes[j]['childNodes'][0]['childNodes'][1]['childNodes'][0]['childNodes'][0].style.backgroundColor = ''
                              useRefValue.current[0].scrollIntoView()
                           }
                        }
                        else {
                           if (pdfData.childNodes[j]['childNodes'][k]['localName'] === 'ul') {

                              [...pdfData.childNodes[j]['childNodes'][k]['childNodes'][0]['childNodes']].map((table_value, l) => {
                                 var table_nodes = pdfData.childNodes[j]['childNodes'][k]['childNodes'][0]['childNodes'][l];
                                 [...table_nodes['childNodes']].map((table_child_node_row, n) => {
                                 [...table_nodes['childNodes'][n]['cells']].map((table_header_row, m) => {
                                    if (pdfData.textContent.toLowerCase().trim().includes((val).toLowerCase().trim()) && val) {

                                       if (pdfData.childNodes[j]['childNodes'][k].textContent.toLowerCase().trim().includes((val).toLowerCase().trim())) {
                                          if (table_nodes.textContent.toLowerCase().trim().includes((val).toLowerCase().trim())) {
                                             if (table_nodes['childNodes'][n]['cells'][m].textContent.toLowerCase().trim().includes((val).toLowerCase().trim())) {
                                                 table_nodes['childNodes'][n]['cells'][m].style.setProperty('background-color', '#FFFF00', 'important')
                                                 table_nodes['childNodes'][n]['cells'][m].style.setProperty('color', 'rgba(0, 0, 0, 0.87)', 'important')
                                                 table_nodes['childNodes'][n]['cells'][m].scrollIntoView({ behavior: 'smooth' })
                                             }

                                             else {
                                                //Remove Background Color
                                                 table_nodes['childNodes'][n]['cells'][m].style.backgroundColor = ''
                                                 table_nodes['childNodes'][n]['cells'][m].style.color = ''
                                             }
                                          }
                                          else {
                                             //Remove Background Color
                                              table_nodes['childNodes'][n]['cells'][m].style.backgroundColor = ''
                                              table_nodes['childNodes'][n]['cells'][m].style.color = ''
                                          }
                                       }
                                       else {
                                          //Remove Background Color
                                           table_nodes['childNodes'][n]['cells'][m].style.backgroundColor = ''
                                           table_nodes['childNodes'][n]['cells'][m].style.color = ''
                                       }
                                    }
                                    else {
                                       //Remove Background Color
                                        table_nodes['childNodes'][n]['cells'][m].style.backgroundColor = ''
                                        table_nodes['childNodes'][n]['cells'][m].style.color = ''
                                       useRefValue.current[0].scrollIntoView()
                                    }
                                 })
                              })
                              })
                           }
                        }
                     }
                  })
               })
            }
         })
      }
   };

   const handleOpenColor = (e:any, color:string, key: string) => {
      setOpenColor({open: true, color: color, key: key })
      anchorEl = e.currentTarget
    };
    
    const handleCloseColor = () => {
      setOpenColor({open: false, color: '', key:''})
    };
    
    const handleColor = (e) => {
      console.log(e)
      const list = {...mappedData};
      setOpenColor((prev)=> ({...prev, color: e.hex }))
      if (list['color_data'][openColor.key]) {
          list['color_data'][openColor.key] = e.hex
      };
       setMappedData(list);
    };

    const handleCloseCompany = () => {
      setOpenCompany({open: false, company: ''})
     }
 
     const handleCompany = (formData) => {
       setOpenCompany({open: true, company: formData.key})
     }

   //  const CompanySchema = Yup.object().shape({
   //    company_name: Yup.string().required('Company is required'),
   //    address: Yup.string().required('Address is required'),
   //    city: Yup.string().required('City is required'),
   //    country: Yup.string().required('Country is required'),
   //  });
  
   //  const formik = useFormik({
   //    initialValues: {
   //       company_name: '',
   //       address: '',
   //       city: '',
   //       country: ''
   //    },
   //    validationSchema: CompanySchema,
   //    onSubmit: async (values, { setSubmitting }) => {
   //       console.log(values)
   //     const response = await Post(apiURL + '/msil_company', {...values}, navigate)
   //     if(response && response.data && response.status) {
   //       toast.success('Company Details Saved Sucessfully')
   //       setSubmitting(false)
   //       handleCloseCompany();
   //     }
   //     else {
   //       toast.error('Please Try Again')
   //       setSubmitting(false)
   //     }
   //  }
   //  });
    
   //  const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
const handleClickChip = (num) => {
   setNumeric_format(num)
}

   return (
      <>
       <DialogTitle>
            <Grid container spacing={2} >
               <Grid item xs={5} sx={{ alignItems: "center", display: 'flex' }}> 
               <Typography component={'div'} align="center" variant="h6" width={'100%'}>{Previewfile.filename}</Typography>
               </Grid>
               <Grid xs={openBill ? 5: 7} alignItems="end">
                  <TextField id="search_table" variant='outlined' label="Search" onChange={handleSearch} fullWidth sx={{ mt: '12px' }} />
               </Grid>
               {openBill ? <Grid xs={2} sx={{alignItems: 'center', display: 'flex',justifyContent: 'center'}}>
                 <CircularProgressWithLabel value={10} />
                 </Grid>:null} </Grid>

         </DialogTitle>

         <DialogContent >
            <Grid container spacing={2} sx={{ margin: 0 }} >
               <Grid xs={5} sx={{ padding: '10px', height: 500 }}>
                  <iframe src={Previewfile.blobUrl} aria-label="Preview" width="100%" height="100%" />
               </Grid>
               <Grid xs={openBill ? 5: 7} sx={{ padding: '10px', overflow: 'auto', height: 500 }}>
                  {(textExtract).length !== 0 ? <><Typography component={'div'} align="center" variant="h6">Extrated Data</Typography>
                     <Typography component={'div'} align="center">To Know Mapped Fields.<Button variant='text' onClick={() => setOpenMap(true)}>Click here</Button></Typography> </> : null}
                     <Stack spacing={2} direction={'row'} justifyContent={'space-between'}>
                   <Stack spacing={2} direction={'row'}>
                  <Typography>Numeric Format</Typography>
                  <Chip label="10,000.00"  color={numeric_format === 0 ? 'primary': 'default'} onClick={()=>handleClickChip(0)} />
                  <Chip label="10 000,00"  color={numeric_format === 1 ? 'primary': 'default'} onClick={()=>handleClickChip(1)} />
                  <Chip label="10.000,00"  color={numeric_format === 2 ? 'primary': 'default'} onClick={()=>handleClickChip(2)} />
                   </Stack>
                   <ButtonStyle onClick={()=> setOpenBill(!openBill)}><Iconify icon={openBill ?"ic:sharp-keyboard-arrow-right":"ic:sharp-keyboard-arrow-left"} /></ButtonStyle>
                   </Stack>
                  {textExtract.length > 0 ? textExtract.map((value: any, index: number) => {
                     return (
                        <Card key={index} sx={{ m: '10px 0px', p: 2, overflow: 'initial' }} ref={el => useRefValue.current[index] = el}>
                           <Typography id="header1" padding='0px 16px' fontWeight={'bold'} className="TextExtract-Sticky" sx={{ top: '0px' }}>Page {index + 1} Form Data</Typography>
                           {value.forms.map((formData: any, i: number) => {                            
                              return (
                                 <ListItem key={i} sx={{ '& .MuiTextField-root': { m: 1 }, my: '5px', borderRadius: '5px' }}>
                                    <Grid container spacing={2}>

                                       <Grid xs={5} sx={{ padding: '8px', display:'flex' }} >
                                          {/* <IconButton onClick={()=> handleCompany(formData)}><Iconify icon="carbon:add-alt" sx={{width:'24px', height:'24px'}} /></IconButton> */}
                                          <Circle bgcolor={formData['color'] ? formData['color'] : ''} count={formData['count']} />
                                             <MemoizedTextAreaComponent id={formData.key} value={formData.key} onChange={(e: any) => handleExtractKeys(e, index, i, formData)} mapped_with={formData['mapped_with']} />                                  
                                       </Grid>
                                       <Grid xs={7} sx={{ padding: '8px' }}>
                                          <div style={{ alignItems: 'center', display: 'flex' }}>
                                             <MemoizedTextAreaComponent id={formData.key} value={formData.value} onChange={(e: any) => handleExtractValues(e, index, i)} mapped_with={formData['mapped_with']} />
                                             <IconButton edge="end" onClick={() => handleDeleteExtract(index, i)}><Iconify icon="el:remove-circle" color="#FF4842" sx={undefined} /> </IconButton>
                                          </div>
                                       </Grid></Grid>
                                 </ListItem>
                              )
                           })}
                           <Grid container spacing={2}>
                              <Grid xs={12}>
                                 <Button variant="contained" onClick={() => handleAddObject(index)} startIcon={<Iconify icon="carbon:add-alt" sx={undefined} />} sx={{ float: 'right', backgroundColor: '#3f51b5' }}>Add</Button>
                              </Grid></Grid>
                           <Divider sx={{ m: '10px 0px' }} />
                           <Typography id="header2" padding='16px 0px' fontWeight={'bold'} className="TextExtract-StickyTable" sx={{ top: '0px', zIndex: 3000 }}>Page {index + 1} Table Data</Typography>
                           <PageTableExtract record={{ textExtract: [...textExtract], index: index }} parentpdfTable={handlePdfTable} />
                        </Card>
                     )
                  }) :
                     <CircularProgress />}
               </Grid> 
               {openBill ? <BillEntryPreview bill_entry={props.record.bill_entry} /> :null}
               </Grid>

         </DialogContent> 
         <DialogActions>
            <LoadingButton variant="contained" onClick={() => submitTextextractValues(Previewfile)} loading={loadingUpdate.update} sx={{ backgroundColor: '#3f51b5' }}> Update </LoadingButton>
         </DialogActions>

        <AddCompany open={openCompany.open} company={openCompany.company} parentCallback={handleCloseCompany} />

         <Dialog open={openMap} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
            <DialogTitle className="appBarSend"> <IconButton size="medium" className="closeButtonSend" color="inherit" onClick={handleCloseDialog} aria-label="close"><CloseIcon />
            </IconButton>  <Typography variant="h6" className="sendTypo"><b> Mapping Data to Invoice Number</b></Typography>
            </DialogTitle>
            <List sx={{ pt: 0, overflow: 'auto', m: 1 }} ref={ref}>
               <Table stickyHeader aria-label="simple table" sx={{ border: '1px solid black', m: 1 }}>
                  <TableHead>
                      <TableRow>
                        {mappedData['headers_data'] ? Object.keys(mappedData['headers_data']).map((data: any, index: number) => (
                           <TableCell className={classes.MapField} sx={{ width: `${100 / Object.keys(mappedData['headers_data']).length}%` }}> 
                           <div> {mappedData['headers_data'][data]} </div>
                              <div>
                              <Box id={`${index}`} component="span" sx={{ ...shapeStyles, ...shapeCircleStyles, bgcolor: mappedData['color_data'][data] }} onClick={(e)=>handleOpenColor(e, mappedData['color_data'][data], data)} />
                           </div>
                           </TableCell>
                        )) : null}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {mappedData['values_data'] ? mappedData['values_data'].map((data: any, i: number) => (
                        <TableRow key={i} >
                           {data ? Object.keys(mappedData['headers_data']).map((val: any, index: number) => (
                              <TableCell key={index} contentEditable suppressContentEditableWarning onBlur={(e: any) => handleEditMap(e, val, i, index)}>
                                 {data[val]}
                              </TableCell>
                           )) : null}

                        </TableRow>
                     )) : null}
                  </TableBody>
               </Table>
            </List>
            <DialogActions>
               <LoadingButton variant="contained" startIcon={<SaveIcon />} onClick={() => submitMappedData()} sx={{ backgroundColor: '#3f51b5' }} loading={loadingMap.map}> Save </LoadingButton>
            </DialogActions>
         </Dialog>

         <Popper open={openColor.open} anchorEl={anchorEl} transition disablePortal={false} className={classes.popper} placement={"bottom-start"}  modifiers={[{ name: 'flip', enabled: true}, { name: 'preventOverflow', enabled: true }, { name: 'arrow', enabled: true, options: { element: arrowRef } } ]} >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' }} >
              <Paper elevation={12} >
                <ClickAwayListener onClickAway={handleCloseColor} >
                <Paper elevation={12}>
                <span className={classes.arrow} ref={(e)=>setArrowRef(e)} />
               
                <SketchPicker color={openColor.color} onChangeComplete={handleColor} />
                </Paper>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
   )
});

const TextAreaComponent = ({ value, onChange, id, mapped_with }) => {
   console.log('Render TextAreaComponent');
 
   return (
      <Tooltip arrow title={mapped_with ? mapped_with : 'Not Mapped'}>
     <textarea style={{ resize: 'vertical', width: '100%' }}
       id={id}
       value={value}
       onChange={onChange}
     />
   </Tooltip>
   );
 };

 const MemoizedTextAreaComponent = memo(TextAreaComponent);

export default memo(PagePDFExtract)

const ButtonStyle = styled(IconButton)(({ theme }) => ({
   width: '40px',
   height: '40px',
   // position:'fixed',
   // top:'35px',
   borderRadius:'50%',
   border:'1px dashed rgba(145, 158, 171, 0.24)',
   color:'rgb(99, 115, 129)',
   zIndex:'1101'
   }));

   