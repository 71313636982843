import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import axios , { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { apiURL } from '../../../utils/apiCalls';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      axios.post(apiURL + '/msil_login/authenticateNew', values)
      .then(function (response) {
        if(response) {
        if(response.data.success) {
          sessionStorage.setItem('user', JSON.stringify(response.data.user))
          sessionStorage.setItem('token', response.data.token)
          setSubmitting(false)   
        navigate('/dashboard/app');  
        toast.success("Login Sucessful");
        }
        else {
          setSubmitting(false)
          toast.error(response.data.message);
        }
      }
      else {
        setSubmitting(false)
        toast.error('Please Login Again');
      }
      })
  .catch(function (error) {
    console.log(error.response)
    if(error.response) {
      if (error.response.status < 200 || error.response.status >= 300) {  
        if(error.response.status === 401) {
      setSubmitting(false)
     console.log(error.message)
     toast.error("Please Enter Valid Email Address and Password");
        }
      else {
        setSubmitting(false)
        console.log(error.message)
        toast.error("Please Try Again");
      }}
    }
    else {
      setSubmitting(false)
      toast.error("Please Check Your Network");
    }
  })
  }
  })

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email Address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
