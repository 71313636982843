import React from  'react';
import { filter } from 'lodash';
import { dotCase, sentenceCase, capitalCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {Card, Table,Stack,Button, CircularProgress, Checkbox,TableRow,TableBody,TableCell,Container,Typography,TableContainer,TablePagination , IconButton} from '@mui/material';
// components
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import { GetAll, apiURL } from '../../utils/apiCalls';
import { toast } from 'react-toastify';
import ImageEye from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import SvgIcon from '@mui/material/SvgIcon';
import MiscSkeleton from '../../sections/@dashboard/user/MiscSkeleton';
import { fDate } from '../../utils/formatTime';

// import { Icon } from '@iconify/react';
// import editIcon from '@iconify/icons-ci/edit';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'no', label: 'NO.', align: 'left' },
  { id: 'exporter_company_Name', label: 'Exporter Company Name', align: 'left' },
  { id: 'invoice_no', label: 'Invoice Number', align: 'center' },
  { id: 'country', label: 'Origin Country', align: 'center' },
  { id: 'created_date', label: 'Created Date', align: 'center' },
  { id: 'document_uploaded', label: 'Document Uploaded Status', align: 'center' },
  { id: 'hs_classified', label: 'HS Classify Status', align: 'center' },
  // { id: 'anamoly_check', label: 'Anomaly Check Status', align: 'center' },
  { id: '', label: '', align: 'center' },
];

// ----------------------------------------------------------------------

function descendingComparator(a:any, b:any, orderBy:any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order:any, orderBy:any) {
  return order === 'desc'
    ? (a:any, b:any) => descendingComparator(a, b, orderBy)
    : (a:any, b:any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array:any, comparator:any, query:any) {
  const stabilizedThis = array.map((el:any, index:number) => [el, index]);
  stabilizedThis.sort((a:any, b:any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => JSON.stringify(_user['exporter_company_Name']).toLowerCase().trim().indexOf(query.toLowerCase().trim()) !== -1);
  }
  return stabilizedThis.map((el:any) => el[0]);
}

export default function HSClassifier() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [list, setList]:any = useState([]);
  const [loading, setLoading] = useState(false);

  async function GetList () {
    setLoading(true);
    const response: any = await GetAll(apiURL + "/exporter_details", navigate);
         if(response) {
        if (response.data.status) {       
        setLoading(false);
        setList(response.data.data)
        }
        else {
        setLoading(false);
        toast.error(response.data.message)
          setList([])
        }
      }
      else {
        setLoading(false);
      }
};

  useEffect(()=> {
    GetList();    
  },[])

  const handleRequestSort = (event:any, property:any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event:any) => {
    if (event.target.checked) {
      const newSelecteds:any = list.map((n:any) => n.exporter_company_Name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event:any, newPage:any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event:any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event:any) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredUsers = applySortFilter(list, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  
  const handleClickEdit = (e: any, id: any, anamoly_check: any) => {
    e.preventDefault();
    
    if(anamoly_check === 'pending'){
      sessionStorage.setItem('anamoly_check', 'pending')
      navigate(`/dashboard/hsclassifier/${id}?pending`);
    }
    else if(anamoly_check === 'completed') {
      sessionStorage.setItem('anamoly_check', 'completed')
      navigate(`/dashboard/hsclassifier/${id}?completed`);
    }
    else {
      sessionStorage.setItem('anamoly_check', '')
      navigate(`/dashboard/hsclassifier/${id}`);
    }
};

const handleCreate = () => {
  sessionStorage.setItem('anamoly_check', '')
  navigate(`/dashboard/hsclassifier/create`);
}

  return (
      <Container sx={{m:0, width:'100%', maxWidth:'100%'}} maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
           HS CODE CLASSIFY
          </Typography>
          <Button variant="contained" onClick={handleCreate} startIcon={<Iconify icon="eva:plus-fill" />} sx={{textAlign: "center"}}>
            New HS Code Classify
          </Button>
        </Stack>

        <Card>
          <UserListToolbar placeholder= "Search Company Name..." numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table stickyHeader >
                <UserListHead
                  order={order as "asc" | "desc"}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  isCheckbox={false}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any, index) => {
                    const { id, uuid, exporter_company_Name,country,country_id, anamoly_check, document_uploaded, hs_classified, created_date, invoice_no } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell component="th" scope="row" align="left">{index+1}</TableCell>
                        <TableCell align="left">{exporter_company_Name}</TableCell>
                        <TableCell align="center">{invoice_no}</TableCell>
                      <TableCell align="center" > <img
          loading="lazy"
          // width="20"
          src={`https://flagcdn.com/w20/${country_id}.png`}
          srcSet={`https://flagcdn.com/w40/${country_id}.png 2x`}
          alt=""
          className='flagIcon'
        />{country}</TableCell>
                        <TableCell align="center">{fDate(created_date)}</TableCell>
                        <TableCell align="center"> {document_uploaded ? <Label variant="ghost" color={document_uploaded === 'completed' ? 'success': 'warning'}>{capitalCase(document_uploaded)}</Label> : null}</TableCell>
                        <TableCell align="center">{hs_classified ? <Label variant="ghost" color={hs_classified === 'completed' ?'success': 'warning'}>{capitalCase(hs_classified)}</Label> : null}</TableCell>
                        {/* <TableCell align="center">{anamoly_check ? <Label variant="ghost" color={anamoly_check === 'completed' ?'success': 'warning'}>{capitalCase(anamoly_check)}</Label> : null}</TableCell> */}

                        <TableCell align="center">
                       <IconButton color="primary" onClick={(e: any) => handleClickEdit(e, uuid, anamoly_check)}> <EditIcon /> </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody> {loading ? <MiscSkeleton record={{number: 7}}/> :
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                       {list.length <=0 ? <Typography gutterBottom align="center" variant="subtitle1"> Not found</Typography> :<SearchNotFound searchQuery={filterName} /> }
                      </TableCell>
                    </TableRow> }
                  </TableBody> 
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
  );
}
