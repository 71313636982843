// @mui
import { Button, Container, Typography, Stack , Paper, Card} from '@mui/material';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
// components
import Iconify from '../components/Iconify';
import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import FlipNumbers from 'react-flip-numbers';
import { alpha, styled } from '@mui/material/styles';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { apiURL, GetAll } from '../utils/apiCalls';
// ----------------------------------------------------------------------


const CardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: 'rgb(6, 27, 100)',
  overflow: 'hidden',
  position: 'relative',
  background:'rgb(209, 233, 252)',
  width: '50%', 
  marginRight: 20,
  padding:'10px',
  '&>div': {
      position: 'relative',
      zIndex: 5
  },
  '&:after': {
      content: '""',
      position: 'absolute',
      width: 210,
      height: 210,
      background: 'rgb(21, 101, 192)',
      borderRadius: '50%',
      zIndex: 1,
      top: -85,
      right: -95,
      [theme.breakpoints.down('sm')]: {
          top: -105,
          right: -140
      }
  },
  '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      width: 210,
      height: 210,
      background: 'rgb(21, 101, 192)',
      borderRadius: '50%',
      top: -125,
      right: -15,
      opacity: 0.5,
      [theme.breakpoints.down('sm')]: {
          top: -155,
          right: -70
      }
  }
}));

const CardWrapperAnomaly = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette['warning']['dark'],
  overflow: 'hidden',
  position: 'relative',
  background: alpha(theme.palette['warning'].main, 0.16),
  // alpha(theme.palette['warning'].main, 0.16)
  width: '50%', 
  marginRight: 20,
  padding:'10px',
  '&>div': {
      position: 'relative',
      zIndex: 5
  },
  '&:after': {
      content: '""',
      position: 'absolute',
      width: 210,
      height: 210,
      background: '#e9a404',
      // `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`
      borderRadius: '50%',
      zIndex: 1,
      top: -85,
      right: -95,
      [theme.breakpoints.down('sm')]: {
          top: -105,
          right: -140
      }
  },
  '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      width: 210,
      height: 210,
      background: theme.palette['warning'].light,
      // `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`
      borderRadius: '50%',
      top: -125,
      right: -15,
      opacity: 0.5,
      [theme.breakpoints.down('sm')]: {
          top: -155,
          right: -70
      }
  }
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

export default function DashboardApp() {
  
  const navigate = useNavigate();
const [ total, setTotal ] = useState({anomalies_count: 0, shipment_count: 0})

  async function GetList () {
    const response: any = await GetAll(apiURL + "/msilMapping/dashboard", navigate);
         if(response) {
        if (response.data.status) {       
          setTotal(response.data.data)
        }
      }
};

  useEffect(()=> {
    GetList();    
  },[])
  
  const [values, Setvalues]: any = React.useState({

    series: [{
      name: 'Siam NPR Company Ltd',
      data: [44, 55, 41, 67, 22, 43]
    }, {
      name: 'Dyna Metals',
      data: [13, 23, 20, 8, 13, 27]
    }, {
      name: 'Asian Maruchi Comp. Ltd',
      data: [11, 17, 15, 15, 21, 14]
    }, {
      name: 'Suzuki Motor Corporation',
      data: [21, 7, 65, 13, 22, 8]
    }, {
      name: 'Sumi Tomo electric Sintered Company',
      data: [55, 36, 45, 11, 21, 15]
    },],
    options: {

      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: 'end'
        },
      },
      xaxis: {
        title: {
          text: 'Month'
        },
        type: 'month',
        categories: ['January', 'February', 'March', 'April', 'May', 'June'],
      },
      yaxis: {
        title: {
          text: 'Quantity'
        }
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    },

  },
  )

  return (

    <Container maxWidth="xl">
<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        
        {/* <span style={{ fontSize: '50px', fontWeight: 600, display:'inline-flex', height:'100%' }}><FlipNumbers height={40} width={40} color="black" background="white" play perspective={100} numbers={'100'} duration={1} /></span> */}
        {/* <div style={{width:'100%', height:'100%', display:'flex'}}> */}
        <CardWrapper>
        <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette['primary'].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette['primary'].dark, 0)} 0%, ${alpha(
              theme.palette['primary'].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={'material-symbols:local-mall-outline'} width={24} height={24} />
      </IconWrapperStyle>
        <Typography sx={{textAlign: 'center', alignItems:'center', justifyContent:'center', display:'flex'}}>Total Shipments 
        </Typography>
        <div className="FilpCounterDashboard">
        <div className="FilpCounter-Font">
       
          <FlipNumbers
            play
            color="rgb(6, 27, 100)"
            width={60}
            height={80}
            numbers={total['shipment_count'].toString()}
            duration={3}
          />
        </div>
      </div>
      </ CardWrapper>
      {/* color: theme.palette[color][isLight ? 'dark' : 'light'],
    backgroundColor: alpha(theme.palette[color].main, 0.16), */}
          <CardWrapperAnomaly>
        <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette['warning']['dark'],
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette['warning'].dark, 0)} 0%, ${alpha(
              theme.palette['warning'].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={'ic:outline-warning'} width={24} height={24} />
      </IconWrapperStyle>
        <Typography sx={{textAlign: 'center', alignItems:'center', justifyContent:'center', display:'flex'}}>Total Anomaly Checks
        </Typography>
        <div className="FilpCounterDashboard">
        <div className="FilpCounter-Font">
       
          <FlipNumbers
            play
            color="#B78103"
            width={60}
            height={80}
            numbers={total['anomalies_count'].toString()}
            duration={3}
          />
        </div>
      </div>
      </ CardWrapperAnomaly>
        </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" >
          Spend By Supplier
        </Typography> */}
        {/* <Button variant="contained" component={RouterLink} to="/dashboard/inbound_shipment/create" startIcon={<Iconify icon="eva:plus-fill" sx={undefined} />}>
          New Inbound Shipment
        </Button> */}
      {/* </Stack> */}

      {/* <div id="chart" >
        <Chart options={values.options} series={values.series} type="bar" height={350} />
      </div> */}

    </Container>
  );
}
